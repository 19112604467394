import { SUPPLY_CHAIN_TRACKS } from "../../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import {
  SupplyChainPerson,
  SupplyChainProductModel,
} from "./supplyChainModels";

export interface PostSupplyChainTrackParams {
  id?: number;
  title: string;
  artistName: string;
  labelId: number;
  languageId: number;
  genreId: number;
  parentalAdvisory: number;
  // TODO: Add support for syndication actions.
  // Defaults to only insert.
  syndicationAction?: number;
  parentId: number;
}

interface postTrackParams {
  id?: number;
  title: string;
  persons: SupplyChainPerson[];
  label_id: number;
  language_id: number;
  genre_id: number;
  parental_advisory: number;
  syndication_action: number;
  product_type_id: number;
}

interface PostSupplyChainTrackBody {
  // Parent album ID.
  id: number;
  children: postTrackParams[];
}

export const postSupplyChainTrack = async (
  params: PostSupplyChainTrackParams,
): Promise<SupplyChainProductModel> => {
  const track: postTrackParams = {
    title: params.title,
    label_id: params.labelId,
    genre_id: params.genreId,
    language_id: params.languageId,
    parental_advisory: params.parentalAdvisory,
    syndication_action: params?.syndicationAction || 1,
    // TODO: Add support for person type and is_featured.
    persons: [{ name: params.artistName, type: 1, is_featured: 0 }],
    // TODO: Add support for product types.
    product_type_id: 3,
  };
  if (params.id) {
    track.id = params.id;
  }

  const body: PostSupplyChainTrackBody = {
    id: params.parentId,
    children: [track],
  };

  const response =
    await makeBackendPostCallWithJsonResponse<SupplyChainProductModel>(
      SUPPLY_CHAIN_TRACKS,
      body,
    );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};
