import {
  DialogActionsProps,
  DialogContentProps,
  DialogProps,
  DialogTitleProps,
} from "@mui/material";

import { useCallback, useMemo } from "react";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import classNames from "classnames";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../../constants/trackingEvents";
import { EngineEarsDialog } from "./ResponsiveDialog.styles";
import { Button, ButtonProps } from "../Button/MUIButton";
import { ButtonVariant } from "../Button/utils";
import { ResponsiveDialogTitle } from "./ResponsiveDialogTitle";
import { ResponsiveDialogContent } from "./ResponsiveDialogContent";
import { ResponsiveDialogActions } from "./ResponsiveDialogActions";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";

interface ResponsiveDialogProps extends Omit<DialogProps, "children"> {
  titleProps?: Partial<
    Omit<DialogTitleProps, "component" | "container" | "ref">
  >;
  contentProps?: Partial<DialogContentProps>;
  actionsProps?: Partial<DialogActionsProps>;
  closeButtonRightSide?: boolean;
  closeButtonProps?: Partial<Omit<ButtonProps, "onClick">>;
  hideCloseButton?: boolean;
  manuallySizeButtons?: boolean;
}

export const ResponsiveDialog = ({
  actionsProps = {},
  className,
  closeButtonProps = {},
  hideCloseButton = false,
  closeButtonRightSide = false,
  manuallySizeButtons = false,
  onClose = () => {},
  fullScreen,
  titleProps = {},
  contentProps = {},
  ...props
}: ResponsiveDialogProps) => {
  const { children: actionsChildren, ...remainingActionsProps } = actionsProps;
  const {
    children: closeButtonChildren = "Close",
    className: closeButtonClassName,
    disabled: closeButtonDisabled,
    ...remainingCloseButtonProps
  } = closeButtonProps;
  const { isMobile } = useMediaQueryBreakpoint();
  const isFullScreen = useMemo(() => {
    return typeof fullScreen === "undefined" ? isMobile : fullScreen;
  }, [fullScreen, isMobile]);

  const handleClose = useCallback(
    (
      event: object,
      reason: "backdropClick" | "escapeKeyDown" = "backdropClick",
    ) => {
      onClose(event, reason);
      emitAnalyticsTrackingEvent(TRACKING_EVENTS_NAME.USER_CLOSE_DIALOG, {
        reason,
        className,
        isMobile,
        isFullScreen,
      });
    },
    [onClose, className, isMobile, isFullScreen],
  );
  return (
    <EngineEarsDialog
      fullScreen={isFullScreen}
      onClose={handleClose}
      className={classNames(
        TrackableClassNames.ENGINEEARS_DIALOG,
        {
          [TrackableClassNames.ENGINEEARS_DIALOG_FULLSCREEN]: isFullScreen,
        },
        className,
      )}
      sx={{
        "& .MuiDialogActions-root > button": {
          width: !manuallySizeButtons && isFullScreen ? "100%" : undefined,
          minWidth: "100px", // TODO: Switch to constant
        },
      }}
      data-testid={TrackableClassNames.ENGINEEARS_DIALOG}
      {...props}
    >
      <ResponsiveDialogTitle
        {...titleProps}
        hideCloseButton={hideCloseButton}
        disabled={closeButtonDisabled}
        onClose={(e) => handleClose(e, "backdropClick")}
      />
      <ResponsiveDialogContent {...contentProps} />
      <ResponsiveDialogActions {...remainingActionsProps}>
        {closeButtonRightSide && actionsChildren}
        {!hideCloseButton && (
          <Button
            variant={ButtonVariant.OUTLINED}
            className={classNames(
              TrackableClassNames.ENGINEEARS_DIALOG_CLOSE_BUTTON,
              closeButtonClassName,
            )}
            onClick={(e) => handleClose(e, "backdropClick")}
            disabled={closeButtonDisabled}
            {...remainingCloseButtonProps}
          >
            {closeButtonChildren}
          </Button>
        )}
        {!closeButtonRightSide && actionsChildren}
      </ResponsiveDialogActions>
    </EngineEarsDialog>
  );
};
