import { atom } from "jotai";

export interface SupplyChainSocketMessage {
  eventName: string;
  content: any;
}

const supplyChainSocketMessagesAtom = atom<SupplyChainSocketMessage[]>([]);
supplyChainSocketMessagesAtom.debugLabel = "SupplyChain Websocket Messages";

export default supplyChainSocketMessagesAtom;
