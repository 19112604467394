import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { PostSupplyChainLabelParams } from "../../../api/supplyChain/postSupplyChainLabel";
import { SupplyChainLabelModel } from "../../../api/supplyChain/getSupplyChainLabels";
import { Button } from "../../core-ui/components/Button/Button";
import useSupplyChainLabelMutation from "../../../hooks/supplyChainHooks/useSupplyChainLabelMutation";
import useGetSupplyDashboardOptionsQuery, {
  initialDashboardOptions,
} from "../../../hooks/supplyChainHooks/useGetSupplyDashboardOptionsQuery";
import SupplyChainAxios from "../../../api/supplyChain/supplyChainAxios";

const FormRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: 600,
  padding: 8,
});

export const SupplyChainLabel = ({
  label,
}: {
  label: SupplyChainLabelModel;
}) => {
  const [labelForm, setLabelForm] = useState<PostSupplyChainLabelParams>({
    id: label.id,
  });

  const handleOnChange = (value: string | number, field: string) => {
    setLabelForm({ ...labelForm, [field]: value });
  };

  const { mutateAsync: updateLabel, isPending } = useSupplyChainLabelMutation(
    label.id,
  );

  const axiosInstance = SupplyChainAxios();
  const { data: dashboardOptions } =
    useGetSupplyDashboardOptionsQuery(axiosInstance);

  const { countries } = dashboardOptions || initialDashboardOptions;

  return (
    <Box key={label.id}>
      <FormRow>
        <Box>label name: {label.name}</Box>
        <TextField
          value={labelForm?.name || ""}
          onChange={(e) => handleOnChange(e.target.value, "name")}
        />
      </FormRow>
      <FormRow>
        <Box>label email: {label.email}</Box>
        <TextField
          value={labelForm?.email || ""}
          onChange={(e) => handleOnChange(e.target.value, "email")}
        />
      </FormRow>
      <FormRow>
        <FormControl fullWidth>
          <InputLabel>Country</InputLabel>
          <Select
            label="Country"
            value={labelForm?.countryId || label.country.id}
            onChange={(e) => handleOnChange(e.target.value, "countryId")}
          >
            {countries?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormRow>
      <FormRow>
        <Button
          loading={isPending}
          disabled={
            isPending ||
            (!labelForm?.countryId && !labelForm?.email && !labelForm.name)
          }
          onClick={async () => {
            await updateLabel(labelForm);
            setLabelForm({ id: label.id });
          }}
        >
          Update Label
        </Button>
      </FormRow>
    </Box>
  );
};
