import { Redirect, useParams } from "react-router-dom";
import { SCREENS } from "../../../routes/screens";

import { useAppSelector } from "../../../store/hooks";
import { MusoSelectionTab } from "../../../store/models/muso";
import MusoRosterScreen from "../MusoRosterScreen/MusoRosterScreen";
import MusoCreditsScreen from "../MusoCreditsScreen/MusoCreditsScreen";

const MusoSelectionScreen = () => {
  const { tab } = useParams<{ tab: string }>();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { activeMusoUserId, musoAssociation } = useAppSelector(
    (state) => state.musoAssociationStore,
  );

  if (
    !loggedInUser ||
    (tab !== MusoSelectionTab.CREDITS && tab !== MusoSelectionTab.ROSTER)
  ) {
    return <Redirect to={SCREENS.SIGNED_OUT_HOME_SCREEN} />;
  }

  if (!activeMusoUserId) {
    return <Redirect to={SCREENS.DASHBOARD} />;
  }

  if (tab === MusoSelectionTab.CREDITS && !musoAssociation) {
    return <Redirect to={SCREENS.DASHBOARD} />;
  }

  switch (tab) {
    case MusoSelectionTab.ROSTER:
      return <MusoRosterScreen />;
    case MusoSelectionTab.CREDITS:
      return <MusoCreditsScreen musoAssociation={musoAssociation!} />;
    default:
      return null;
  }
};

export default MusoSelectionScreen;
