import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import classNames from "classnames";
import {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  ReactText,
  useCallback,
  useRef,
} from "react";
import { toast } from "react-toastify";
import { AnalyticsEvent, trackEvent } from "../../../../utils/analyticsUtils";
import Link, { LinkProps } from "../../../elements/Link/Link";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import "./Button.css";
import {
  buttonStyle,
  buttonStyleDisabled,
  ButtonVariant,
  muiColor,
  muiVariant,
} from "./utils";

export { ButtonVariant } from "./utils";

export interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
  children?: ReactNode;
  disableText?: string;
  fullWidth?: boolean;
  labelIcon?: ReactNode;
  LinkProps?: Omit<LinkProps, "to">;
  loading?: boolean;
  search?: string;
  reverseIconOrder?: boolean;
  variant?: ButtonVariant;
  analyticsEvent?: AnalyticsEvent;
  form?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      analyticsEvent,
      children,
      className,
      disabled,
      disableRipple = true,
      disableText,
      fullWidth,
      onClick = () => {},
      href,
      search,
      labelIcon,
      LinkProps,
      reverseIconOrder,
      loading,
      style,
      sx,
      variant = ButtonVariant.PRIMARY,
      ...restProps
    } = props;
    const toastId = useRef<ReactText>("");
    const showDisableToast = () => {
      if (!disabled || !disableText) return;
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(disableText);
      }
    };

    const finalButtonStyle = disabled
      ? buttonStyleDisabled[variant]
      : buttonStyle[variant];

    const buttonClass = classNames(finalButtonStyle, className);

    const buttonContent = loading ? (
      <SoundWaveLoader
        whiteLoader={variant === ButtonVariant.PRIMARY}
        width={50}
        height={12}
      />
    ) : (
      children
    );

    const handleClick:
      | MouseEventHandler<HTMLButtonElement>
      | MouseEventHandler<HTMLAnchorElement> = useCallback(
      (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> &
          React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      ) => {
        try {
          onClick(e);
        } catch {
          // If the click is not intercepted for any reason, track the event
        }

        if (analyticsEvent) {
          trackEvent(analyticsEvent);
        }
      },
      [onClick, analyticsEvent],
    );

    const button = (
      <MuiButton
        className={buttonClass}
        color={muiColor[variant]}
        disabled={disabled}
        disableRipple={disableRipple}
        startIcon={!loading && reverseIconOrder && labelIcon}
        endIcon={!loading && !reverseIconOrder && labelIcon}
        form={props.form}
        fullWidth={fullWidth}
        onMouseOver={showDisableToast}
        ref={ref}
        variant={muiVariant[variant]}
        style={style}
        sx={sx}
        {...restProps}
        // Must be placed after the spread, otherwise it will get overridden
        // We couldn't de-structure onClick either because TS 4.5.5 wasn't able to infer the type correctly
        onClick={handleClick as MouseEventHandler<HTMLButtonElement>}
      >
        {buttonContent}
      </MuiButton>
    );

    const url = `${href}${search ? `?${search}` : ""}`;

    return href === undefined ? (
      button
    ) : (
      // Wrapping the button in a link component allows us to have greater control over whether the link
      // opens a new tab or navigates to a new page.
      // This affects styling so additional props can be passed to the Link component
      <Link
        className={LinkProps?.className}
        to={!disabled ? url : "#"}
        onClick={LinkProps?.onClick}
        onMouseOver={showDisableToast}
        target={href.match(/^http/) ? "_blank" : undefined}
        rel={href.match(/^http/) ? "noreferrer" : undefined}
        style={{
          display: "block",
          width: fullWidth ? "100%" : "fit-content",
          ...LinkProps?.style,
        }}
      >
        {button}
      </Link>
    );
  },
);

Button.displayName = "Button";
