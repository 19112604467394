import { Box } from "@mui/material";
import useGetSupplyChainLabelsQuery from "../../../hooks/supplyChainHooks/useGetSupplyChainLabelsQuery";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { SupplyChainLabel } from "./SupplyChainLabel";

export const SupplyChainLabels = () => {
  const { isPending: isLabelsPending, data: labels } =
    useGetSupplyChainLabelsQuery();

  if (isLabelsPending) {
    return <SoundWaveLoader width={400} height={100} />;
  }

  if (labels && Object.keys(labels).length) {
    return (
      <Box sx={{ width: "600px", boxSizing: "border-box", padding: "8px" }}>
        {Object.values(labels).map((label) => (
          <SupplyChainLabel key={label.id} label={label} />
        ))}
      </Box>
    );
  }

  return <Box>No labels</Box>;
};
