import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../routes/screens";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectCurrentUser } from "../../../store/selectors/userInfoSelectors";
import { getAppsList } from "../../../store/utils/customerPortalUtils";
import "./CustomerPortalHomeScreen.css";
import { Button } from "../../elements/Button/button";
import { getUmgBillingReport } from "../../../store/actions/transactions";

export const CustomerPortalHomeScreen = () => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);

  return (
    <>
      <h3>Welcome, {user?.username}</h3>
      <p className="mt-3">Your auth token is {localStorage.getItem("token")}</p>
      <ul>
        {Array.from(getAppsList.entries()).map(([screen, details]) => (
          <li key={screen} className="home-view-list-item">
            <Link
              className="home-view-link"
              to={SCREENS.CUSTOMER_PORTAL.replace(":tab", details.endpoint)}
            >
              <img
                alt={details.title}
                src={details.icon}
                className="mx-2 customer-portal-item-icon"
              />
              <h4 className="customer-portal-title">{details.title}</h4>
            </Link>
          </li>
        ))}
      </ul>
      <Button
        loading={downloadingReport}
        primary={false}
        label="Download UMG Report"
        onClick={() => {
          setDownloadingReport(true);
          dispatch(getUmgBillingReport())
            .unwrap()
            .then(() => {
              setDownloadingReport(false);
            });
        }}
      />
    </>
  );
};
