import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { displayEngineEarsError } from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  postSupplyChainAlbum,
  PostSupplyChainAlbumParams,
} from "../../api/supplyChain/postSupplyChainAlbum";
import { AlbumsData } from "./useGetSupplyChainAlbumsQuery";

const useSupplyChainAlbumMutation = (albumId?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QUERY_KEYS.POST_SUPPLY_CHAIN_ALBUM,
      { albumId: albumId || null },
    ],
    mutationFn: async (params: PostSupplyChainAlbumParams) => {
      // TODO: Add analytics
      return postSupplyChainAlbum(params);
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        [QUERY_KEYS.GET_SUPPLY_CHAIN_ALBUMS],
        (prevData: AlbumsData | undefined) => {
          return { ...(prevData || {}), [result.id]: result };
        },
      );
      return result;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};

export default useSupplyChainAlbumMutation;
