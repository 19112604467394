import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { SCREENS } from "../../../routes/screens";
import { getIsSuperuser } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CustomerPortalHomeScreen } from "./CustomerPortalHomeScreen";
import "./CustomerPortalHomeScreen.css";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

export const CustomerPortalIndex = () => {
  const dispatch = useAppDispatch();
  const [isSuperUser, setIsSuperuser] = useState(false);
  const [loading, setLoading] = useState(true);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  useSetPageTitle(undefined);

  useEffect(() => {
    // Call backend API to check that user is superuser
    // raise backend exception if user is not super user
    dispatch(getIsSuperuser())
      .unwrap()
      .then((data) => {
        const { is_superuser } = data;
        setIsSuperuser(is_superuser);
        setLoading(false);
      });
  });

  if (loading) {
    return <LoadingScreen />;
  }
  if (!loggedInUser) {
    return <Redirect to={SCREENS.HOME_SCREEN} />;
  }
  if (isSuperUser) {
    return (
      <div className="container-fluid centered-screen">
        <Switch>
          <Route
            component={CustomerPortalHomeScreen}
            path={SCREENS.CUSTOMER_PORTAL.replace(":tab", "index")}
          />
          <Route
            component={CustomerPortalHomeScreen}
            path={SCREENS.CUSTOMER_PORTAL.replace(":tab", "memberships")}
          />
          <Route
            component={CustomerPortalHomeScreen}
            path={SCREENS.CUSTOMER_PORTAL.replace(":tab", "projects")}
          />
          <Route
            component={CustomerPortalHomeScreen}
            path={SCREENS.CUSTOMER_PORTAL.replace(":tab", "credits")}
          />
          <Route
            component={CustomerPortalHomeScreen}
            path={SCREENS.CUSTOMER_PORTAL.replace(
              ":tab",
              "community-management",
            )}
          />
        </Switch>
      </div>
    );
  }

  return <div className="container-fluid centered-screen">Access Denied</div>;
};
