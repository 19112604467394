import { loadStripe } from "@stripe/stripe-js";

let stripeApiKey =
  "pk_test_51HtM5xDDQAW5CNoRo596n0AbY1wB1LIqsRfQoowSms0ZWrzwd3HeK1Sn3dBxoChxKZENFZ9Q6QJxuXymxo1k9yg7002DMAIQdS";
if (
  window.location.hostname === "engineears.com" ||
  window.location.hostname === "www.engineears.com" ||
  window.location.hostname === "engineears-215922.appspot.com" ||
  window.location.hostname ===
    "prodstaging-dot-engineears-215922.appspot.com" ||
  window.location.hostname === "prodstaging.engineears.org"
) {
  stripeApiKey =
    "pk_live_51HtM5xDDQAW5CNoRQ6HiVZUiI3lbCuMlYBcsJPCgVnD3eXlfn5HxOSZlQyGwsffZp0xTqYRjrBS73hNiG2zjwbnf00J3HVsULR";
}
export const stripePromise = loadStripe(stripeApiKey, {
  betas: ["klarna_pm_beta_1"],
});
