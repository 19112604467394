import { useAtomCallback } from "jotai/utils";
import { AuthSteps, FLOW_STEPS } from "../../constants/authSteps";
import { useAppSelector } from "../../store/hooks";
import {
  selectEmailVerified,
  selectPhoneAdded,
  selectPhoneVerified,
} from "../../store/selectors/userInfoSelectors";
import {
  authModalOpenAtom,
  currentFlowAtom,
  currentSignUpStepAtom,
} from "../../stories/components/Auth/atoms";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { useMediaQueryBreakpoint } from "../useMediaQuery";
import { useHistory } from "react-router-dom";
import { getProfileScreenRoute } from "../../store/utils/routeGetters";

/**
 * @description
 * This will return the steps for the current flow and the function to navigate to the previous step.
 */
export const useAuthNavigationSteps = () => {
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const isPhoneVerified = useAppSelector(selectPhoneVerified);
  const isPhoneAdded = useAppSelector(selectPhoneAdded);
  const { isMobile } = useMediaQueryBreakpoint();
  const { user } = useAppSelector((state) => state.accountInfo);

  const history = useHistory();

  return useAtomCallback((get, set) => {
    const currentFlow = get(currentFlowAtom);
    const currentStep = get(currentSignUpStepAtom);
    const flowSteps = FLOW_STEPS[currentFlow]?.steps.flat() || [];

    const nextStep = (options?: { skipVerification?: boolean }) => {
      const currentStepIndex = flowSteps.indexOf(currentStep);
      let targetStepIndex = currentStepIndex;

      if (currentStepIndex === flowSteps.length - 1) {
        if (isMobile) {
          history.push(getProfileScreenRoute(user?.username ?? ""));
        }
        set(authModalOpenAtom, false);
        return;
      }

      if (options?.skipVerification) {
        targetStepIndex = flowSteps.length - 1;
      } else {
        for (let i = currentStepIndex + 1; i < flowSteps.length; i++) {
          const nextStep = flowSteps[i];

          if (nextStep === AuthSteps.VERIFY_EMAIL && isEmailVerified) {
            continue;
          }

          if (
            (nextStep === AuthSteps.ADD_PHONE ||
              nextStep === AuthSteps.AANDR_ADD_PHONE) &&
            isPhoneAdded
          ) {
            continue;
          }

          if (
            (nextStep === AuthSteps.VERIFY_PHONE ||
              nextStep === AuthSteps.AANDR_VERIFY_PHONE) &&
            isPhoneVerified
          ) {
            continue;
          }

          targetStepIndex = i;
          break;
        }
      }

      set(currentSignUpStepAtom, flowSteps[targetStepIndex]);
    };

    const prevStep = () => {
      emitAnalyticsTrackingEvent("auth_prev_step_clicked", {});
      const currentStepIndex = flowSteps.indexOf(currentStep);
      if (currentStepIndex > 0) {
        const prevStepIndex = currentStepIndex - 1;

        if (flowSteps[prevStepIndex] === AuthSteps.VERIFY_EMAIL) {
          set(currentSignUpStepAtom, flowSteps[prevStepIndex - 2]);
        } else if (
          flowSteps[prevStepIndex] === AuthSteps.VERIFY_PHONE ||
          flowSteps[prevStepIndex] === AuthSteps.AANDR_VERIFY_PHONE
        ) {
          set(currentSignUpStepAtom, flowSteps[prevStepIndex - 2]);
        } else {
          set(currentSignUpStepAtom, flowSteps[prevStepIndex]);
        }
      }
    };
    return {
      nextStep,
      prevStep,
      flowSteps,
    };
  });
};
