import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useIsAdmin } from "../../../hooks/useIsAdmin";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { useGetTeams } from "../../../hooks/useTeam";
import { SCREENS } from "../../../routes/screens";
import { useAppSelector } from "../../../store/hooks";
import { ActionItems } from "../../components/ActionItems/ActionItems";
import { PlatformActivityTable } from "../../components/PlatformActivityTable/PlatformActivityTable";
import { SidePanel } from "../../components/SidePanel/SidePanel";
import { TeamPanel } from "../../components/TeamPanel/TeamPanel";
import { UserCard } from "../../components/UserCard/UserCard";
import { Card } from "../../elements/Card/Card";
import AdminDashboardActivityCard from "./AdminDashboardActivityCard";
import { AdminDashboardMobileScreen } from "./AdminDashboardMobileScreen";
import "./AdminDashboardScreen.css";
import { AdminDashboardHeaderContainer } from "./AdminDashboardScreen.styles";
import AdminDashboardStudioTeamCard from "./AdminDashboardStudioTeamCard";
import { AdminGenerateBookingButton } from "./AdminGenerateBookingButton";
import {
  adminSidePanelAtom,
  displayOptionAtom,
  DisplayOptions,
  togglePanelAtom,
} from "./atoms";

export const AdminDashboardScreen = () => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const { loading, selectedTeam } = useGetTeams();
  const [isPanelOpen, setIsPanelOpen] = useAtom(adminSidePanelAtom);
  const { isDesktop } = useMediaQueryBreakpoint();
  const isAdmin = useIsAdmin(loggedInUser);
  const displaySelected = useAtomValue(displayOptionAtom);
  const handlePanelToggle = useSetAtom(togglePanelAtom);
  const location = useLocation();

  // Closes the panel on unmount and route change since we don't always unmount screens
  useEffect(() => {
    setIsPanelOpen(false);
    return () => {
      setIsPanelOpen(false);
    };
  }, [setIsPanelOpen, location.pathname]);

  useSetPageTitle("Dashboard");
  const wrappedTeamPanel = (
    <AdminDashboardStudioTeamCard
      cardHeight={305}
      onClick={() => {
        handlePanelToggle(DisplayOptions.Team);
      }}
    />
  );

  const wrappedPlatformActivityTable = (
    <AdminDashboardActivityCard
      cardHeight={305}
      onClick={() => handlePanelToggle(DisplayOptions.Activity)}
    />
  );

  const wrappedAdminActionItems = (
    <Card
      hideOverflow={true}
      height={380}
      customClassName="admin-dashboard-row-container"
    >
      <ActionItems
        onClick={() => handlePanelToggle(DisplayOptions.Action_Items)}
        onSidePanel={false}
      />
    </Card>
  );

  const displaySelectedComponent = () => {
    switch (displaySelected) {
      case DisplayOptions.Action_Items:
        return (
          <ActionItems
            onClick={() => handlePanelToggle(DisplayOptions.Action_Items)}
            onSidePanel={true}
          />
        );
      case DisplayOptions.Activity:
        return (
          <PlatformActivityTable onSidePanel={true} teamId={selectedTeam?.id} />
        );
      case DisplayOptions.Team:
        return (
          <TeamPanel
            currentTeam={selectedTeam}
            isLoading={loading}
            onSidePanel={true}
          />
        );
      default:
        return null;
    }
  };

  if (!isAdmin || !loggedInUser) {
    return <Redirect to={SCREENS.SIGNED_OUT_HOME_SCREEN} />;
  }

  return (
    <div className="admin-dashboard-screen-container container">
      <AdminDashboardHeaderContainer>
        <UserCard
          loggedInUser={loggedInUser}
          selectedProfile={selectedProfile}
        />
        <AdminGenerateBookingButton />
      </AdminDashboardHeaderContainer>
      {isDesktop && (
        <>
          <div className="admin-dashboard-row-container">
            {wrappedAdminActionItems}
          </div>
          <div className="admin-dashboard-columns-container">
            <div className="admin-dashboard-left-column-container">
              <div className="admin-dashboard-shared-container">
                {wrappedTeamPanel}
              </div>{" "}
            </div>
            <div className="admin-dashboard-right-column-container">
              {wrappedPlatformActivityTable}
            </div>
          </div>
        </>
      )}
      {!isDesktop && <AdminDashboardMobileScreen />}
      <SidePanel
        isOpen={isPanelOpen}
        onClose={() => setIsPanelOpen(!isPanelOpen)}
        fillScreen={!isDesktop}
      >
        {displaySelectedComponent()}
      </SidePanel>
    </div>
  );
};
