import {
  sectionsWithComparisons,
  SUBSCRIPTION_PLAN,
} from "../../../store/models/subscription";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useSubscriptionMonthlyPrice } from "../../../hooks/useSubscriptionTrophy";
import { SubscriptionFeatureItem } from "./SubscriptionComparisonTable.styles";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { useAppSelector } from "../../../store/hooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { SCREENS } from "../../../routes/screens";
import { useSetAtom } from "jotai";
import { authModalOpenAtom, currentFlowAtom } from "../Auth/atoms";
import { AuthFlows } from "../../../constants/authSteps";

interface SubscriptionComparisonTableProps {
  onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => void;
}

export const SubscriptionComparisonTable = forwardRef<
  HTMLDivElement,
  SubscriptionComparisonTableProps
>(({ onSubscriptionSelected }, ref) => {
  const history = useHistory();
  const theme = useTheme();
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const { isMobile } = useMediaQueryBreakpoint();
  const setFlow = useSetAtom(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const user = useAppSelector((state) => state.accountInfo.user);

  return (
    <Box ref={ref} sx={{ display: "flex", alignItems: "flex-start" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Text variant={TextStyleVariant.S2} bold>
                Features
              </Text>
            </TableCell>
            <TableCell align="center">
              <Text variant={TextStyleVariant.S2} bold>
                Essentials
              </Text>
            </TableCell>
            <TableCell
              align="center"
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                borderTopLeftRadius: theme.border.radius.lg,
                borderTopRightRadius: theme.border.radius.lg,
                boxShadow:
                  "6px 2px 10px 0px rgba(0, 0, 0, 0.15), -6px 2px 10px 0px rgba(0, 0, 0, 0.15)",
              })}
            >
              <Text variant={TextStyleVariant.S2} bold>
                Platinum
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(sectionsWithComparisons.entries()).map(
            ([section, comparisons]) => (
              <>
                {/* Main category row */}
                <TableRow
                  key={section}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.customColor.midgroundColor,
                    boxShadow: "6px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                    "& td": {
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    },
                  })}
                >
                  <TableCell colSpan={3}>
                    <Text variant={TextStyleVariant.P1} bold>
                      {section}
                    </Text>
                  </TableCell>
                </TableRow>
                {/* Subcategory feature rows */}
                {comparisons.map((comparison, index) => {
                  return (
                    <TableRow
                      key={`${section}-${index}`}
                      sx={(theme) => ({
                        "& td": {
                          borderBottom: `1px solid ${theme.palette.customColor.darkerMidgroundColor}`,
                        },
                      })}
                    >
                      {/* Feature name */}
                      <TableCell sx={{ maxWidth: "326px" }}>
                        {comparison.feature}
                      </TableCell>
                      {/* Essentials comparison */}
                      <TableCell align="center" sx={{ maxWidth: "280px" }}>
                        {comparison.comparison.essentials.displayIcon ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size={"lg"}
                            color={
                              comparison.comparison.essentials.disabledColor
                                ? theme.palette.customColor.buttonColorHover
                                : theme.palette.standardColor.BoomyOrange[500]
                            }
                          />
                        ) : null}
                        {comparison.comparison.essentials.text ? (
                          <Text
                            variant={TextStyleVariant.P1}
                            style={{
                              color: comparison.comparison.essentials
                                .disabledColor
                                ? theme.palette.customColor
                                    .unselectedTextPrimaryColor
                                : theme.palette.standardColor.BoomyOrange[500],
                            }}
                          >
                            {comparison.comparison.essentials.text}
                          </Text>
                        ) : null}
                        {comparison.comparison.essentials.subtext ? (
                          <Text
                            variant={TextStyleVariant.P2}
                            style={{
                              color:
                                theme.palette.customColor
                                  .unselectedTextPrimaryColor,
                            }}
                          >
                            {comparison.comparison.essentials.subtext}
                          </Text>
                        ) : null}
                      </TableCell>
                      {/* Platinum comparison */}
                      <TableCell
                        align="center"
                        sx={(theme) => ({
                          maxWidth: "280px",
                          backgroundColor: theme.palette.background.paper,
                          boxShadow:
                            "6px 2px 10px 0px rgba(0, 0, 0, 0.15), -6px 2px 10px 0px rgba(0, 0, 0, 0.15)",
                        })}
                      >
                        {comparison.comparison.platinum.displayIcon ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size={"lg"}
                            color={
                              comparison.comparison.platinum.disabledColor
                                ? theme.palette.customColor.buttonColorHover
                                : theme.palette.standardColor.BoomyOrange[500]
                            }
                          />
                        ) : null}
                        {comparison.comparison.platinum.text ? (
                          <Text
                            variant={TextStyleVariant.P1}
                            style={{
                              color: comparison.comparison.platinum
                                .disabledColor
                                ? theme.palette.customColor
                                    .unselectedTextPrimaryColor
                                : theme.palette.standardColor.BoomyOrange[500],
                            }}
                          >
                            {comparison.comparison.platinum.text}
                          </Text>
                        ) : null}
                        {comparison.comparison.platinum.subtext ? (
                          <Text
                            variant={TextStyleVariant.P2}
                            style={{
                              color:
                                theme.palette.customColor
                                  .unselectedTextPrimaryColor,
                            }}
                          >
                            {comparison.comparison.platinum.subtext}
                          </Text>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ),
          )}
          {/* Add a row for the buttons to subscribe */}
          <TableRow>
            <TableCell sx={{ borderBottom: "none" }} />
            <TableCell align="center" sx={{ borderBottom: "none" }}>
              <SubscriptionFeatureItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "16px",
                }}
              >
                <Text
                  variant={TextStyleVariant.H5}
                  style={{ fontWeight: "400" }}
                >
                  $
                  {useSubscriptionMonthlyPrice(
                    SUBSCRIPTION_PLAN.ESSENTIALS_PLAN,
                  )}
                  <Text
                    variant={TextStyleVariant.P1}
                    style={{ display: "inline" }}
                  >
                    /mo
                  </Text>
                </Text>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    if (isAuthenticated) {
                      onSubscriptionSelected(SUBSCRIPTION_PLAN.ESSENTIALS_PLAN);
                      return;
                    }
                    if (isMobile) history.push(SCREENS.AUTH_SCREEN);
                    setFlow(AuthFlows.START_SIGNUP);
                    setAuthModalOpen(true);
                  }}
                  fullWidth
                >
                  Get started
                </Button>
              </SubscriptionFeatureItem>
            </TableCell>
            <TableCell
              align="center"
              sx={(theme) => ({
                maxWidth: "280px",
                backgroundColor: theme.palette.background.paper,
                borderBottom: "none",
                borderBottomLeftRadius: theme.border.radius.lg,
                borderBottomRightRadius: theme.border.radius.lg,
                boxShadow:
                  "6px 2px 10px 0px rgba(0, 0, 0, 0.15), -6px 2px 10px 0px rgba(0, 0, 0, 0.15)",
              })}
            >
              <SubscriptionFeatureItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "16px",
                }}
              >
                <Text
                  variant={TextStyleVariant.H5}
                  style={{ fontWeight: "400" }}
                >
                  $
                  {useSubscriptionMonthlyPrice(
                    SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY,
                  )}
                  <Text
                    variant={TextStyleVariant.P1}
                    style={{ display: "inline" }}
                  >
                    /mo
                  </Text>
                </Text>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    if (isAuthenticated) {
                      onSubscriptionSelected(
                        SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY,
                      );
                      return;
                    }
                    if (isMobile) history.push(SCREENS.AUTH_SCREEN);
                    setFlow(AuthFlows.START_SIGNUP);
                    setAuthModalOpen(true);
                  }}
                  fullWidth
                >
                  {user?.engineer?.stripe_subscription_id
                    ? "Select plan"
                    : "Start trial"}
                </Button>
              </SubscriptionFeatureItem>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
});

SubscriptionComparisonTable.displayName = "SubscriptionComparisonTable";
