import CircularProgress from "@mui/material/CircularProgress";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { WaveForm } from "wavesurfer-react";
import WaveSurferRef from "wavesurfer.js";
import RegionsPlugin, {
  Region as RegionType,
} from "wavesurfer.js/dist/plugins/regions";

import { useTheme } from "styled-components";
import { useResizeObserver } from "usehooks-ts";
import { useAddStyleToShadowRoot } from "../../../hooks/audioPlayerHooks/useAddStyleToShadowRoot";
import { useCreateLoopRegions } from "../../../hooks/audioPlayerHooks/useCreateLoopRegions";
import { useSetupOnClick } from "../../../hooks/audioPlayerHooks/UseSetupOnClick";
import { DEFAULT_TIME } from "../../../hooks/audioPlayerHooks/waveformHooks";
import {
  convertWaveformDurationToReadableTime,
  toggleWaveFormColors,
} from "../../../hooks/audioPlayerHooks/waveformUtils";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";
import { useSetupEnableRegionDrag } from "../../../hooks/RegionHooks/useSetupEnableRegionDrag";
import usePrevious from "../../../hooks/usePrevious";
import {
  FooterFileTrackType,
  setIsLooping,
  setLocalPlayer,
  setMainPlayer,
  setRefPlayer,
  setSeekTo,
} from "../../../store/actions/abPlayerStore";
import {
  downloadFileVersionTrack,
  downloadTrackSnippet,
} from "../../../store/actions/audioService";
import { updateFileComment } from "../../../store/actions/fileVersionComments";
import {
  resetSelectCommentState,
  selectComment,
} from "../../../store/actions/selectedComment";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { FileVersionComment } from "../../../store/models/fileVersion";
import convertPortfolioFeatureDataToPlayListTrack from "../../../store/models/playListTrack/helpers/convertPortfolioFeatureDataToPlayListTrack";
import convertProjectToPlayListTrack from "../../../store/models/playListTrack/helpers/convertProjectToPlayListTrack";
import { Project, ProjectById } from "../../../store/models/project";
import {
  getFileVersionBlobUrl,
  getFileVersionBlobUrlIsLoading,
  getFileVersionGeneratedMP3BlobUrl,
} from "../../../store/selectors/fileVersions";
import { getPortfolioFeatureDataFromProjectId } from "../../../store/selectors/portfolioProjectSelectors";
import { getTrackComments } from "../../../store/selectors/trackComments";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { ColorPalette, defaultPageLoaderSize } from "../../theme";
import { Size } from "../SessionScheduleGuide/SessionScheduleGuide";
import {
  StyledWaveFormComponentContainer,
  StyledWaveFormComponentDiv,
  StyledWaveFormComponentLoadingLosslessAudioContainer,
  StyledWaveFormComponentP,
  StyledWaveFormComponentWaveSurfer,
  StyledWaveFormComponentWithTimeDiv,
} from "./WaveFormComponent.styles";

export interface WaveFormComponentProps {
  footerPlayerRef: React.MutableRefObject<WaveSurferRef | null>;
  fileVersionId: number;
  code: string | null;
  loadComments: boolean;
  canAddComment: boolean;
  isSelected: boolean;
  isCurrentEngineer: boolean;
  onReady?: (isReady: boolean) => void;
  isSnippet?: boolean;
  waveformReference: React.MutableRefObject<WaveSurferRef | null>;
  allowTrackPreview?: boolean;
  project?: Project | ProjectById;
  isReference: boolean;
  isLatestMain: boolean;
}

declare global {
  interface Window {
    surferidze?: {
      [fileId: string | number]: WaveSurferRef;
    };
  }
}

export const WaveFormComponent = ({
  footerPlayerRef,
  fileVersionId,
  code,
  loadComments,
  canAddComment,
  isSelected,
  isCurrentEngineer,
  onReady,
  isSnippet = false,
  waveformReference,
  allowTrackPreview = true,
  project,
  isReference,
  isLatestMain,
}: WaveFormComponentProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const {
    currentPosition,
    seekToValue: seekTo,
    isLooping,
    projectId: trackedProjectId,
    url: urlFromStore,
    isFooterPlaying,
  } = useAppSelector((state) => state.abPlayerStore);
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();
  const [width, setWidth] = useState(0); // This holds the container width;
  const containerRef = useRef<HTMLDivElement>(null);
  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setWidth(size.width || 0);
      }, 0);
    },
    [setWidth],
  );
  useResizeObserver({
    ref: containerRef,
    box: "border-box",
    onResize,
  });
  const { isUpdating } = useAppSelector((state) => state.selectedComment);
  const previousFileVersionId = usePrevious<number>(fileVersionId);
  const fileVersionBlobUrl = useAppSelector(
    getFileVersionBlobUrl(fileVersionId),
  );
  const fileVersionBlobUrlIsLoading = useAppSelector(
    getFileVersionBlobUrlIsLoading(fileVersionId),
  );
  const fileVersionGeneratedMP3BlobUrl = useAppSelector(
    getFileVersionGeneratedMP3BlobUrl(fileVersionId),
  );
  const currentSeekTime = useRef<string | null>(DEFAULT_TIME);
  const unauthenticatedUserName = useAppSelector(
    (state) => state.unauthenticatedUserStateSlice.name,
  );
  const { page } = useAppSelector((state) => state.fileVersionCommentsSlice);
  const comments = useAppSelector(getTrackComments(page, project?.id));
  const [url, setURL] = useState<string>("");
  const [isWavesurferMounted, setIsWavesurferMounted] =
    useState<boolean>(false);
  const isWavesurferMountedRef = useRef(isWavesurferMounted);
  const [selectedCommentRegion, setSelectedCommentRegion] = useState<
    RegionType | undefined
  >();
  const previousSelectedCommentRegion = usePrevious<RegionType | undefined>(
    selectedCommentRegion,
  );
  const selectedCommentRegionRef = useRef(selectedCommentRegion);
  const previousSelectedCommentRegionRef = useRef(
    previousSelectedCommentRegion,
  );
  const isUpdatingRef = useRef(isUpdating);
  const isSelectedRef = useRef(isSelected);
  const commentsRef = useRef(comments);
  const isLoopingRef = useRef(isLooping);
  const regionsPluginRef = useRef<RegionsPlugin | null>(null);
  const [duration, setDuration] = useState<string>(DEFAULT_TIME);
  // TODO: don't fetch if not necessary
  const portfolioFeatureData = useAppSelector(
    getPortfolioFeatureDataFromProjectId(project?.id),
  );
  const waveformId = `waveform-${fileVersionId}`;
  const canUserUpdateComment = useCallback(
    (comment: FileVersionComment) => {
      // check if comment's author matches the current logged in user
      if (currentUser && comment.author_user?.id === currentUser?.id) {
        return true;
      }

      // check if comment's author matches the current logged out user (via share link)
      if (unauthenticatedUserName === comment.author_name) {
        return true;
      }

      return false;
    },
    [currentUser, unauthenticatedUserName],
  );

  const unselectPreviousRegion = useCallback(() => {
    if (previousSelectedCommentRegionRef.current) {
      const commentExists = getActiveComment(
        previousSelectedCommentRegionRef.current.id,
      );
      if (!commentExists) {
        const regionToDelete = regionsPluginRef.current
          ?.getRegions()
          ?.find(
            (region) =>
              region.id === previousSelectedCommentRegionRef.current?.id,
          );
        regionToDelete?.remove();
      } else {
        previousSelectedCommentRegionRef.current.setOptions({
          color: theme.regionDefault,
          start: previousSelectedCommentRegionRef.current.start,
          end: previousSelectedCommentRegionRef.current.end,
        });
      }
    }
  }, [previousSelectedCommentRegionRef]);

  // On unmount of WaveformComponent, delete the ref to the Wavesurfer instance.
  // We do this because, we regenerate the Wavesurfer instance on every mount and replace.
  // By not deleting the ref, the usage of getSelectedTrackRef will return the previous instance until replaced.
  useEffect(() => {
    return () => {
      if (window.surferidze) delete window.surferidze[fileVersionId];
    };
  }, []);

  useEffect(() => {
    if (fileVersionId && previousFileVersionId) {
      // file version id has changed. reset the waveform element
      // while the new audio loads.
      if (fileVersionId !== previousFileVersionId) {
        if (window.surferidze) {
          delete window.surferidze[previousFileVersionId];
        }
        setURL("");
      }
    }
  }, [dispatch, fileVersionId, previousFileVersionId, waveformReference]);

  useEffect(() => {
    const newURL = fileVersionBlobUrl || fileVersionGeneratedMP3BlobUrl || "";
    if (newURL) {
      setURL((currentURL) => {
        if (newURL !== currentURL) {
          // Check here if we're swapping from an MP3 URL to a WAV URL.
          // If we are, load the WAV URL into the Wavesurfer library.
          return newURL;
        }
        return currentURL;
      });
    }
  }, [dispatch, fileVersionBlobUrl, fileVersionGeneratedMP3BlobUrl]);

  useEffect(() => {
    if (project?.id !== trackedProjectId) return;
    if (isReference) {
      dispatch(setRefPlayer({ id: fileVersionId, url: url }));
      return;
    }
    if (isLatestMain) {
      dispatch(
        setMainPlayer({
          url: url,
          id: fileVersionId,
        }),
      );
    }
  }, [
    isReference,
    isLatestMain,
    project?.id,
    trackedProjectId,
    url,
    dispatch,
    fileVersionId,
  ]);

  // Needed so wavesurfer callbacks can use the most recent version of commentStoreRef.
  useEffect(() => {
    commentsRef.current = comments;
  }, [comments]);

  useEffect(() => {
    isUpdatingRef.current = isUpdating;
  }, [isUpdating]);

  useEffect(() => {
    selectedCommentRegionRef.current = selectedCommentRegion;
  }, [selectedCommentRegion]);

  useEffect(() => {
    previousSelectedCommentRegionRef.current = previousSelectedCommentRegion;
  }, [previousSelectedCommentRegion]);

  useEffect(() => {
    isLoopingRef.current = isLooping;
  }, [isLooping]);

  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  useEffect(() => {
    isWavesurferMountedRef.current = isWavesurferMounted;
  }, [isWavesurferMounted]);

  // Load the track from the backend.
  useEffect(() => {
    if (fileVersionId < 0) return;
    if (!isSnippet && fileVersionBlobUrl) {
      return;
    }
    if (!isSnippet) {
      const onlyMp3 = false;
      downloadFileVersionTrack(
        dispatch,
        onlyMp3,
        fileVersionId,
        code ?? undefined,
        undefined,
        invalidateOnboardingProgress,
      );
    } else {
      void dispatch(downloadTrackSnippet({ fileVersionId: fileVersionId }))
        .unwrap()
        .then((data) => {
          setURL(data);
        });
    }
  }, [fileVersionId, code, isSnippet, dispatch, fileVersionBlobUrl]);

  useEffect(() => {
    return () => {
      setSelectedCommentRegion(undefined);
      regionsPluginRef.current?.unAll();
      regionsPluginRef.current?.destroy();
      waveformReference.current?.unAll();
      waveformReference.current?.destroy();
      dispatch(setIsLooping({ isLooping: false }));
    };
  }, [waveformReference, regionsPluginRef, fileVersionId, dispatch]);

  // seek to other waveform's current position.
  useEffect(() => {
    if (project?.id !== trackedProjectId) return;
    if (waveformReference.current === null) return;
    if (footerPlayerRef.current === null) return;
    const duration = waveformReference.current.getDuration();
    if (!duration) return;
    if (duration < currentPosition) return;
    if (currentPosition < 0) return;
    const seekToValue = currentPosition / duration;
    waveformReference.current.seekTo(seekToValue);
    currentSeekTime.current = convertWaveformDurationToReadableTime(
      footerPlayerRef.current.getCurrentTime(),
    );
  }, [
    currentPosition,
    footerPlayerRef,
    project?.id,
    trackedProjectId,
    waveformReference,
  ]);

  useEffect(() => {
    toggleWaveFormColors(waveformReference.current, isSelected);
  }, [isSelected, waveformReference.current]);

  // seeks to a certain spot in the song after a comment is clicked
  // based on `seekTo` value in ABPlayerStore
  useEffect(() => {
    if (waveformReference.current === null) return;
    if (footerPlayerRef.current === null) return;
    if (!isSelected) return;
    if (!seekTo) return;
    const duration = waveformReference.current.getDuration();
    if (!duration) return;
    let seekToValue = (seekTo - 0.1) / duration;
    if (seekToValue < 0) seekToValue = 0;
    footerPlayerRef.current?.seekTo(seekToValue);
    dispatch(setSeekTo(undefined));
  }, [seekTo, isSelected, waveformReference, dispatch, footerPlayerRef]);

  const getActiveComment = useCallback(
    (regionId: string) => {
      return commentsRef.current?.find(
        (c) => c.unique_css_identifier === regionId,
      );
    },
    [commentsRef],
  );

  const loadedTrack = useMemo(() => {
    if (isSnippet)
      return convertPortfolioFeatureDataToPlayListTrack(portfolioFeatureData);
    if (!project) return undefined;
    return convertProjectToPlayListTrack(project);
  }, [isSnippet, portfolioFeatureData?.id, project?.id]);

  useEffect(() => {
    if (!isSelected) return;
    if (url === urlFromStore || !url) return;
    dispatch(
      setLocalPlayer({
        url: url,
        trackedPlayerId: fileVersionId,
        keepPosition: true,
        abState: isReference,
        playOnLoad: isFooterPlaying,
        footerFileTrackType: isSnippet
          ? FooterFileTrackType.AB_SNIPPET
          : FooterFileTrackType.AB_INSTANCE,
        loadedTrack: loadedTrack,
      }),
    );
  }, [
    dispatch,
    isFooterPlaying,
    fileVersionId,
    isReference,
    isSelected,
    url,
    isSnippet,
  ]);

  useEffect(() => {
    /*
     * When the selected track is changed we want to clean up the selected region
     */
    if (selectedCommentRegion) {
      const commentExists = getActiveComment(selectedCommentRegion.id);
      if (!commentExists) {
        selectedCommentRegion.remove();
      } else {
        selectedCommentRegion.setOptions({
          color: theme.regionDefault,
          start: selectedCommentRegion.start,
          end: selectedCommentRegion.end,
        });
      }
      setSelectedCommentRegion(undefined);
    }
    dispatch(resetSelectCommentState());
  }, [isSelected]);

  const onRegionPlaybackStart = useCallback(
    (region: RegionType) => {
      if (isUpdatingRef.current || !isSelectedRef.current) return;
      dispatch(selectComment({ commentIdentifier: region.id, show: true }));
    },
    [dispatch],
  );

  const onRegionPlaybackEnd = useCallback(() => {
    if (isUpdatingRef.current || !isSelectedRef.current) return;
    dispatch(selectComment({ commentIdentifier: undefined, show: false }));
  }, [dispatch]);

  const onCreateRegion = useCallback(
    (region: RegionType) => {
      if (!isSelectedRef.current) return;

      const commentExists = getActiveComment(region.id);
      if (commentExists) return;

      // On update-end fires when the region is resized via drag.
      // This will update the loop start and end times.
      region.on("update-end", () => {
        if (
          isLoopingRef.current &&
          region.id === selectedCommentRegionRef.current?.id
        ) {
          dispatch(
            setIsLooping({
              isLooping: true,
              loopStartTime: region.start,
              loopEndTime: region.end,
              loopRegionId: region.id,
            }),
          );
        }
      });

      unselectPreviousRegion();
      region.setOptions({
        color: theme.regionSelected,
        start: region.start,
        end: region.end,
      });
      setSelectedCommentRegion(region);
      dispatch(
        selectComment({
          commentIdentifier: region.id,
          show: false,
          start: region.start,
          end: region.end,
        }),
      );
    },
    [dispatch],
  );

  const onClickRegion = useCallback(
    (region: RegionType) => {
      if (!isSelectedRef.current) return;

      // Handle if they are clicking already selected region (Unselect)
      if (region.id == selectedCommentRegionRef.current?.id) {
        // Check if previous selected region belongs to an existing comment
        if (previousSelectedCommentRegionRef.current) {
          const commentExists = getActiveComment(
            previousSelectedCommentRegionRef.current?.id,
          );
          // If the comment does not exist, we can remove the region
          if (!commentExists) {
            setSelectedCommentRegion(undefined);
            dispatch(setIsLooping({ isLooping: false }));
            previousSelectedCommentRegionRef.current.remove();
            dispatch(resetSelectCommentState());
            return;
          }
        }
        // If the comment exists, we should not remove the region but change color
        region.setOptions({
          color: theme.regionDefault,
          start: region.start,
          end: region.end,
        });
        setSelectedCommentRegion(undefined);
        dispatch(setIsLooping({ isLooping: false }));
        dispatch(resetSelectCommentState());
        return;
      }

      unselectPreviousRegion();
      const activeComment = getActiveComment(region.id);
      if (!activeComment) return;
      setSelectedCommentRegion(region);
      if (isLoopingRef.current) {
        dispatch(
          setIsLooping({
            isLooping: true,
            loopStartTime: region.start,
            loopEndTime: region.end,
            loopRegionId: region.id,
          }),
        );
      }
      if (activeComment.author_user?.id === currentUser?.id) {
        dispatch(
          selectComment({
            commentIdentifier: region.id,
            show: false,
            start: region.start,
            end: region.end,
            isUpdating: true,
          }),
        );
        region.setOptions({
          color: theme.regionSelected,
          start: region.start,
          end: region.end,
        });
      }
    },
    [dispatch],
  );

  const onUpdateRegion = useCallback(
    (region: RegionType) => {
      if (!isSelectedRef.current) return;

      const activeComment = getActiveComment(region.id);
      if (!activeComment) {
        setSelectedCommentRegion(region);
        region.setOptions({
          color: theme.regionSelected,
          start: region.start,
          end: region.end,
        });
        dispatch(
          selectComment({
            commentIdentifier: region.id,
            show: false,
            start: region.start,
            end: region.end,
          }),
        );
      } else {
        if (canUserUpdateComment(activeComment)) {
          void dispatch(
            updateFileComment({
              file_version_comment_id: activeComment.id,
              comment: activeComment.comment,
              start_timestamp_in_seconds: region.start,
              end_timestamp_in_seconds: region.end,
              file_version_id: fileVersionId,
              unique_css_identifier: region.id,
              code: code ?? undefined,
            }),
          ).then(() => {
            unselectPreviousRegion();
            setSelectedCommentRegion(region);
            region.setOptions({
              color: theme.regionSelected,
              start: region.start,
              end: region.end,
            });
            dispatch(
              selectComment({
                commentIdentifier: region.id,
                show: false,
                start: region.start,
                end: region.end,
                isUpdating: true,
              }),
            );
          });
        }
      }
    },
    [
      isCurrentEngineer,
      getActiveComment,
      canUserUpdateComment,
      dispatch,
      fileVersionId,
      code,
    ],
  );

  useEffect(() => {
    if (!loadComments || !isWavesurferMountedRef.current) return;
    if (fileVersionId < 0) return;
    if (!regionsPluginRef?.current) return;
    if (!comments) return;
    if (!waveformReference) return;
    comments.forEach((comment) => {
      if (
        comment.reply_to === null &&
        comment.file_version_id === fileVersionId
      ) {
        const {
          start_timestamp_in_seconds: startTimestampInSeconds,
          end_timestamp_in_seconds: endTimestampInSeconds,
          unique_css_identifier: uniqueCssIdentifier,
        } = comment;
        if (
          startTimestampInSeconds != null &&
          endTimestampInSeconds != null &&
          !regionsPluginRef.current
            ?.getRegions()
            .some((region) => region.id === uniqueCssIdentifier)
        ) {
          regionsPluginRef.current!.addRegion({
            start: startTimestampInSeconds,
            end: endTimestampInSeconds,
            id: uniqueCssIdentifier,
            drag: canUserUpdateComment(comment),
            resize: canUserUpdateComment(comment),
          });
        }
      }
    });
  }, [
    fileVersionId,
    loadComments,
    dispatch,
    isWavesurferMountedRef.current,
    canAddComment,
    code,
    regionsPluginRef,
    canUserUpdateComment,
    comments,
    waveformReference,
  ]);

  const onMount = useCallback(
    async (wavesurferRef: WaveSurferRef | null) => {
      if (!url) return;
      if (wavesurferRef === null) return;
      if (onReady) onReady(false);
      if (!window.surferidze) {
        window.surferidze = {};
      }
      if (isWavesurferMounted) {
        waveformReference.current = null;
        setIsWavesurferMounted(false);
      }
      window.surferidze[fileVersionId] = wavesurferRef;

      regionsPluginRef.current = wavesurferRef.registerPlugin(
        RegionsPlugin.create(),
      );
      regionsPluginRef.current.on("region-in", onRegionPlaybackStart);
      regionsPluginRef.current.on("region-out", onRegionPlaybackEnd);
      regionsPluginRef.current.on("region-created", onCreateRegion);
      regionsPluginRef.current.on("region-double-clicked", onClickRegion);
      regionsPluginRef.current.on("region-updated", onUpdateRegion);
      waveformReference.current = wavesurferRef;
      waveformReference.current.load(url);
      waveformReference.current.once("ready", (duration) => {
        // Remove the media element from the DOM to prevent Safari from treating all audio as paused.
        waveformReference.current?.getMediaElement().remove();
        setDuration(convertWaveformDurationToReadableTime(duration));
        if (onReady) onReady(true);
        setIsWavesurferMounted(true);
      });
      // Add the empty catch to prevent the error from being thrown.
      // This prevents the error "Signal Aborted without reason" from occurring.
    },
    [
      waveformReference,
      url,
      onReady,
      onRegionPlaybackStart,
      onRegionPlaybackEnd,
      onUpdateRegion,
      onCreateRegion,
      onClickRegion,
      canAddComment,
      fileVersionId,
    ],
  );

  useCreateLoopRegions(
    regionsPluginRef,
    Boolean(!canAddComment || !isSelected),
    setSelectedCommentRegion,
    selectedCommentRegion,
  );

  useSetupOnClick(
    waveformReference,
    Boolean(!isSelected || !footerPlayerRef.current),
  );
  useSetupEnableRegionDrag(
    regionsPluginRef,
    Boolean(canAddComment && isSelected),
  );
  useAddStyleToShadowRoot(
    waveformId,
    width ? `${width}px` : undefined,
    waveformId,
  );

  return (
    <StyledWaveFormComponentContainer>
      <StyledWaveFormComponentWithTimeDiv>
        {!isSnippet && (
          <StyledWaveFormComponentP>
            {currentSeekTime.current}
          </StyledWaveFormComponentP>
        )}
        <StyledWaveFormComponentDiv ref={containerRef}>
          {!url ? (
            <SoundWaveLoader width={defaultPageLoaderSize} height={50} />
          ) : (
            <StyledWaveFormComponentWaveSurfer
              onMount={onMount}
              container={`#${waveformId}`}
              progressColor={ColorPalette.BoomyOrange200}
              waveColor={ColorPalette.Gray200}
              interact={allowTrackPreview}
              plugins={[]}
            >
              <WaveForm id={waveformId} />
            </StyledWaveFormComponentWaveSurfer>
          )}{" "}
        </StyledWaveFormComponentDiv>
        {waveformReference.current !== null && (
          <>
            {!isSnippet && (
              <StyledWaveFormComponentP>{duration}</StyledWaveFormComponentP>
            )}
            {url === fileVersionGeneratedMP3BlobUrl && (
              <StyledWaveFormComponentLoadingLosslessAudioContainer>
                {fileVersionBlobUrlIsLoading ? (
                  <>
                    <div className="caption-semi-bold">
                      Loading Lossless Audio
                    </div>
                    <CircularProgress
                      size={16}
                      className="lossless-audio-loading-circle"
                    />
                  </>
                ) : fileVersionBlobUrl === null ? (
                  <div className="caption-semi-bold">
                    Could not load lossless audio. Playing MP3 version
                  </div>
                ) : null}
              </StyledWaveFormComponentLoadingLosslessAudioContainer>
            )}
          </>
        )}
      </StyledWaveFormComponentWithTimeDiv>
    </StyledWaveFormComponentContainer>
  );
};
