import { unwrapResult } from "@reduxjs/toolkit";
// TODO: add this back in when we are ready to show the stats
// import { ReviewState } from "../../../store/models/review";
import { addDays } from "date-fns";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useEngineerCanHostServices } from "../../../hooks/useEngineerCanHostServices";
import { isStudioSeparateStripeEnabled } from "../../../hooks/useIsStudioSeperateStripeOptIn";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useQuery } from "../../../hooks/useQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { useGetTeams } from "../../../hooks/useTeam";
// import { useUserReviews } from "../../../hooks/useUserReviews";
import { SCREENS } from "../../../routes/screens";
import {
  getStripeBalance,
  loadUser,
  refreshStripeCredentials,
} from "../../../store/actions/accountInfo";
import { fetchPendingProjectCounts } from "../../../store/actions/dashboard";
import {
  getCompleteTodayProjects,
  getProjectRequests,
} from "../../../store/actions/scheduledprojects";
import { loadEngineerServices } from "../../../store/actions/services";
import { loadProjectStats } from "../../../store/actions/stats";
import { getSubscriptionStatus } from "../../../store/actions/subscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getNumberOfUpcomingDeadlines } from "../../../store/selectors/scheduledProjectSelector";
import {
  isCurrentUserEngineerOrStudioManagerSelector,
  isEngineerOnboardedSelector,
  selectIsCurrentUserEngineerSelector,
} from "../../../store/selectors/userInfoSelectors";
import { convertUTCDateToLocalDate } from "../../../store/utils/dateTimeUtils";
import { ProjectTableTab } from "../../../store/utils/projectTableUtils";
import { UniqueBreakpoints } from "../../../utils/breakpoints";
import { isUserListenerOnly } from "../../../utils/utils";
import { CalendarWeekView } from "../../components/CalendarDayGrid/CalendarMonthGrid";
import { CampaignCard } from "../../components/CampaignCard/CampaignCard";
import { CashOut } from "../../components/CashOut/CashOut";
import { CashOutModal } from "../../components/CashOutModal/CashOutModal";
import { DashboardCard } from "../../components/Dashboard/DashboardCard/DashboardCard";
import { GenerateBookingButton } from "../../components/GenerateBookingButton/GenerateBookingButton";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  BOTTOM_TAB_BAR_OVERLAY_ID,
  useBottomTabBarOverlayView,
} from "../../components/Navigation/BottomNav/useBottomTabBarOverlayView";
import PerformanceChart from "../../components/PerformanceChart/PerformanceChart";
import { PlatformActivityTable } from "../../components/PlatformActivityTable/PlatformActivityTable";
import { ReviewsList } from "../../components/ReviewsList/ReviewsList";
import { TeamPanel } from "../../components/TeamPanel/TeamPanel";
import { TransactionHistoryCard } from "../../components/TransactionHistoryCard/TransactionHistoryCard";
import { TrophyRoomCard } from "../../components/TrophyRoomList/TrophyRoomList";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Card } from "../../elements/Card/Card";
import {
  DashboardNumberCard,
  DashboardNumberColor,
} from "../../elements/DashboardNumberCard/DashboardNumberCard";
import {
  GenerateBookingServiceGroups,
  ServiceTypePopConfirm,
} from "../ProfileScreen/components/ServiceTypePopConfirm/ServiceTypePopConfirm";
import { dashboardSidePanelAtom } from "./atoms";
import "./ModularDashboard.css";
import ModularDashboardHeader from "./ModularDashboardHeader";
import ModularDashboardSidePanel from "./ModularDashboardSidePanel";
import ModularDashboardStudioTeamCard from "./ModularDashboardStudioTeamCard";
import { useHandleCreateTransaction } from "../../../hooks/useHandleCreateTransaction";
import { useEngineerHasPublicRecordingService } from "../../../hooks/profileScreenHooks/engineerGenerateBookingHooks";
import { useGetEngineerServices } from "../../../hooks/useGetEngineerServices";
import { displayGenerateBookingInfoAtom } from "../../../atoms/modals/generatedBookingInfoModal";

enum ModularDashboardDisplayOptions {
  NONE,
  TEAM,
  ACTIVITY,
  PERFORMANCE,
  CASHOUT,
  TRANSACTIONS,
}

export const ModularDashboard = () => {
  const isMobile = useMediaQuery(UniqueBreakpoints.DashboardMobile);
  const isSingleColumn = useMediaQuery(UniqueBreakpoints.DashboardTablet);
  const [sidePanelData, setSidePanelContents] = useAtom(dashboardSidePanelAtom);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const pendingCounts = useAppSelector(
    (state) => state.dashboard.pendingProjects,
  );
  const { user: loggedInUser, localUTMParams } = useAppSelector(
    (state) => state.accountInfo,
  );
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isStudio = selectedProfile.studio !== undefined;
  const isEngineer = useAppSelector(selectIsCurrentUserEngineerSelector);
  const isEngineerOnboarded = useAppSelector(isEngineerOnboardedSelector);
  const isEngineerOrStudioManager = useAppSelector(
    isCurrentUserEngineerOrStudioManagerSelector,
  );
  const meetsStripeRequirements = useAppSelector(
    (state) =>
      state.accountInfo.meetsStripeRequirements ||
      state.selectedProfileSlice?.studio?.meets_stripe_requirements,
  );

  const isStudioSeparateStripeToggled =
    isStudioSeparateStripeEnabled(selectedProfile);
  const { isAuthenticated, isLoading: isAccountInfoLoading } = useAppSelector(
    (state) => state.accountInfo,
  );
  const [stripeCredentialsRefreshed, setStripeCredentialsRefreshed] =
    useState(false);
  const [showCashOutModal, setShowCashOutModal] = useState<boolean>(false);
  const [cashingOut, setCashingOut] = useState(false);
  const { data: services, isFetching: isLoadingEngineerServices } =
    useGetEngineerServices(loggedInUser?.engineer?.id);

  const engineerHasPublicRecordingService =
    useEngineerHasPublicRecordingService(services, loggedInUser?.id);

  const { isCreatingTransaction, createTransactionAndNavigate } =
    useHandleCreateTransaction(
      localUTMParams,
      loggedInUser,
      loggedInUser?.engineer,
    );
  const setIsOpenGenerateBookingInfo = useSetAtom(
    displayGenerateBookingInfoAtom,
  );

  const handleGenerateBookingClick = (
    serviceGenerated?: GenerateBookingServiceGroups,
  ) => {
    if (!services || services?.length === 0) {
      return;
    }
    if (
      (engineerHasPublicRecordingService && services.length === 1) ||
      serviceGenerated === GenerateBookingServiceGroups.RECORDING
    ) {
      void createTransactionAndNavigate(true);
      return;
    }
    setIsOpenGenerateBookingInfo(true);
  };

  const [totalStripeBalance, setTotalStripeBalance] = useState(0);
  const [amountToWithdraw, setAmountToWithdraw] = useState(0);
  const [possibleFeeRefundAmount, setPossibleFeeRefundAmount] = useState(0);
  const [currencyToWithdraw, setCurrencyToWithdraw] = useState("usd");
  const query = useQuery();
  const subscription = useAppSelector((state) => state.subscriptionStore);
  const { projectStats, loadingProjectStats } = useAppSelector(
    (state) => state.statService,
  );
  const numUpcomingDeadlines = useAppSelector(getNumberOfUpcomingDeadlines());
  const numProjectRequests =
    pendingCounts.pendingScheduledProjectCount +
    pendingCounts.pendingRecordingSessionBookingCount;
  const { associatedTeams, loading: isLoadingTeam } = useGetTeams();
  const [isLoadingSubscriptionStatus, setIsLoadingSubscriptionStatus] =
    useState(true);

  const { isLoadingCompleteToday, isLoadingProjectRequests } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const {
    loadingPendingRecordingSessionBookings,
    pendingRecordingSessionBookings,
  } = useAppSelector((state) => state.recordingSessionReducer);

  useSetPageTitle("Dashboard");

  const redirectToProjectsPage = (
    tab: ProjectTableTab,
    toCompletedProjects = false,
  ) => {
    let projectsPath = SCREENS.PROJECTS.replace(":tab", tab);
    if (tab === ProjectTableTab.ACTIVE && toCompletedProjects) {
      projectsPath += `#${ProjectTableTab.COMPLETED}`;
    }
    history.push(projectsPath, {
      disableAutoRedirectToPendingTab: true,
    });
  };

  const generateButton = () => {
    if (
      !isStudio &&
      engineerHasPublicRecordingService &&
      services &&
      services.length > 1
    ) {
      return (
        <ServiceTypePopConfirm
          variant={ButtonVariant.PRIMARY}
          disabled={Boolean(!services?.length)}
          loading={isCreatingTransaction || isLoadingEngineerServices}
          onClick={handleGenerateBookingClick}
          fullWidth
        />
      );
    }

    if (!isStudio && services) {
      return (
        <Button
          variant={ButtonVariant.PRIMARY}
          disabled={services?.length === 0}
          loading={isCreatingTransaction || isLoadingEngineerServices}
          onClick={() => handleGenerateBookingClick()}
          fullWidth
        >
          Generate Booking
        </Button>
      );
    }

    return (
      <GenerateBookingButton
        disabled={services?.length === 0 && !isStudio}
        isLoadingEngineerServices={Boolean(isLoadingEngineerServices)}
        isMobile={isMobile}
        activeStudio={selectedProfile.studio}
      />
    );
  };

  const dateJoined = useAppSelector((state) =>
    state.selectedProfileSlice?.user?.date_joined
      ? convertUTCDateToLocalDate(
          new Date(state.selectedProfileSlice?.user?.date_joined),
        )
      : state.selectedProfileSlice?.studio?.created
        ? convertUTCDateToLocalDate(
            new Date(state.selectedProfileSlice?.studio?.created),
          )
        : new Date(),
  );
  const minStartDate = useMemo(() => {
    return new Date(dateJoined!);
  }, [dateJoined]);
  const [startDate, setStartDate] = useState<Date>(minStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const canHostServices = useEngineerCanHostServices(loggedInUser?.engineer);
  // Prevent the redirect if the user is an engineer and is not onboarded
  const isUserProfileEngineerUnOnboarded =
    !isEngineerOnboarded && selectedProfile.user;

  const shouldPreventRedirect = isUserProfileEngineerUnOnboarded;

  const panelActivityTable = useMemo(() => {
    return (
      <PlatformActivityTable
        onSidePanel={true}
        teamId={
          associatedTeams.find(
            (t) => t.studio_id === selectedProfile?.studio?.id,
          )?.id
        }
      />
    );
  }, [associatedTeams, selectedProfile?.studio?.id]);

  const panelCashOut = useMemo(() => {
    return (
      <div>
        <CashOut
          totalStripeBalance={totalStripeBalance}
          availableStripeBalance={amountToWithdraw}
          currencyToWithdraw={currencyToWithdraw}
          meetsStripeRequirements={meetsStripeRequirements}
          onClickCashOut={() => {
            setShowCashOutModal(true);
          }}
          onClickExtend={() => setSidePanelContents(undefined)}
          onClickViewTransactions={() => setSidePanelContents(undefined)}
          cashingOut={cashingOut}
          onSidePanel={true}
          contentOnly
          noHeaderIcon
        />
        <br></br>
        <TransactionHistoryCard />
      </div>
    );
  }, [
    setSidePanelContents,
    setShowCashOutModal,
    cashingOut,
    amountToWithdraw,
    currencyToWithdraw,
    meetsStripeRequirements,
  ]);

  const panelTransactions = useMemo(() => {
    return (
      <div>
        <TransactionHistoryCard
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
    );
  }, [setEndDate, setStartDate, startDate, endDate]);

  const panelPerformanceChart = useMemo(() => {
    return (
      <div>
        <PerformanceChart
          onClick={() => setSidePanelContents(undefined)}
          onClickViewTransactions={() => setSidePanelContents(undefined)}
          onSidePanel={true}
          isStudio={isStudio}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <br></br>
        <TransactionHistoryCard
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
    );
  }, [
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isStudio,
    setSidePanelContents,
  ]);

  const activityHeaderText = useMemo(
    () => (isEngineer && !isStudio ? "Your Recent Activity" : "Team Activity"),
    [isEngineer, isStudio],
  );

  const panelTeamPanel = useMemo(() => {
    return (
      <TeamPanel
        currentTeam={associatedTeams.find(
          (t) => t.studio_id === selectedProfile?.studio?.id,
        )}
        isLoading={isLoadingTeam}
        onSidePanel={true}
      />
    );
  }, [associatedTeams, selectedProfile?.studio?.id, isLoadingTeam]);
  const toggleSidePanel = (option: ModularDashboardDisplayOptions) => {
    switch (option) {
      case ModularDashboardDisplayOptions.TEAM:
        setSidePanelContents(panelTeamPanel, "Your Team");
        break;
      case ModularDashboardDisplayOptions.ACTIVITY:
        setSidePanelContents(panelActivityTable, activityHeaderText);
        break;
      case ModularDashboardDisplayOptions.PERFORMANCE:
        setSidePanelContents(panelPerformanceChart);
        break;
      case ModularDashboardDisplayOptions.CASHOUT:
        setSidePanelContents(panelCashOut);
        break;
      case ModularDashboardDisplayOptions.TRANSACTIONS:
        setSidePanelContents(panelTransactions);
        break;
      default:
        setSidePanelContents(undefined);
    }
  };
  const showGenerateBookingButton = services?.length !== 0 || isStudio;

  useEffect(() => {
    if (!loggedInUser) return;
    if (!isEngineerOrStudioManager) return;

    setIsLoadingSubscriptionStatus(true);
    void dispatch(getSubscriptionStatus({ refetch_subs_status: true })).finally(
      () => {
        setIsLoadingSubscriptionStatus(false);
      },
    );
  }, [dispatch, query, loggedInUser, isEngineerOrStudioManager]);

  useEffect(() => {
    if (!isEngineer) return;
    if (!loggedInUser) return;
    const engineer = loggedInUser.engineer;
    if (!engineer) return;
    void dispatch(loadEngineerServices({ engineer_id: engineer.id }));
  }, [dispatch, isEngineer, loggedInUser]);

  useEffect(() => {
    if (!loggedInUser) return;
    void dispatch(fetchPendingProjectCounts());
  }, [loggedInUser, dispatch]);

  useEffect(() => {
    if (!loggedInUser) {
      dispatch(loadUser())
        .unwrap()
        .then((res) => {
          if (!res) {
            history.push(SCREENS.SIGNED_OUT_HOME_SCREEN);
          }
        })
        .catch(() => {
          history.push(SCREENS.SIGNED_OUT_HOME_SCREEN);
        });
    } else {
      if (!isEngineerOrStudioManager) return;
      if (isEngineer && !showCashOutModal) {
        void dispatch(loadProjectStats({}));
      }
      void dispatch(
        refreshStripeCredentials(
          isStudioSeparateStripeToggled
            ? { studio_id: selectedProfile?.studio?.id }
            : {},
        ),
      )
        .then(unwrapResult)
        .then(() => {
          setStripeCredentialsRefreshed(true);
        });
      void dispatch(
        getCompleteTodayProjects({
          studio_id: selectedProfile.studio
            ? selectedProfile.studio.id
            : undefined,
        }),
      );
      void dispatch(getProjectRequests({}));
    }
  }, [dispatch, loggedInUser, isEngineerOrStudioManager, selectedProfile]);

  useEffect(() => {
    if (!isEngineerOrStudioManager) return;
    if (!showCashOutModal) {
      void dispatch(
        getStripeBalance(
          isStudioSeparateStripeToggled
            ? { studio_id: selectedProfile?.studio?.id }
            : {},
        ),
      )
        .unwrap()
        .then((result) => {
          setTotalStripeBalance((result.available + result.pending) / 100);
          setAmountToWithdraw(result.available / 100);
          setCurrencyToWithdraw(result.currency);
          setPossibleFeeRefundAmount(result.fee_refund / 100);
        });
      void dispatch(
        getCompleteTodayProjects({
          studio_id: selectedProfile.studio
            ? selectedProfile.studio.id
            : undefined,
        }),
      );
    }
  }, [showCashOutModal, selectedProfile, isEngineerOrStudioManager]);

  useEffect(() => {
    if (startDate > endDate) {
      setEndDate(addDays(startDate, 1));
    } else if (endDate < startDate) {
      setStartDate(addDays(endDate, -1));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate(new Date(minStartDate));
    setEndDate(new Date());
  }, [selectedProfile]);

  useEffect(() => {
    // Clear side panel on unmount
    return () => {
      setSidePanelContents(undefined);
    };
  }, [setSidePanelContents]);

  const stickyFooterButton = useBottomTabBarOverlayView(
    showGenerateBookingButton && isMobile,
    <div className="dashboard-start-a-project-button-fixed">
      {generateButton()}
    </div>,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  // This handles the situation where a user who is neither an engineer nor a studio manager attempts to access the dashboard. It ensures appropriate access control and user redirection.
  if (isAuthenticated && !isEngineerOrStudioManager) {
    if (isUserListenerOnly(loggedInUser!)) {
      return <Redirect to={SCREENS.MY_LIBRARY} />;
    }
    return <Redirect to={SCREENS.PROJECTS.replace(":tab", "today")} />;
  }

  // Wait for these to be reloaded, so we don't redirect to the wrong page
  if (
    !loggedInUser ||
    !stripeCredentialsRefreshed ||
    isLoadingSubscriptionStatus
  ) {
    return <LoadingScreen />;
  }

  // Handles case where an onboarded engineer or studio manager does not have a subscription plan.
  // Redirect them to the add subscription page.
  // if (
  //   !shouldPreventRedirect &&
  //   canHostServices &&
  //   subscription.subscription_plan_choice ===
  //     SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN
  // ) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: SCREENS.ADD_SUBSCRIPTION,
  //         state: { isOnboarding: true },
  //       }}
  //     />
  //   );
  // }
  // This block of code manages situations where a verified engineer does not offer any services.
  // In such scenarios, the engineer is redirected to their profile where they can set up a new service.
  // It's important to note that recording service is checked separately, due to its independent status out of the common services array.

  const leftTopElements = (
    <PerformanceChart
      onClick={() =>
        toggleSidePanel(ModularDashboardDisplayOptions.PERFORMANCE)
      }
      onClickViewTransactions={() =>
        toggleSidePanel(ModularDashboardDisplayOptions.TRANSACTIONS)
      }
      onSidePanel={false}
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      isStudio={isStudio}
    />
  );

  const rightTopElements = (
    <>
      <CashOut
        totalStripeBalance={totalStripeBalance}
        availableStripeBalance={amountToWithdraw}
        currencyToWithdraw={currencyToWithdraw}
        meetsStripeRequirements={meetsStripeRequirements}
        onClickCashOut={() => {
          setShowCashOutModal(true);
        }}
        onClickExtend={() =>
          toggleSidePanel(ModularDashboardDisplayOptions.CASHOUT)
        }
        onClickViewTransactions={() =>
          toggleSidePanel(ModularDashboardDisplayOptions.TRANSACTIONS)
        }
        cashingOut={cashingOut}
        isLoading={isAccountInfoLoading}
      />
      {isStudio ? (
        <div className="dashboard-upcoming-sessions">
          {!isMobile && (
            <DashboardNumberCard
              isLoadingData={loadingPendingRecordingSessionBookings}
              titleToShow="Pending Requests"
              buttonText="View Requests"
              numberToDisplay={
                selectedProfile.studio !== undefined
                  ? pendingRecordingSessionBookings.length
                  : 0
              }
              numberColor={DashboardNumberColor.PENDING}
              onClick={() => redirectToProjectsPage(ProjectTableTab.PENDING)}
            />
          )}
          <CalendarWeekView />
        </div>
      ) : (
        <div className="dashboard-upcoming-projects">
          <DashboardNumberCard
            isLoadingData={isLoadingProjectRequests}
            titleToShow="Pending Requests"
            buttonText="View Requests"
            numberToDisplay={numProjectRequests}
            numberColor={DashboardNumberColor.PENDING}
            onClick={() => redirectToProjectsPage(ProjectTableTab.PENDING)}
          />
          <DashboardNumberCard
            isLoadingData={loadingProjectStats}
            titleToShow="Active Projects"
            numberToDisplay={projectStats?.num_active_projects ?? 0}
            buttonText="View Projects"
            onClick={() => {
              redirectToProjectsPage(ProjectTableTab.ACTIVE);
            }}
          />
          <DashboardNumberCard
            isLoadingData={isLoadingCompleteToday}
            titleToShow="Upcoming Deadlines"
            numberToDisplay={numUpcomingDeadlines}
            buttonText="View All"
            onClick={() => {
              redirectToProjectsPage(ProjectTableTab.TODAY);
            }}
          />
          <DashboardNumberCard
            isLoadingData={loadingProjectStats}
            titleToShow="Completed Projects"
            // TODO: Handle up to four figures.
            numberToDisplay={projectStats?.num_completed_projects || 0}
            buttonText="View All"
            numberColor={DashboardNumberColor.COMPLETED}
            onClick={() => {
              redirectToProjectsPage(ProjectTableTab.ACTIVE, true);
            }}
          />
        </div>
      )}
      <DashboardCard
        cardHeight={330}
        className="dashboard-cards-mobile-container no-overflow-content h-full"
        headerText={activityHeaderText}
        handleHeaderClick={() =>
          toggleSidePanel(ModularDashboardDisplayOptions.ACTIVITY)
        }
      >
        <PlatformActivityTable
          onSidePanel={false}
          teamId={
            associatedTeams.find(
              (t) => t.studio_id === selectedProfile?.studio?.id,
            )?.id
          }
        />
      </DashboardCard>
    </>
  );

  const leftBottomElements = (
    <>
      <TrophyRoomCard
        userId={selectedProfile.studio ? undefined : selectedProfile.user?.id}
        studioId={
          selectedProfile.studio ? selectedProfile.studio.id : undefined
        }
      />
      {isStudio && <ModularDashboardStudioTeamCard />}
    </>
  );

  return (
    <>
      <div
        className={`dashboard-container container ${sidePanelData.isOpen ? "no-scroll" : ""}`}
      >
        <ModularDashboardHeader />
        {isSingleColumn && (
          <div className="dashboard-single-column-container">
            {leftTopElements}
            {rightTopElements}
            {leftBottomElements}
            <CampaignCard />
          </div>
        )}
        {!isSingleColumn && (
          <div className="dashboard-columns-container">
            <div className="dashboard-left-column">
              {leftTopElements}
              {leftBottomElements}
            </div>
            <div className="dashboard-right-column">
              {rightTopElements}
              <CampaignCard />
            </div>
          </div>
        )}
        <div className="dashboard-reviews-list">
          <Card
            hideOverflow={true}
            customClassName="dashboard-cards-mobile-container"
          >
            <ReviewsList
              engineer={
                selectedProfile.studio ? undefined : loggedInUser.engineer
              }
              studio={
                selectedProfile.studio ? selectedProfile.studio : undefined
              }
            />
          </Card>
        </div>
        <CashOutModal
          setCashingOut={setCashingOut}
          cashingOut={cashingOut}
          amountToWithdraw={amountToWithdraw}
          currencyToWithdraw={currencyToWithdraw}
          showModal={showCashOutModal}
          setShowModal={setShowCashOutModal}
          possibleFeeRefundAmount={possibleFeeRefundAmount}
        />
      </div>
      <ModularDashboardSidePanel />
      {stickyFooterButton}
    </>
  );
};
