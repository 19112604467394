import { SUPPLY_CHAIN_LABELS } from "../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { EngineEarsError } from "../../store/actions/errorStore";
import { SupplyChainCountry } from "./supplyChainModels";

export interface SupplyChainLabelModel {
  id: number;
  name: string;
  email: string;
  logo: string;
  parent_id: number;
  country: SupplyChainCountry;
}

interface SupplyChainLabelsResponse {
  data: SupplyChainLabelModel[];
  limit: number;
  offset: number;
  total: number;
}

export const getSupplyChainLabels =
  async (): Promise<SupplyChainLabelsResponse> => {
    return makeBackendGetCallWithJsonResponse<SupplyChainLabelsResponse>(
      SUPPLY_CHAIN_LABELS,
      "",
    ).then((res) => {
      if (!res.success) {
        throw res.resultJson as EngineEarsError;
      }
      return res.resultJson;
    });
  };
