import { useDebounce } from "ahooks";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { searchTermAtom } from "../../atoms/searchQueryAtom";
import { AutocompleteSuggestion } from "../../store/models/autocomplete";
import useAutocompleteSuggestions from "../autocompleteHooks/useAutocompleteSuggestions";

export interface UseSearchParams {
  enabled?: boolean;
  term?: string;
  mapView?: boolean;
  debounceInMs?: number;
  mapDataOnly?: boolean;
}

const useSearch = ({
  enabled = true,
  mapView = false,
  debounceInMs = 600,
}: UseSearchParams) => {
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom);
  const debouncedSearchTerm = useDebounce(searchTerm, { wait: debounceInMs });
  const { isFetching: fetchingAutocomplete, data: dataAutocomplete } =
    useAutocompleteSuggestions({
      term: debouncedSearchTerm,
      enabled,
      mapView,
    });

  // This additional query is to get the map data that will be concatenated to the autocomplete result
  const {
    isFetching: fetchingMapOnlyAutocomplete,
    data: mapOnlyAutocompleteData,
  } = useAutocompleteSuggestions({
    term: debouncedSearchTerm,
    enabled: enabled && !mapView,
    mapView: true,
    mapDataOnly: true,
  });

  const autocompleteSuggestions = useMemo(() => {
    // These are only applied to non map views
    let itemsToBeConcatenated: AutocompleteSuggestion[] = [];
    if (!mapView) {
      itemsToBeConcatenated = mapOnlyAutocompleteData || [];
    }
    return [...(dataAutocomplete || []), ...itemsToBeConcatenated];
  }, [dataAutocomplete, mapOnlyAutocompleteData, mapView]);

  const isFetchingAutocomplete = useMemo(() => {
    return fetchingAutocomplete;
  }, [fetchingAutocomplete]);

  // This is to account for the case when the list query has been resolved,
  // but the map only data query has not yet been resolved. We still want to show the loading indicator in this case
  const isFetchingMapOnlyDataOnEmptyList =
    fetchingMapOnlyAutocomplete && autocompleteSuggestions.length === 0;

  return useMemo(
    () => ({
      autocompleteSuggestions,
      isFetchingAutocomplete,
      debouncedSearchTerm,
      searchTerm: searchTerm || "",
      setSearchTerm,
      isFetchingMapOnlyDataOnEmptyList,
    }),
    [
      autocompleteSuggestions,
      isFetchingAutocomplete,
      debouncedSearchTerm,
      searchTerm,
      setSearchTerm,
      isFetchingMapOnlyDataOnEmptyList,
    ],
  );
};

export default useSearch;
