import queryString from "query-string";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { useAtomValue } from "jotai";
import { connectionEstablishedAtom } from "../../../hooks/chatHooks/atoms";
import { SCREENS } from "../../../routes/screens";
import { createMusoAuthToken } from "../../../store/actions/musoProfile";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button } from "../../core-ui/components/Button/Button";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";

const MusoAuthRedirectScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const connectionEstablished = useAtomValue(connectionEstablishedAtom);

  const { code: musoAuthenticationCode, redirect_path: redirectPath } =
    queryString.parse(location.search);
  const isValidMusoAuthCode =
    musoAuthenticationCode && typeof musoAuthenticationCode === "string";

  const returnToPreviousScreen = () => {
    history.replace((redirectPath as string) ?? SCREENS.DASHBOARD);
  };

  useEffect(() => {
    if (isValidMusoAuthCode && connectionEstablished) {
      setIsLoading(true);
      dispatch(
        createMusoAuthToken({
          authentication_code: musoAuthenticationCode,
        }),
      )
        .unwrap()
        .then(returnToPreviousScreen)
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [musoAuthenticationCode, connectionEstablished]);

  if (!isValidMusoAuthCode || !loggedInUser) {
    return <Redirect to={SCREENS.SIGNED_OUT_HOME_SCREEN} />;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Text
        size={TEXT_SIZE.LARGE}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ margin: "32px 0" }}
        color={hasError ? TEXT_COLOR.ERROR : TEXT_COLOR.PRIMARY}
      >
        {hasError
          ? "An error occurred connecting your account to Muso.AI"
          : "Connecting your account to Muso.AI..."}
      </Text>
      {hasError && <Button onClick={returnToPreviousScreen}>Return</Button>}
      {isLoading && <SoundWaveLoader width={100} height={100} />}
    </div>
  );
};

export default MusoAuthRedirectScreen;
