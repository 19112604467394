import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import supplyChainSocketMessagesAtom from "../../../atoms/supplychain/supplyChainSocketMessagesAtom";
import { SupplyChainWebsocketMessage } from "./SupplyChainWebsocketMessage";

export const SupplyChainWebsocketMessages = () => {
  const messages = useAtomValue(supplyChainSocketMessagesAtom);

  return (
    <Box sx={{ padding: "8px", width: "600px" }}>
      <Box>Websocket messages</Box>
      {messages.map((message) => (
        <SupplyChainWebsocketMessage key={Math.random()} message={message} />
      ))}
    </Box>
  );
};
