import { DialogContent, DialogContentProps } from "@mui/material";
import classNames from "classnames";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";

type ResponsiveDialogContentProps = Partial<DialogContentProps>;

export const ResponsiveDialogContent = (
  contentProps: ResponsiveDialogContentProps,
) => {
  const {
    children: contentChildren,
    className: contentClassName,
    ...remainingContentProps
  } = contentProps;
  return (
    <DialogContent
      className={classNames(
        TrackableClassNames.ENGINEEARS_DIALOG_CONTENT,
        contentClassName,
      )}
      {...remainingContentProps}
    >
      {contentChildren}
    </DialogContent>
  );
};
