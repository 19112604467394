import { AxiosInstance } from "axios";

export enum SupplyChainRecordTypes {
  Album = "album",
  Video = "video",
  Track = "track",
  Ringtone = "ringtone",
}

export enum SupplyChainFileTypes {
  Cover = "cover",
  DigitalBooklet = "digitalBooklet",
  Audio = "audio",
  Dolby = "dolby",
  Video = "video",
  AppleMotionDesktop = "appleMotionDesktop",
  AppleMotionMobile = "appleMotionMobile",
}

interface SCFileUploadStartParams {
  fileId: string;
  fileSize: number;
  fileType: string;
  // Supply Chain Album, track, etc ID
  relatedId: number;
  relatedType: string;
  createThumbnail?: boolean;
  type?: string;
}

interface SupplyChainUploadAdditionalParams {
  type?: "dolby" | "motion_desktop" | "motion_mobile";
}

interface SCFileUploadStartResponse {
  chunksCount: number;
  filename: string;
  chunkSizeInMb: number;
}

const supplyChainUploadStart = async (
  payload: SCFileUploadStartParams,
  axiosInstance: AxiosInstance,
): Promise<SCFileUploadStartResponse> => {
  try {
    const resp = await axiosInstance.post("uploads/multipart/start", payload, {
      headers: { "Content-Type": "application/json" },
    });
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

interface SCFileStatusResponse {
  name: string;
  uploadedParts: { etag: string; partNumber: number }[];
}

const supplyChainUploadFileStatus = async (
  filename: string,
  axiosInstance: AxiosInstance,
): Promise<SCFileStatusResponse[]> => {
  try {
    const resp = await axiosInstance.get(`uploads/multipart/pending`, {
      params: { filename },
    });
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

interface SCUploadMultipartChunkResponse {
  data: {
    message: string;
    partNumber: string;
  };
}

const supplyChainUploadMultipartChunk = (
  filename: string,
  fileType: string,
  chunkNumber: number,
  content: Blob,
  axiosInstance: AxiosInstance,
): Promise<SCUploadMultipartChunkResponse> => {
  return axiosInstance.post(
    `uploads/multipart/chunk/${chunkNumber}/${filename}`,
    content,
    {
      headers: {
        "Content-Type": fileType,
      },
    },
  );
};

const supplyChainCancelMultipartUpload = (
  filename: string,
  axiosInstance: AxiosInstance,
) => {
  return axiosInstance.post("uploads/multipart/cancel", {
    filename,
  });
};

export const supplyChainUploadFile = async (
  file: File,
  relatedId: number,
  recordType: SupplyChainRecordTypes,
  supplyChainFileType: SupplyChainFileTypes,
  axiosInstance: AxiosInstance,
  createThumbnail = false,
  relatedType = "product",
  additionalParams: SupplyChainUploadAdditionalParams = {},
) => {
  const fileSize = file.size;
  const fileType = file.type;

  // Supply chain specific file type required for fileId generation.
  // File type used everywhere else.
  const payload: SCFileUploadStartParams = {
    fileId: `${recordType}-${supplyChainFileType}-${Date.now()}`,
    fileSize,
    fileType,
    relatedId,
    relatedType,
    ...additionalParams,
  };

  if (createThumbnail) {
    payload.createThumbnail = createThumbnail;
  }

  const { chunksCount, filename, chunkSizeInMb } = await supplyChainUploadStart(
    payload,
    axiosInstance,
  );

  const fileStatus = await supplyChainUploadFileStatus(filename, axiosInstance);
  const lastUploadedChunk =
    fileStatus[0]?.uploadedParts?.at(-1)?.partNumber || 0;
  const chunkSize = chunkSizeInMb * 1024 * 1024;

  // SupplyChain file upload API can not handle concurrent uploads.
  try {
    for (let i = lastUploadedChunk; i < chunksCount; i++) {
      const start = i * chunkSize;
      const end = (i + 1) * chunkSize;
      const blob =
        i <= chunksCount - 1 ? file.slice(start, end) : file.slice(start);
      await supplyChainUploadMultipartChunk(
        filename,
        fileType,
        i + 1,
        blob,
        axiosInstance,
      );
    }
  } catch (e) {
    void supplyChainCancelMultipartUpload(filename, axiosInstance);
    console.error(e);
  }
};
