import { SUPPLY_CHAIN_ALBUMS } from "../../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import {
  SupplyChainProductModel,
  SupplyChainPerson,
} from "./supplyChainModels";

export interface PostSupplyChainAlbumParams {
  id?: number;
  title: string;
  artistName: string;
  releaseAt: string;
  labelId: number;
  languageId: number;
  genreId: number;
  parentalAdvisory: number;
  // TODO: Add support for syndication actions.
  // Defaults to only insert.
  syndicationAction?: number;
}

interface PostSupplyChainBody {
  id?: number;
  title: string;
  persons: SupplyChainPerson[];
  release_at: string;
  label_id: number;
  language_id: number;
  genre_id: number;
  parental_advisory: number;
  syndication_action: number;
  product_type_id: number;
}

export const postSupplyChainAlbum = async (
  params: PostSupplyChainAlbumParams,
): Promise<SupplyChainProductModel> => {
  const body: PostSupplyChainBody = {
    title: params.title,
    release_at: params.releaseAt,
    label_id: params.labelId,
    genre_id: params.genreId,
    language_id: params.languageId,
    parental_advisory: params.parentalAdvisory,
    // TODO: Update syndication_action, person type and is_featured, and product_type_id to not be hard coded.
    syndication_action: params?.syndicationAction || 1,
    persons: [{ name: params.artistName, type: 1, is_featured: 0 }],
    product_type_id: 1,
  };
  if (params.id) {
    body.id = params.id;
  }

  const response =
    await makeBackendPostCallWithJsonResponse<SupplyChainProductModel>(
      SUPPLY_CHAIN_ALBUMS,
      body,
    );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};
