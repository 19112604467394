import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays, subDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { Calendar, ViewCallback } from "react-calendar";
import { useSessionsDateMap } from "../../../hooks/recordingSessionHooks/useSessionsDateMap";
import { loadScheduleSessions } from "../../../store/actions/scheduledSessions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CalendarDayCard } from "./CalendarDayCard/CalendarDayCard";
import "./CalendarDayGrid.css";
import { getMonthNameFromDate } from "../../../store/utils/dateTimeUtils";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  CalendarWeekViewContainer,
  CalendarWeekViewHeader,
  CalendarWeekViewRow,
} from "./CalandarDayGrid.styles";
import { useAtomValue } from "jotai";
import { activeProfileAtom } from "../../../atoms/user/activeProfileAtom";
import { ProfileType } from "../../../store/models/base";
import { applySessionStageFilter } from "../../../store/models/recordingSession";

const START_OF_MONTH_DAY_OFFSET = 5;
const END_OF_MONTH_DAY_OFFSET = 31;

export const CalendarMonthGrid = () => {
  const { stages } = useAppSelector(
    (state) => state.paginatedRecordingSessions,
  );
  const activeProfile = useAtomValue(activeProfileAtom);
  const userId = useAppSelector((state) => state.accountInfo.user?.id);
  const dispatch = useAppDispatch();
  const [value, onChange] = useState<Date>(new Date());
  const { startDate, endDate } = useMemo(() => {
    const startOfMonth = new Date(value);
    startOfMonth.setDate(1);
    return {
      startDate: subDays(startOfMonth, START_OF_MONTH_DAY_OFFSET),
      endDate: addDays(startOfMonth, END_OF_MONTH_DAY_OFFSET),
    };
  }, [value]);

  const paginate = (date: Date) => {
    if (!userId) return;
    const startOfMonth = new Date(date);
    startOfMonth.setDate(1);
    void dispatch(
      loadScheduleSessions({
        studioId:
          activeProfile?.type === ProfileType.STUDIO
            ? activeProfile?.id
            : undefined,
        userId: userId,
        startDate: subDays(startOfMonth, START_OF_MONTH_DAY_OFFSET),
        endDate: addDays(startOfMonth, END_OF_MONTH_DAY_OFFSET),
      }),
    );
  };

  const recordingSessions = useSessionsDateMap({ startDate, endDate });

  const handleDateChange: ViewCallback = ({ activeStartDate }) => {
    const activeStartDateConvertedToLocale = new Date(activeStartDate);
    paginate(activeStartDateConvertedToLocale);
    onChange(activeStartDateConvertedToLocale);
  };

  return (
    <Calendar
      onActiveStartDateChange={handleDateChange}
      maxDetail={"month"}
      minDetail={"month"}
      prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
      nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
      className={"session-calendar"}
      view="month"
      showDoubleView={false}
      tileContent={({ date }) => {
        const isoDateString = date.toISOString();
        const sessionsForDate = recordingSessions.get(isoDateString) ?? [];
        const sessionsForDateFiltered = applySessionStageFilter(
          stages,
          sessionsForDate,
        );
        return (
          <CalendarDayCard
            dateNumber={date.getDate()}
            recordingSessions={sessionsForDateFiltered}
          />
        );
      }}
    />
  );
};

export const CalendarWeekView = () => {
  const { stages } = useAppSelector(
    (state) => state.paginatedRecordingSessions,
  );
  const activeProfile = useAtomValue(activeProfileAtom);
  const userId = useAppSelector((state) => state.accountInfo.user?.id);
  const dispatch = useAppDispatch();

  const startDate = useMemo(() => {
    const startDate = new Date();
    // if we set it entirely to 0, we get back the end of yesterday
    startDate.setHours(0, 0, 0, 1);
    return startDate;
  }, []);

  const endDate = useMemo(() => {
    return addDays(startDate, 7);
  }, [startDate]);

  const recordingSessions = useSessionsDateMap({ startDate, endDate });

  useEffect(() => {
    if (!userId) return;
    void dispatch(
      loadScheduleSessions({
        studioId:
          activeProfile?.type === ProfileType.STUDIO
            ? activeProfile?.id
            : undefined,
        userId: userId,
        startDate: startDate,
        endDate: endDate,
      }),
    );
  }, [dispatch, startDate, endDate, userId, activeProfile]);

  return (
    <CalendarWeekViewContainer>
      <CalendarWeekViewHeader>
        <Text weight={TEXT_WEIGHT.BOLD}>{getMonthNameFromDate(startDate)}</Text>
        <Text>{startDate.getFullYear()}</Text>
      </CalendarWeekViewHeader>
      <CalendarWeekViewRow>
        {Array.from({ length: 7 }).map((_, index) => {
          // here we want 0 because the map keys are annotated with 0 for all fields
          // we also want to init a new date to prevent mutation bugs
          let currentDate = new Date(startDate);
          currentDate.setHours(0, 0, 0, 0);
          currentDate = addDays(currentDate, index);
          const isoDateString = currentDate.toISOString();
          const sessionsForDate = recordingSessions.get(isoDateString) ?? [];
          const sessionsForDateFiltered = applySessionStageFilter(
            stages,
            sessionsForDate,
          );
          return (
            <CalendarDayCard
              key={index}
              displayMobileView={true}
              dateNumber={currentDate.getDate()}
              recordingSessions={sessionsForDateFiltered}
            />
          );
        })}
      </CalendarWeekViewRow>
    </CalendarWeekViewContainer>
  );
};
