import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { displayEngineEarsError } from "../../api/helpers";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  postSupplyChainLabel,
  PostSupplyChainLabelParams,
} from "../../api/supplyChain/postSupplyChainLabel";
import { LabelsData } from "./useGetSupplyChainLabelsQuery";

const useSupplyChainLabelMutation = (labelId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_SUPPLY_CHAIN_LABEL, { labelId }],
    mutationFn: async (params: PostSupplyChainLabelParams) => {
      // TODO: Add analytics
      return postSupplyChainLabel(params);
    },
    onSuccess: (result) => {
      const labels = queryClient.getQueryData([
        QUERY_KEYS.GET_SUPPLY_CHAIN_LABELS,
      ]) as LabelsData | undefined;

      queryClient.setQueryData([QUERY_KEYS.GET_SUPPLY_CHAIN_LABELS], {
        ...(labels || {}),
        [result.id]: result,
      });
      return result;
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};

export default useSupplyChainLabelMutation;
