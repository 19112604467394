import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";

const SUPPLY_CHAIN_FILE_URL = "https://file.sb.supplychainmusic.com/";

const SupplyChainFileAxios = () => {
  const SCTOKEN = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPPLY_CHAIN_TOKEN);
  const parsedToken = JSON.parse(SCTOKEN || "");
  return axios.create({
    baseURL: SUPPLY_CHAIN_FILE_URL,
    headers: { Authorization: `Bearer ${parsedToken}` },
  });
};

export default SupplyChainFileAxios;
