export enum TRACKING_EVENTS_NAME {
  USER_CLICK_FAVORITED_ACCOUNT = "user_click_favorited_account",
  USER_CLICK_SAVE_PROFILE = "user_click_save_profile",
  USER_OPEN_DIRECT_MESSAGE = "user_open_direct_message",
  USER_CLICK_PROFILE_CARD = "user_click_profile_card",
  USER_UPDATE_COVER_PHOTO = "user_update_cover_photo",
  USER_CLICK_PRODUCT_CHECKOUT = "user_click_product_checkout",
  USER_CLICK_PLAY_PROJECT = "user_click_play_project",
  USER_CLICK_SHARE_RELEASE = "user_click_share_release",
  USER_CLICK_OPEN_ACCOUNT_SETTINGS = "user_click_open_account_settings",
  USER_CLICK_SOCIAL_MEDIA_LINK = "user_click_social_media_link",
  USER_CLICK_EDIT_SOCIAL_MEDIA_LINKS = "user_click_edit_social_media_links",
  USER_CLICK_GENERATE_BOOKING = "user_click_generate_booking",
  USER_CLICK_BOOK_NOW = "user_click_book_now_click",
  USER_GENERATING_TRANSACTION = "user_generating_transaction",
  USER_CLICK_ADD_OR_EDIT_SERVICE = "user_click_add_or_edit_service",
  USER_CLICK_PAYWALL_OPTION = "user_click_paywall_option",
  USER_CLICK_SAVE_ASSET = "user_click_save_asset",
  USER_CLICK_THUMBNAIL_ASSET = "user_click_thumbnail_asset",
  USER_CLICK_CANCEL_ASSET = "user_click_cancel_asset",
  SYSTEM_SUBMIT_FORM = "system_submit_form",
  USER_CLOSE_DIALOG = "user_close_dialog",
  USER_VISIT_PROFILE = "user_visit_profile",
}
