import { SUPPLY_CHAIN_ALBUMS } from "../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { EngineEarsError } from "../../store/actions/errorStore";
import { SupplyChainProductModel } from "./supplyChainModels";

interface SupplyChainAlbumsResponse {
  data: SupplyChainProductModel[];
  limit: number;
  offset: number;
  total: number;
}

export const getSupplyChainAlbums =
  async (): Promise<SupplyChainAlbumsResponse> => {
    return makeBackendGetCallWithJsonResponse<SupplyChainAlbumsResponse>(
      SUPPLY_CHAIN_ALBUMS,
      "",
    ).then((res) => {
      if (!res.success) {
        throw res.resultJson as EngineEarsError;
      }
      return res.resultJson;
    });
  };
