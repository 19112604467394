import { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { QueryObserverResult } from "@tanstack/react-query";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { SCREENS } from "../../../routes/screens";
import { PaginatedAdminActionsResponse } from "../../../store/actions/adminDashboardStore";
import { createScheduledProjectShareLink } from "../../../store/actions/scheduledprojects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  Project,
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import { AdminActionPurchaseOrder } from "../../../store/models/PurchaseOrderWithTransactions";
import { PaginatedScheduledProject } from "../../../store/models/scheduledproject";
import { imagePathPrefix } from "../../../store/utils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { getProjectOverviewRoute } from "../../../store/utils/routeGetters";
import defaultUser from "../../../stories/assets/defaultuser.jpg";
import { AdminAssignProjectModal } from "../../components/AdminAssignProjectModal/AdminAssignProjectModal";
import { hasUserAcceptedProject } from "../../components/ScheduledProjectTable/utils";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import "./ActionItemRow.css";
import { AdminActionButton } from "./components/AdminActionButton";
import { ActionToPerform } from "./utils";

interface ActionItemRowProps {
  purchaseOrderData: AdminActionPurchaseOrder;
  completedOverride?: boolean;
  handleOpenBookingRequestedPanel: (_: PaginatedScheduledProject) => void;
  refetchAdminActionItems:
    | (() => Promise<
        QueryObserverResult<PaginatedAdminActionsResponse, unknown>
      >)
    | null;
  scheduledProject?: PaginatedScheduledProject;
}
export interface ProjectTypeNumbers {
  count: number;
  subTotal: number;
  firstProject: Project;
}

export const ActionItemRow = ({
  purchaseOrderData,
  completedOverride,
  handleOpenBookingRequestedPanel,
  refetchAdminActionItems,
  scheduledProject,
}: ActionItemRowProps) => {
  const [isProjectLinkLoading, setIsProjectLinkLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const {
    isOpen: isAssignProjectModalOpen,
    setIsOpen: toggleAssignProjectModal,
  } = useModal();

  // The original rate per song - used to ensure engineer rates do not exceed this amount
  const originalBookingPrice = useMemo(() => {
    const originalTotalPrice = purchaseOrderData.transaction.total_price || 0;
    const numberOfSongs =
      Object.values(purchaseOrderData.project_type_counts)?.[0] || 1;
    return originalTotalPrice / numberOfSongs;
  }, [purchaseOrderData]);

  const totalPrice = useMemo(() => {
    return (
      purchaseOrderData.transaction?.total_price ||
      0 + purchaseOrderData.transaction?.fees_collected ||
      0
    );
  }, [purchaseOrderData]);

  const renderTotalPrice = useMemo(() => {
    if (totalPrice === 0) return null;
    const total = PennyDollarFormatter().format(totalPrice);
    return (
      <p style={{ textAlign: "center" }} className="b2-semi-bold">
        {total}
      </p>
    );
  }, [totalPrice]);

  const user = useAppSelector((state) => state.accountInfo.user);
  const history = useHistory();

  const { budgetManager, completed } = useMemo(() => {
    const budgetManager = purchaseOrderData.budget_managers.find(
      (budgetManager) => budgetManager.email === user?.email,
    );
    let completed =
      Boolean(budgetManager?.budget_approved) ||
      Boolean(budgetManager?.budget_rejected);

    if (completedOverride !== undefined) {
      completed = completedOverride;
    }

    return { budgetManager, completed };
  }, [purchaseOrderData.budget_managers, completedOverride]);

  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const actionToPerform = useMemo(() => {
    if (budgetManager?.can_submit_billing_info) {
      return ActionToPerform.SUBMIT_BILLING_INFO;
    }

    if (budgetManager?.can_approve_budget) {
      return ActionToPerform.APPROVE_BUDGET;
    }

    if (scheduledProject) {
      const projectIsAccepted = hasUserAcceptedProject(
        scheduledProject.project_acceptances,
        currentUser,
      );

      return projectIsAccepted
        ? ActionToPerform.ASSIGN_PROJECT
        : ActionToPerform.VIEW_REQUEST;
    }

    return ActionToPerform.NO_ACTION;
  }, [budgetManager, scheduledProject, currentUser]);

  const onClickProjectDetails = async () => {
    if (!purchaseOrderData.scheduled_project) {
      history.push(SCREENS.PROJECTS_NO_TAB);
      return;
    }

    setIsProjectLinkLoading(true);
    try {
      const response = await dispatch(
        createScheduledProjectShareLink({
          scheduled_project_id:
            purchaseOrderData.scheduled_project.id.toString(),
        }),
      ).unwrap();
      history.push(
        getProjectOverviewRoute(
          purchaseOrderData.scheduled_project.id,
          response[0].code,
        ),
      );
    } catch {
      // redirect to /projects on a failed API call
      history.push(SCREENS.PROJECTS_NO_TAB);
    } finally {
      setIsProjectLinkLoading(false);
    }
  };

  const imageContainer = () => {
    return purchaseOrderData.users?.map((user, key) => {
      const imgUrl = !user.photo
        ? defaultUser
        : imagePathPrefix + user.photo?.path;
      return (
        <Link
          to={SCREENS.PROFILE_SCREEN.replace(":username", user.username)}
          key={key}
          className="action-item-image-container"
        >
          <img
            className="action-item-image"
            key={key}
            height={21}
            width={21}
            src={imgUrl}
            alt="profile"
          />
          <p>{getDisplayableNameForUser(user)}</p>
        </Link>
      );
    });
  };

  const budgetStatus = () => {
    if (budgetManager?.budget_approved) {
      return <p className="b1-semi-bold">Budget Approved</p>;
    }
    if (budgetManager?.budget_rejected) {
      return <p className="b1-semi-bold">Budget Rejected</p>;
    }
    return null;
  };

  return (
    <div className="action-item-table-element-container">
      {isDesktop && (
        <div className="action-item-row-container">
          {budgetManager && (
            <input
              className="action-item-checkbox"
              type="checkbox"
              name={"Main"}
              disabled={true}
              checked={Boolean(completed)}
            />
          )}
          <div className="action-item-column-container">
            <p style={{ textAlign: "center" }} className="b2-semi-bold">
              {actionToPerform}
            </p>
            <p className="b2">{purchaseOrderData.scheduled_project_title}</p>
          </div>
        </div>
      )}
      {isDesktop && (
        <div className="action-item-column-container">{imageContainer()}</div>
      )}
      <div className="action-item-column-container">
        {renderTotalPrice}
        {Object.entries(purchaseOrderData.project_type_counts).map(
          ([serviceType, count]) => (
            <p
              style={{ textAlign: "center" }}
              key={serviceType}
              className="b3"
            >{`${count} Songs • ${projectTypeReadableName.get(+serviceType as ProjectType)}`}</p>
          ),
        )}
      </div>
      <div className="action-item-column-container">
        {isProjectLinkLoading ? (
          <SoundWaveLoader width={100} height={100} />
        ) : (
          <p
            style={{
              textDecoration: "underline",
              textTransform: "uppercase",
              textAlign: "center",
              cursor: "pointer",
            }}
            className="b3"
            onClick={onClickProjectDetails}
          >
            Project Details
          </p>
        )}
      </div>
      <div className="action-item-column-container">
        {completed ? (
          budgetStatus()
        ) : (
          <AdminActionButton
            actionToPerform={actionToPerform}
            budgetManager={budgetManager}
            handleOpenBookingRequestedPanel={
              scheduledProject?.id
                ? () => handleOpenBookingRequestedPanel(scheduledProject)
                : () => {}
            }
            toggleAssignProjectModal={toggleAssignProjectModal}
            transactionId={purchaseOrderData.transaction.id}
          />
        )}
      </div>
      <AdminAssignProjectModal
        setOpen={toggleAssignProjectModal}
        open={isAssignProjectModalOpen}
        originalBookingPrice={originalBookingPrice}
        refetchAdminActionItems={refetchAdminActionItems}
        scheduledProjectId={scheduledProject?.id || null}
      />
    </div>
  );
};
