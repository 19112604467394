import { useMemo } from "react";
import { projectTypeReadableName } from "../../../../../store/models/project";
import {
  useIsInProgressProject,
  usePaymentPlanStatus,
} from "../../../../../hooks/partialPaymentHooks";
import {
  getUserIsArtistOnScheduledProject,
  getUserIsEngineerOnScheduledProject,
  isUserCollaboratorOnProject,
} from "../../../../../utils/projectUtils";
import useProjectCompletion from "./useProjectCompletion";
import useProjectDuration from "./useProjectDuration";
import { ScheduledProject } from "../../../../../store/models/scheduledproject";
import User from "../../../../../store/models/user";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";

interface useProjectHeaderHooksProps {
  isMobile: boolean;
  isTablet: boolean;
  serviceTypeSet: Set<string>;
  isPartiallyPaid: boolean;
  isProjectCancelled: boolean;
  redirectToPurchaseProject: () => Promise<void>;
  isInProgressProject: boolean;
  userIsEngineerOnScheduledProject: boolean;
  userIsArtistOnScheduledProject: boolean;
  userIsCollaborator: boolean;
  disableDownloadAndSharingActions: boolean;
  numberOfCompletedTracks: number;
  completedTracksPercentage: number;
  estimatedCompletionDate: string;
  projectDuration: string;
}

export const useProjectHeaderHooks = (
  scheduledProject: ScheduledProject,
  currentUser?: User,
  code?: string,
): useProjectHeaderHooksProps => {
  const { isTablet, isMobile } = useMediaQueryBreakpoint();
  const serviceTypeSet = useMemo(() => {
    const returnValue = new Set<string>();
    scheduledProject.projects.forEach((project) => {
      const readableServiceType = projectTypeReadableName.get(
        project.service_type,
      );
      if (readableServiceType) {
        returnValue.add(readableServiceType);
      }
    });
    return returnValue;
  }, [scheduledProject.projects]);

  const isPartiallyPaid = useMemo(
    () => Boolean(scheduledProject.outstanding_balance > 0),
    [scheduledProject.outstanding_balance],
  );

  const isProjectCancelled = Boolean(scheduledProject.refunded);

  const { redirectToPurchaseProject } = usePaymentPlanStatus(
    code,
    scheduledProject.id,
    undefined,
    isPartiallyPaid,
  );
  const { isInProgressProject } = useIsInProgressProject(scheduledProject);

  const userIsEngineerOnScheduledProject = useMemo(
    () => getUserIsEngineerOnScheduledProject(currentUser, scheduledProject),
    [currentUser, scheduledProject],
  );

  const userIsArtistOnScheduledProject = useMemo(
    () => getUserIsArtistOnScheduledProject(currentUser, scheduledProject),
    [currentUser, scheduledProject],
  );

  const userIsCollaborator = useMemo(
    () => isUserCollaboratorOnProject(currentUser, scheduledProject?.users),
    [currentUser, scheduledProject?.users],
  );

  const disableDownloadAndSharingActions = useMemo(
    () =>
      !userIsEngineerOnScheduledProject &&
      (isPartiallyPaid || isInProgressProject),
    [isPartiallyPaid, isInProgressProject, userIsEngineerOnScheduledProject],
  );

  const numberOfCompletedTracks = useMemo(
    () =>
      scheduledProject.projects.filter((project) => project.completed).length,
    [scheduledProject.projects],
  );

  const { completedTracksPercentage, estimatedCompletionDate } =
    useProjectCompletion(
      numberOfCompletedTracks,
      scheduledProject.projects.length,
      scheduledProject.estimated_delivery_date,
    );

  const projectDuration = useProjectDuration(
    scheduledProject.created,
    scheduledProject.completed,
  );

  return {
    isMobile,
    isTablet,
    serviceTypeSet,
    isPartiallyPaid,
    isProjectCancelled,
    redirectToPurchaseProject,
    isInProgressProject,
    userIsEngineerOnScheduledProject,
    userIsArtistOnScheduledProject,
    userIsCollaborator,
    disableDownloadAndSharingActions,
    numberOfCompletedTracks,
    completedTracksPercentage,
    estimatedCompletionDate,
    projectDuration,
  };
};
