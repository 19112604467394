import { Dialog, styled } from "@mui/material";

export const EngineEarsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.customColor.backgroundColor,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.customColor.foregroundColor,
  },
  "& .MuiDialogActions-root": {
    backgroundColor: theme.palette.customColor.foregroundColor,
  },
}));
