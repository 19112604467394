import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { PostSupplyChainAlbumParams } from "../../../api/supplyChain/postSupplyChainAlbum";
import { Calendar, CalendarTileProperties } from "react-calendar";
import useSupplyChainAlbumMutation from "../../../hooks/supplyChainHooks/useSupplyChainAlbumMutation";
import { Button } from "../../core-ui/components/Button/Button";
import useGetSupplyDashboardOptionsQuery, {
  initialDashboardOptions,
} from "../../../hooks/supplyChainHooks/useGetSupplyDashboardOptionsQuery";
import {
  SupplyChainAudioFileTypes,
  SupplyChainCoverFileTypes,
  SupplyChainProductModel,
} from "../../../api/supplyChain/supplyChainModels";
import useSupplyChainTrackMutation from "../../../hooks/supplyChainHooks/useSupplyChainTrackMutation";
import useGetSupplyChainLabelsQuery from "../../../hooks/supplyChainHooks/useGetSupplyChainLabelsQuery";
import { Uploader } from "../Uploader/Uploader";
import {
  SupplyChainFileTypes,
  SupplyChainRecordTypes,
  supplyChainUploadFile,
} from "../../../api/supplyChain/supplyChainUploadFile";
import { AxiosInstance } from "axios";
import { SupplyChainProductFile } from "./SupplyChainProductFile";

const initialState: PostSupplyChainAlbumParams = {
  id: 0,
  title: "",
  artistName: "",
  releaseAt: "",
  labelId: 0,
  languageId: 0,
  genreId: 0,
  parentalAdvisory: 0,
};

const FieldRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 8,
  width: 600,
  boxSizing: "border-box",
});

interface SupplyChainProductProps {
  product?: SupplyChainProductModel;
  parentId?: number;
  close?: () => void;
  fileAxios: AxiosInstance;
  supplyChainAxios: AxiosInstance;
}

export const SupplyChainProduct = ({
  product,
  parentId,
  close,
  fileAxios,
  supplyChainAxios,
}: SupplyChainProductProps) => {
  const [addTrack, setAddTrack] = useState<boolean>(false);

  const [productForm, setProductForm] = useState<PostSupplyChainAlbumParams>({
    id: product?.id || initialState.id,
    title: product?.title || initialState.title,
    artistName: product?.persons[0].name || initialState.artistName,
    releaseAt: product?.release_at || initialState.releaseAt,
    labelId: product?.label_id || initialState.labelId,
    languageId: product?.language_id || initialState.languageId,
    genreId: product?.genre_id || initialState.genreId,
    parentalAdvisory:
      product?.parental_advisory || initialState.parentalAdvisory,
  });

  const refreshProductFormState = (editedProduct?: SupplyChainProductModel) => {
    if (!product || !editedProduct) {
      setProductForm(initialState);
    } else {
      setProductForm({
        id: editedProduct.id,
        title: editedProduct.title,
        artistName: editedProduct.persons[0].name,
        releaseAt: editedProduct.release_at || "",
        labelId: editedProduct.label_id,
        languageId: editedProduct.language_id,
        genreId: editedProduct.genre_id,
        parentalAdvisory: editedProduct.parental_advisory,
      });
    }
  };

  const { data: dashboardOptions } =
    useGetSupplyDashboardOptionsQuery(supplyChainAxios);

  const {
    genres,
    parental_advisories: parentalAdvisories,
    languages,
  } = dashboardOptions || initialDashboardOptions;

  const { data: labels, isPending: isLabelsPending } =
    useGetSupplyChainLabelsQuery();

  const { mutateAsync: createEditAlbum, isPending: isAlbumPending } =
    useSupplyChainAlbumMutation();

  const { mutateAsync: createEditTrack, isPending: isTrackPending } =
    useSupplyChainTrackMutation(product?.id);

  const handleOnChange = (value: string | number | null, field: string) => {
    setProductForm({ ...productForm, [field]: value });
  };

  const createEditDisabledAlbum = () => {
    if (!product) {
      return (
        !productForm.title ||
        !productForm.artistName ||
        !productForm.releaseAt ||
        !productForm.languageId ||
        !productForm.genreId ||
        !productForm.parentalAdvisory
      );
    } else {
      return !(
        productForm.title !== product.title ||
        productForm.artistName !== product.persons[0].name ||
        productForm.releaseAt !== product.release_at ||
        productForm.labelId !== product.label_id ||
        productForm.languageId !== product.language_id ||
        productForm.genreId !== product.genre_id ||
        productForm.parentalAdvisory !== product.parental_advisory
      );
    }
  };

  const createEditDisabledTrack = () => {
    if (!product) {
      return (
        !productForm.title ||
        !productForm.artistName ||
        !productForm.languageId ||
        !productForm.genreId ||
        !productForm.parentalAdvisory
      );
    } else {
      return !(
        productForm.title !== product.title ||
        productForm.artistName !== product.persons[0].name ||
        productForm.labelId !== product.label_id ||
        productForm.languageId !== product.language_id ||
        productForm.genreId !== product.genre_id ||
        productForm.parentalAdvisory !== product.parental_advisory
      );
    }
  };

  const handleEditProduct = async () => {
    if (!labels) return;
    const labelId = parseInt(Object.keys(labels)[0]);
    if (!labelId) return;

    let editedProduct;
    if (parentId) {
      const editedAlbum = await createEditTrack({
        ...productForm,
        parentId,
        labelId,
      });
      editedProduct = editedAlbum.children.find(
        (track) => track.id === product?.id,
      );
      if (!product && close) {
        close();
      }
    } else {
      editedProduct = await createEditAlbum({
        ...productForm,
        labelId,
      });
    }
    refreshProductFormState(editedProduct);
  };

  const uploadFile = async (files: File[]) => {
    if (!product) return;
    const recordType = parentId
      ? SupplyChainRecordTypes.Track
      : SupplyChainRecordTypes.Album;
    const fileType = parentId
      ? SupplyChainFileTypes.Audio
      : SupplyChainFileTypes.Cover;
    await supplyChainUploadFile(
      files[0],
      product.id,
      recordType,
      fileType,
      fileAxios,
    );
  };

  const getTileClass = ({ date }: CalendarTileProperties) => {
    if (!productForm.releaseAt) {
      return "";
    }

    const selectedDate = new Date(productForm.releaseAt);

    if (
      date.getFullYear() === selectedDate.getFullYear() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getDate() === selectedDate.getDate()
    ) {
      return "estimated-delivery-date-picker-selected";
    }
    return "";
  };

  return (
    <Box sx={{ marginTop: "20px" }}>
      <h3>
        {product ? "Edit" : "Create new"} {parentId ? "track" : "album"}:
      </h3>
      <FieldRow>
        Title:{" "}
        <TextField
          value={productForm.title}
          onChange={(e) => handleOnChange(e.target.value, "title")}
        />
      </FieldRow>
      <FieldRow>
        Artist name:{" "}
        <TextField
          value={productForm.artistName}
          onChange={(e) => handleOnChange(e.target.value, "artistName")}
        />
      </FieldRow>
      {product && (
        <FieldRow>
          <Uploader
            headerText={parentId ? "Upload Track" : "Upload Cover"}
            instructionText={
              parentId ? "click to upload track" : "click to upload cover photo"
            }
            acceptedFiles={
              parentId ? SupplyChainAudioFileTypes : SupplyChainCoverFileTypes
            }
            uploaderDisabled={false}
            onDrop={uploadFile}
          />
        </FieldRow>
      )}
      {product &&
        product.files.length > 0 &&
        product.files.map((file) => (
          <SupplyChainProductFile key={file.id} file={file} />
        ))}
      <FieldRow>
        <FormControl fullWidth>
          <InputLabel>Genre</InputLabel>
          <Select
            label="Genre"
            value={productForm.genreId ? productForm.genreId : ""}
            onChange={(e) => {
              handleOnChange(e.target.value, "genreId");
            }}
          >
            {genres?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>
      <FieldRow>
        <FormControl fullWidth>
          <InputLabel>Parental Advisory</InputLabel>
          <Select
            label="Parental Advisory"
            value={
              productForm.parentalAdvisory ? productForm.parentalAdvisory : ""
            }
            onChange={(e) => {
              handleOnChange(e.target.value, "parentalAdvisory");
            }}
          >
            {parentalAdvisories?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>
      <FieldRow>
        <FormControl fullWidth>
          <InputLabel>Language</InputLabel>
          <Select
            label="Language"
            value={productForm.languageId ? productForm.languageId : ""}
            onChange={(e) => {
              handleOnChange(e.target.value, "languageId");
            }}
          >
            {languages.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>
      {!parentId && (
        <FieldRow>
          Release date:
          <Calendar
            value={
              productForm.releaseAt
                ? new Date(productForm.releaseAt)
                : new Date()
            }
            onChange={(date: Date) => {
              setProductForm({ ...productForm, releaseAt: date.toISOString() });
            }}
            view="month"
            tileClassName={getTileClass}
          />
        </FieldRow>
      )}
      <FieldRow>
        <Button
          disabled={
            parentId ? createEditDisabledTrack() : createEditDisabledAlbum()
          }
          loading={isAlbumPending || isTrackPending || isLabelsPending}
          onClick={handleEditProduct}
        >
          {product ? "Edit" : "Create"} {parentId ? "Track" : "Album"}
        </Button>
      </FieldRow>
      {product?.children &&
        Object.values(product.children).length > 0 &&
        Object.values(product.children).map((track) => (
          <SupplyChainProduct
            key={track.id}
            product={track}
            parentId={product.id}
            fileAxios={fileAxios}
            supplyChainAxios={supplyChainAxios}
          />
        ))}
      {!parentId && product && (
        <FieldRow>
          <Button onClick={() => setAddTrack(!addTrack)}>
            {addTrack ? "Undo Add Track" : "Add New Track"}
          </Button>
        </FieldRow>
      )}
      {addTrack && product && !parentId && (
        <SupplyChainProduct
          parentId={product.id}
          close={() => setAddTrack(false)}
          fileAxios={fileAxios}
          supplyChainAxios={supplyChainAxios}
        />
      )}
    </Box>
  );
};
