import { Box } from "@mui/material";
import { SupplyChainSocketMessage } from "../../../atoms/supplychain/supplyChainSocketMessagesAtom";

export const SupplyChainWebsocketMessage = ({
  message,
}: {
  message: SupplyChainSocketMessage;
}) => {
  return (
    <Box>
      <Box>Message type: {message.eventName}</Box>
      <Box>Content: {JSON.stringify(message.content)}</Box>
    </Box>
  );
};
