import { faChevronRight, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Drawer from "react-bottom-drawer";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes/screens";
import {
  clearFiltersForStudioRoomSearch,
  setFiltersForStudioRoomSearch,
  setUserLatLngForStudioRoomSearch,
} from "../../../store/actions/studioRoomSearch";
import {
  clearFiltersForUserSearch,
  removeAutoCompleteSuggestions,
  setFiltersForUserSearch,
  setUserLatLngForUserSearch,
} from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { amenitiesList, AmenityType } from "../../../store/models/amenity";
import {
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
} from "../../../store/models/autocomplete";
import { Genre } from "../../../store/models/genres";
import { ProjectType } from "../../../store/models/project";
import { WEEKDAYS } from "../../../store/models/workingHours";
import { getServiceFromServiceType } from "../../../store/utils/serviceUtils";
import dolby from "../../../stories/assets/badgeIcons/dolby.svg";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import { ToggleSwitch } from "../../elements/ToggleSwitch/ToggleSwitch";
import { AmenityCard } from "../AmenityList/AmenityList";
import { BaseModal } from "../BaseModal/BaseModal";
import { UNVERIFIED_ENG_PROMO_CODE } from "../EngineerPromoListModal/EngineerPromoListModal";
import "./SearchFilters.css";

export interface FilterRowProps {
  label?: string;
  subLabel?: string;
  children: ReactNode | ((showAdvanceFilter: boolean) => ReactNode);
}

export const FilterRow = ({
  label = "",
  children,
  subLabel,
}: FilterRowProps) => {
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  if (typeof children === "function") {
    return (
      <div className="filter-row">
        <label className="h7-semi-bold">
          {label}
          <button
            className={"advanced-filter-button"}
            onClick={() => {
              setShowAdvanceFilter(!showAdvanceFilter);
            }}
          >
            <p className={"advanced-settings-text b2 m-2"}>Advanced</p>
            <FontAwesomeIcon
              size={"2xs"}
              icon={faChevronRight}
              color={"var(--dark-grey)"}
            />
          </button>
        </label>
        <div className="filter-row-options">{children(showAdvanceFilter)}</div>
      </div>
    );
  }
  return (
    <div className="filter-row">
      {label.length > 0 && <label className="h7-semi-bold">{label}</label>}
      {subLabel && <p className="label2 mb-2">{subLabel}</p>}
      <div className="filter-row-options">{children}</div>
    </div>
  );
};

export interface FilterButtonProps {
  label: ReactNode;
  value: string;
  onClick: (value: string) => void;
  active: boolean;
}

export const FilterButton = ({
  label,
  onClick,
  active,
  value,
}: FilterButtonProps) => {
  const buttonOnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const value = e.currentTarget.value;
      onClick(value);
    },
    [onClick],
  );

  return (
    <button
      value={value}
      className={`filter-button ${active ? "active" : ""}`}
      onClick={buttonOnClick}
    >
      {label}
    </button>
  );
};

export interface SearchFiltersProps {
  minRate: number;
  maxRate: number;
  simpleBudgetSelected: string[];
  maxDistance: number;
  serviceTypes: ProjectType[];
  genres: Genre[];
  allowNoEngineer?: boolean;
  selectedAmenities: AmenityType[];
  selectedWeekdays: WEEKDAYS[];
  durationMinutes?: number;
  handleBudgetClick: (value: string) => void;
  handleMinRateSet: (value: number) => void;
  handleMaxRateSet: (value: number) => void;
  handleDistance: (value: string) => void;
  handleServiceType: (value: string) => void;
  handleSetGenres: (value: string) => void;
  showEngineerFilterOptions?: boolean;
  showStudioFilterOptions?: boolean;
  setAllowNoEngineer?: (value: boolean) => void;
  setSelectedAmenity: (value: AmenityType) => void;
  setSelectedWeekdays?: (value: WEEKDAYS) => void;
  setDurationMinutes?: (value: number) => void;
  mapView?: boolean;
}

export const SearchFilters = ({
  minRate,
  maxRate,
  simpleBudgetSelected,
  maxDistance,
  serviceTypes,
  genres,
  durationMinutes,
  selectedAmenities,
  allowNoEngineer,
  selectedWeekdays,
  handleBudgetClick,
  handleMinRateSet,
  handleMaxRateSet,
  handleDistance,
  handleServiceType,
  handleSetGenres,
  showEngineerFilterOptions,
  showStudioFilterOptions,
  setAllowNoEngineer,
  setSelectedAmenity,
  setSelectedWeekdays,
  setDurationMinutes,
  mapView,
}: SearchFiltersProps) => {
  const distances = [
    { label: "within 10 miles", value: "10" },
    { label: "within 25 miles", value: "25" },
    { label: "within 50 miles", value: "50" },
  ];

  const budgets = [
    { label: "$", value: "1", maxRate: 0, minRate: 0 },
    { label: "$$", value: "2", maxRate: 99, minRate: 0 },
    { label: "$$$", value: "3", maxRate: 999, minRate: 100 },
    { label: "$$$$", value: "4", maxRate: 9999, minRate: 1000 },
  ];

  const durations = [
    { label: "1 hour", value: 60 },
    { label: "2 hours", value: 120 },
    { label: "4 hours", value: 240 },
    { label: "8 hours", value: 480 },
  ];

  const projectTypes = [
    { type: ProjectType.NO_TYPE, label: "All" },
    {
      type: ProjectType.RECORDING,
      label: getServiceFromServiceType(
        ProjectType.RECORDING,
        !showStudioFilterOptions,
      ),
    },
    {
      type: ProjectType.MIXING,
      label: getServiceFromServiceType(ProjectType.MIXING),
    },
    {
      type: ProjectType.TWO_TRACK_MIXING,
      label: getServiceFromServiceType(ProjectType.TWO_TRACK_MIXING),
    },
    {
      type: ProjectType.ATMOS_MIXING,
      label: getServiceFromServiceType(ProjectType.ATMOS_MIXING),
    },
    {
      type: ProjectType.MASTERING,
      label: getServiceFromServiceType(ProjectType.MASTERING),
    },
  ];

  const genresList = [
    { type: Genre.NO_GENRE, label: "All" },
    { type: Genre.HIP_HOP, label: "Hip Hop" },
    { type: Genre.RNB, label: "R&B" },
    { type: Genre.ROCK, label: "Rock" },
    { type: Genre.ALTERNATIVE, label: "Alternative" },
    { type: Genre.JAZZ, label: "Jazz" },
    { type: Genre.FOLK, label: "Folk" },
    { type: Genre.COUNTRY, label: "Country" },
    { type: Genre.ELECTRONIC, label: "Electronic" },
    { type: Genre.POP, label: "Pop" },
    { type: Genre.REGGAE, label: "Reggae" },
    { type: Genre.LATIN, label: "Latin" },
    { type: Genre.AFROBEAT, label: "Afrobeat" },
    { type: Genre.TECHNO, label: "Techno" },
    { type: Genre.GOSPEL, label: "Gospel" },
    { type: Genre.HOUSE, label: "House" },
    { type: Genre.AMAPIANO, label: "Amapiano" },
    { type: Genre.REGGAETON, label: "Reggaeton" },
    { type: Genre.PUNK, label: "Punk" },
    { type: Genre.ORCHESTRAL, label: "Orchestral" },
    { type: Genre.METAL, label: "Metal" },
  ];

  const Weekdays = [
    { type: WEEKDAYS.MONDAY, label: "Monday" },
    { type: WEEKDAYS.TUESDAY, label: "Tuesday" },
    { type: WEEKDAYS.WEDNESDAY, label: "Wednesday" },
    { type: WEEKDAYS.THURSDAY, label: "Thursday" },
    { type: WEEKDAYS.FRIDAY, label: "Friday" },
    { type: WEEKDAYS.SATURDAY, label: "Saturday" },
    { type: WEEKDAYS.SUNDAY, label: "Sunday" },
  ];

  return (
    <div>
      {showEngineerFilterOptions && (
        <FilterRow label="Service Type">
          {projectTypes.map(({ type, label }) => (
            <FilterButton
              key={type}
              label={<p className="b2-semi-bold">{label}</p>}
              onClick={handleServiceType}
              active={serviceTypes.includes(type)}
              value={type.toString()}
            />
          ))}
        </FilterRow>
      )}
      <FilterRow label="Budget">
        {(showAdvancedFilter) =>
          showAdvancedFilter || Boolean(maxRate) ? (
            <div className="filter-row-advance-budget-container">
              <div className="filter-budget-text-field">
                <p className="b2-semi-bold">Minimum</p>
                <FixedRangeNumberField
                  initialValue={minRate}
                  className="filter-row-advance-budget-number-field"
                  parseAsFloat={true}
                  placeholder="Minimum"
                  min={0}
                  max={9999}
                  onChange={(value) => {
                    handleMinRateSet(value);
                  }}
                />
              </div>
              <div className="filter-budget-text-field">
                <p className="b2-semi-bold">Maximum</p>
                <FixedRangeNumberField
                  initialValue={maxRate}
                  className="filter-row-advance-budget-number-field"
                  parseAsFloat={true}
                  placeholder="Maximum"
                  min={0}
                  max={9999}
                  onChange={(value) => {
                    handleMaxRateSet(value);
                  }}
                />
              </div>
            </div>
          ) : (
            budgets.map(({ label, value }, index) => (
              <FilterButton
                key={value}
                label={<p className="b2-semi-bold">{label}</p>}
                onClick={handleBudgetClick}
                value={value}
                active={simpleBudgetSelected.includes((index + 1).toString())}
              />
            ))
          )
        }
      </FilterRow>
      {showStudioFilterOptions && !mapView && (
        <FilterRow label="Location (miles)">
          {distances.map(({ label, value }) => (
            <FilterButton
              key={value}
              label={<p className="b2-semi-bold">{label}</p>}
              onClick={handleDistance}
              value={value}
              active={maxDistance === parseInt(value)}
            />
          ))}
        </FilterRow>
      )}
      {showStudioFilterOptions && (
        <FilterRow label="Availability" subLabel="Duration">
          {durations.map(({ label, value }) => (
            <FilterButton
              key={value}
              label={<p className="b2-semi-bold">{label}</p>}
              onClick={() => {
                if (!setDurationMinutes) {
                  return;
                }
                if (value === durationMinutes) {
                  setDurationMinutes(0);
                  return;
                }
                setDurationMinutes(value);
              }}
              active={durationMinutes === value}
              value={value.toString()}
            />
          ))}
        </FilterRow>
      )}
      {showStudioFilterOptions && (
        <FilterRow label="" subLabel="Days (select multiple)">
          {Weekdays.map(({ type, label }) => (
            <FilterButton
              key={type}
              label={<p className="b2-semi-bold">{label}</p>}
              onClick={() => {
                if (!setSelectedWeekdays) {
                  return;
                }
                setSelectedWeekdays(type);
              }}
              active={selectedWeekdays.includes(type)}
              value={type.toString()}
            />
          ))}
        </FilterRow>
      )}
      {showEngineerFilterOptions && (
        <FilterRow label="Genres">
          {genresList.map(({ type, label }) => (
            <FilterButton
              key={type}
              label={<p className="b2-semi-bold">{label}</p>}
              onClick={handleSetGenres}
              active={genres.includes(type)}
              value={type.toString()}
            />
          ))}
        </FilterRow>
      )}
      {showStudioFilterOptions && (
        <>
          <FilterRow label="Amenities">
            <div className={"amenity-list-container amenity-filter-container"}>
              {amenitiesList.map((amenityType) => (
                <AmenityCard
                  onClick={() => {
                    setSelectedAmenity(amenityType);
                  }}
                  key={amenityType}
                  amenity={{
                    amenity_type: amenityType,
                    id: amenityType,
                    created: "",
                    deleted: null,
                  }}
                  editMode={true}
                  isSelected={selectedAmenities.includes(amenityType)}
                />
              ))}
            </div>
          </FilterRow>
          <FilterRow label="Need Recording Engineer with Studio?">
            <ToggleSwitch
              id="allow-no-engineer-toggle-toggle"
              checked={!allowNoEngineer}
              onChange={(value) => {
                if (setAllowNoEngineer) {
                  setAllowNoEngineer(!value);
                }
              }}
              updateCheckedLocally={false}
              label={
                allowNoEngineer
                  ? "Recording Engineer Not Required"
                  : "All Studios"
              }
            />
          </FilterRow>
        </>
      )}
    </div>
  );
};

export interface SearchFilterContainerProps {
  isVisible: boolean;
  onClose: () => void;
  showEngineerFilterOptions?: boolean;
  showStudioFilterOptions?: boolean;
  mapView?: boolean;
}

export const SearchFilterContainer = ({
  isVisible,
  onClose,
  showEngineerFilterOptions = false,
  showStudioFilterOptions = false,
  mapView = false,
}: SearchFilterContainerProps) => {
  const userSearchState = useAppSelector((state) => state.userSearch);
  const userStateFetching = useAppSelector(
    (state) => state.userSearch.fetching,
  );
  const studioRoomSearchState = useAppSelector(
    (state) => state.studioRoomSearch,
  );
  const studioRoomStateFetching = useAppSelector(
    (state) => state.studioRoomSearch.fetching,
  );
  const isFetching = useMemo(() => {
    return userStateFetching || studioRoomStateFetching;
  }, [userStateFetching, studioRoomStateFetching]);
  const { isDesktop } = useMediaQueryBreakpoint();
  const dispatch = useAppDispatch();
  const [maxRate, setMaxRate] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [simpleBudgetSelected, setSimpleBudgetSelected] = useState<string[]>(
    [],
  );
  const history = useHistory();
  const [maxDistance, setMaxDistance] = useState(0);
  const [selectedAmenities, setSelectedAmenities] = useState<AmenityType[]>([]);
  const [allowNoEngineer, setAllowNoEngineer] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<ProjectType[]>(
    userSearchState.serviceTypes,
  );
  const [selectedWeekdays, setSelectedWeekdays] = useState<WEEKDAYS[]>([]);
  const [genres, setGenres] = useState<Genre[]>(userSearchState.genres);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const [upAndComingEng, setUpAndComingEng] = useState(false);

  useEffect(() => {
    if (isVisible) {
      if (showStudioFilterOptions) {
        setMinRate(studioRoomSearchState.minRate);
        setMaxRate(studioRoomSearchState.maxRate);
        setSimpleBudgetSelected(studioRoomSearchState.simpleBudgetSelected);
      } else {
        setMinRate(userSearchState.minRate);
        setMaxRate(userSearchState.maxRate);
        setSimpleBudgetSelected(userSearchState.simpleBudgetSelected);
      }
      setGenres(userSearchState.genres);
      setMaxDistance(userSearchState.maxDistance);
      setServiceTypes(userSearchState.serviceTypes);
      setUpAndComingEng(userSearchState.upAndComingEng);

      setAllowNoEngineer(studioRoomSearchState.allowNoEngineer);
      setDurationMinutes(studioRoomSearchState.durationMinutes);
      setSelectedAmenities(studioRoomSearchState.selectedAmenities);
      setAllowNoEngineer(studioRoomSearchState.allowNoEngineer);
      setSelectedWeekdays(studioRoomSearchState.daysAvailable);
    }
  }, [
    isVisible,
    userSearchState,
    studioRoomSearchState,
    showStudioFilterOptions,
    showEngineerFilterOptions,
  ]);

  const handleBudgetClick = useCallback((value: string) => {
    setMaxRate(0);
    setMinRate(0);
    setSimpleBudgetSelected((prevValue: string[]) => {
      if (prevValue.includes(value)) {
        const updatedBudgets = prevValue.filter((v) => v !== value);
        if (updatedBudgets.length === 0) {
          return [];
        }
        return updatedBudgets;
      } else {
        return [...prevValue, value].sort();
      }
    });
  }, []);

  const handleDistance = useCallback(
    async (value: string) => {
      setMaxDistance((prevValue) => {
        if (prevValue === parseInt(value)) {
          return 0;
        }
        return parseInt(value);
      });
      const onSuccess = (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords;
        dispatch(setUserLatLngForUserSearch({ latitude, longitude }));
        dispatch(setUserLatLngForStudioRoomSearch({ latitude, longitude }));
      };
      window.navigator.geolocation.getCurrentPosition(onSuccess);
    },
    [dispatch],
  );
  const applyFilters = useCallback(async () => {
    emitAnalyticsTrackingEvent("apply_search_filters", {
      simpleBudgetSelected,
      maxRate,
      minRate,
      maxDistance,
      serviceTypes,
      genres,
      selectedAmenities,
      allowNoEngineer,
      daysAvailable: selectedWeekdays,
      durationMinutes,
      upAndComingEng,
    });
    dispatch(
      setFiltersForUserSearch({
        simpleBudgetSelected,
        maxRate,
        minRate,
        maxDistance,
        serviceTypes,
        genres,
        upAndComingEng,
      }),
    );
    dispatch(
      setFiltersForStudioRoomSearch({
        simpleBudgetSelected,
        selectedAmenities,
        maxRate,
        minRate,
        maxDistance,
        allowNoEngineer,
        daysAvailable: selectedWeekdays,
        durationMinutes,
      }),
    );
    if (history.location.pathname === SCREENS.SIGNED_OUT_HOME_SCREEN) {
      history.push(SCREENS.SEARCH);
    }
    onClose();
  }, [
    simpleBudgetSelected,
    maxRate,
    minRate,
    maxDistance,
    serviceTypes,
    genres,
    selectedAmenities,
    allowNoEngineer,
    selectedWeekdays,
    durationMinutes,
    upAndComingEng,
    dispatch,
    history,
    onClose,
  ]);

  const handleServiceType = useCallback((value: string) => {
    const serviceType = parseInt(value);
    setServiceTypes((prevValue) => {
      if (serviceType === ProjectType.NO_TYPE) {
        return [ProjectType.NO_TYPE];
      }
      if (prevValue.includes(serviceType)) {
        const updatedServices = prevValue.filter((v) => v !== serviceType);
        if (updatedServices.length === 0) {
          return [ProjectType.NO_TYPE];
        }
        return updatedServices;
      }
      const newServiceTypes = [...prevValue, serviceType].filter(
        (v) => v !== ProjectType.NO_TYPE,
      );
      if (newServiceTypes.length === 0) {
        return [ProjectType.NO_TYPE];
      }
      if (newServiceTypes.length === 5) {
        return [ProjectType.NO_TYPE];
      }
      return newServiceTypes;
    });
  }, []);

  const handleSetGenres = useCallback((value: string) => {
    const genre = parseInt(value);
    setGenres((prevValue) => {
      if (genre === Genre.NO_GENRE) {
        return [Genre.NO_GENRE];
      }
      if (prevValue.includes(genre)) {
        const updatedGenres = prevValue.filter((v) => v !== genre);
        if (updatedGenres.length === 0) {
          return [Genre.NO_GENRE];
        }
        return updatedGenres;
      }
      const newGenres = [...prevValue, genre].filter(
        (v) => v !== Genre.NO_GENRE,
      );
      if (newGenres.length === 0) {
        return [Genre.NO_GENRE];
      }
      const numberOfGenreEnums = Object.keys(Genre).length / 2 - 1;
      if (newGenres.length === numberOfGenreEnums) {
        return [Genre.NO_GENRE];
      }
      return newGenres;
    });
  }, []);

  const handleMinRateSet = useCallback((value: number) => {
    setSimpleBudgetSelected([]);
    setMinRate(value);
  }, []);

  const handleMaxRateSet = useCallback((value: number) => {
    setSimpleBudgetSelected([]);
    setMaxRate(value);
  }, []);

  const setSelectedAmenity = useCallback((value: AmenityType) => {
    setSelectedAmenities((prevValue) => {
      let updatedAmenities;
      if (prevValue.includes(value)) {
        updatedAmenities = prevValue.filter((v) => v !== value);
      } else {
        updatedAmenities = [...prevValue, value];
      }
      if (updatedAmenities.length === amenitiesList.length) {
        return [];
      }
      return updatedAmenities;
    });
  }, []);

  const handleSelectedWeekdays = useCallback((value: WEEKDAYS) => {
    setSelectedWeekdays((prevValue) => {
      if (prevValue.includes(value)) {
        return prevValue.filter((v) => v !== value);
      }
      const updatedWeekdays = [...prevValue, value];
      if (updatedWeekdays.length === 7) {
        return [];
      }
      return updatedWeekdays;
    });
  }, []);

  const handleClearFilters = useCallback(() => {
    dispatch(clearFiltersForUserSearch());
    dispatch(clearFiltersForStudioRoomSearch());
  }, [dispatch]);

  const showStudioFilterOverride = useMemo(() => {
    if (!showEngineerFilterOptions) {
      return true;
    }
    if (
      !serviceTypes.includes(ProjectType.NO_TYPE) &&
      !serviceTypes.includes(ProjectType.RECORDING)
    ) {
      return false;
    }
    return true;
  }, [serviceTypes, showEngineerFilterOptions]);

  const searchFilterContent = (
    <>
      <header className="h6-semi-bold search-filter-container-header">
        Filters
        {isDesktop && (
          <FontAwesomeIcon
            color="var(--medium-grey)"
            size="xs"
            icon={faX}
            onClick={onClose}
            className="search-filter-close-icon"
          />
        )}
      </header>
      <div className="search-filters-scrollable-container">
        <SearchFilters
          selectedWeekdays={selectedWeekdays}
          simpleBudgetSelected={simpleBudgetSelected}
          selectedAmenities={selectedAmenities}
          allowNoEngineer={allowNoEngineer}
          showEngineerFilterOptions={showEngineerFilterOptions}
          showStudioFilterOptions={
            showStudioFilterOptions && showStudioFilterOverride
          }
          minRate={minRate}
          maxRate={maxRate}
          maxDistance={maxDistance}
          serviceTypes={serviceTypes}
          genres={genres}
          durationMinutes={durationMinutes}
          handleSetGenres={handleSetGenres}
          handleBudgetClick={handleBudgetClick}
          handleMinRateSet={handleMinRateSet}
          handleMaxRateSet={handleMaxRateSet}
          handleDistance={handleDistance}
          handleServiceType={handleServiceType}
          setAllowNoEngineer={setAllowNoEngineer}
          setSelectedAmenity={setSelectedAmenity}
          setSelectedWeekdays={handleSelectedWeekdays}
          setDurationMinutes={setDurationMinutes}
          mapView={mapView}
        />
      </div>
      <div className="filters-footer">
        <p
          className="clear-all-button-text b1"
          style={{
            borderBottom: "1px solid var(--medium-grey)",
          }}
          onClick={handleClearFilters}
        >
          Clear all
        </p>
        <Button
          variant={ButtonVariant.GRADIENT}
          loading={isFetching}
          onClick={applyFilters}
        >
          Apply
        </Button>
      </div>
    </>
  );

  if (!isDesktop) {
    return (
      <Drawer
        hideScrollbars
        className="search-filter-drawer"
        isVisible={isVisible}
        onClose={onClose}
      >
        {searchFilterContent}
      </Drawer>
    );
  }

  return (
    <BaseModal
      closeModal={onClose}
      modalIsOpen={isVisible}
      label="Search Filters"
      hideCloseButton={true}
    >
      <div className="search-filters-modal-container">
        {searchFilterContent}
      </div>
    </BaseModal>
  );
};

export interface SearchQuickFilterProps {
  isEngineerSearch?: boolean;
  showQuickFilters: boolean;
}

export const SearchQuickFilter = ({
  isEngineerSearch = false,
  showQuickFilters = true,
}: SearchQuickFilterProps) => {
  const userSearchState = useAppSelector((state) => state.userSearch);
  const { isDesktop } = useMediaQueryBreakpoint();
  const autocompleteSuggestions = useAppSelector(
    (state) => state.userSearch.autocompleteSuggestions,
  );
  const { serviceTypes, upAndComingEng } = userSearchState;
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const serviceTypeOptions = [
    ProjectType.RECORDING,
    ProjectType.MIXING,
    ProjectType.ATMOS_MIXING,
    ProjectType.MASTERING,
  ];

  const setUpAndComingEngFilter = (previousValue: string) => {
    const previousBooleanValue = previousValue === "true";
    dispatch(
      setFiltersForUserSearch({
        ...userSearchState,
        upAndComingEng: !previousBooleanValue,
      }),
    );
  };

  const setServiceType = (serviceType: ProjectType) => {
    emitAnalyticsTrackingEvent("search_quick_filter_selection", {
      service_type: serviceType,
    });
    if (serviceTypes.includes(serviceType)) {
      let updatedServiceTypes = serviceTypes.filter(
        (type) => type !== serviceType,
      );
      if (
        !updatedServiceTypes.includes(ProjectType.MIXING) &&
        updatedServiceTypes.includes(ProjectType.TWO_TRACK_MIXING)
      ) {
        updatedServiceTypes = updatedServiceTypes.filter(
          (type) => type !== ProjectType.TWO_TRACK_MIXING,
        );
      }
      dispatch(
        setFiltersForUserSearch({
          ...userSearchState,
          serviceTypes:
            updatedServiceTypes.length === 0
              ? [ProjectType.NO_TYPE]
              : updatedServiceTypes,
        }),
      );
    } else {
      const currentServiceTypes = [...serviceTypes];
      const noTypeIndex = currentServiceTypes.indexOf(ProjectType.NO_TYPE);
      if (noTypeIndex > -1) {
        currentServiceTypes.splice(noTypeIndex, 1);
      }
      if (
        serviceType === ProjectType.MIXING &&
        !currentServiceTypes.includes(ProjectType.TWO_TRACK_MIXING)
      ) {
        dispatch(
          setFiltersForUserSearch({
            ...userSearchState,
            serviceTypes: [
              ...currentServiceTypes,
              serviceType,
              ProjectType.TWO_TRACK_MIXING,
            ],
          }),
        );
      } else if (
        serviceType === ProjectType.MIXING &&
        currentServiceTypes.includes(ProjectType.TWO_TRACK_MIXING)
      ) {
        dispatch(
          setFiltersForUserSearch({
            ...userSearchState,
            serviceTypes: currentServiceTypes.filter(
              (type) => type !== ProjectType.TWO_TRACK_MIXING,
            ),
          }),
        );
      } else {
        dispatch(
          setFiltersForUserSearch({
            ...userSearchState,
            serviceTypes: [...currentServiceTypes, serviceType],
          }),
        );
      }
    }
  };

  const appliedPromoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );

  const showUpAndComingEngFilter = useMemo(() => {
    return (
      !serviceTypes.includes(ProjectType.RECORDING) &&
      appliedPromoCode?.code === UNVERIFIED_ENG_PROMO_CODE
    );
  }, [serviceTypes, appliedPromoCode]);

  const showRecordingFilter = useMemo(() => !upAndComingEng, [upAndComingEng]);

  if (!showQuickFilters) {
    return null;
  }

  return (
    <div className="quickfilter-container">
      {pathname !== SCREENS.PAGINATED_STUDIOS && (
        <>
          {serviceTypeOptions.map((serviceType) => {
            if (serviceType === ProjectType.ATMOS_MIXING) {
              return (
                <div key={serviceType} className="quickfilter-button">
                  <FilterButton
                    label={
                      <div
                        style={{
                          display: "flex",
                          marginTop: "auto",
                          marginBottom: "auto",
                          alignItems: "center",
                        }}
                      >
                        <img
                          height={16}
                          width={16}
                          src={dolby}
                          style={Object.assign(
                            {
                              marginRight: 10,
                            },
                            serviceTypes.includes(serviceType)
                              ? {
                                  border: "solid thin white",
                                  borderRadius: "50%",
                                }
                              : {},
                          )}
                          alt="service type"
                        />
                        <p
                          className="b2-semi-bold"
                          style={
                            serviceTypes.includes(serviceType)
                              ? {
                                  color: "var(--white)",
                                }
                              : {
                                  color: "var(--black)",
                                }
                          }
                        >
                          {getServiceFromServiceType(
                            serviceType,
                            isEngineerSearch,
                          )}
                        </p>
                      </div>
                    }
                    onClick={() => {
                      setServiceType(serviceType);
                    }}
                    active={serviceTypes.includes(serviceType)}
                    value={serviceType.toString()}
                  />
                </div>
              );
            }
            return (
              (serviceType !== ProjectType.RECORDING ||
                showRecordingFilter) && (
                <div key={serviceType} className="quickfilter-button">
                  <FilterButton
                    label={
                      <p className="b2-semi-bold">
                        {serviceType === ProjectType.MIXING
                          ? "Mixing"
                          : getServiceFromServiceType(
                              serviceType,
                              isEngineerSearch,
                            )}
                      </p>
                    }
                    onClick={() => {
                      setServiceType(serviceType);
                    }}
                    active={
                      serviceType === ProjectType.MIXING
                        ? serviceTypes.includes(ProjectType.MIXING) ||
                          serviceTypes.includes(ProjectType.TWO_TRACK_MIXING)
                        : serviceTypes.includes(serviceType)
                    }
                    value={serviceType.toString()}
                  />
                </div>
              )
            );
          })}
          {showUpAndComingEngFilter && (
            <div className="quickfilter-button">
              <FilterButton
                label={
                  <p className="b2-semi-bold">Recently joined - get 50% off</p>
                }
                onClick={setUpAndComingEngFilter}
                active={upAndComingEng}
                value={upAndComingEng.toString()}
              />
            </div>
          )}
        </>
      )}
      {!isDesktop &&
        autocompleteSuggestions.map((filter: AutocompleteSuggestion, index) => {
          switch (filter.type) {
            case AutoCompleteTypeEnum.ARTIST_CREDIT:
              if (pathname === SCREENS.PAGINATED_STUDIOS) {
                return null;
              }
              return (
                <div className="quickfilter-button">
                  <FilterButton
                    key={index}
                    label={<p className="b2-semi-bold">{filter.label}</p>}
                    onClick={() => {
                      dispatch(removeAutoCompleteSuggestions(filter));
                    }}
                    active={true}
                    value={filter.label}
                  />
                </div>
              );
            case AutoCompleteTypeEnum.LOCATION:
              return (
                <div className="quickfilter-button">
                  <FilterButton
                    key={index}
                    label={<p className="b2-semi-bold">{filter.label}</p>}
                    onClick={() => {
                      dispatch(removeAutoCompleteSuggestions(filter));
                    }}
                    active={true}
                    value={filter.label}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
    </div>
  );
};
