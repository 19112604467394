import { Box } from "@mui/material";
import { SupplyChainAlbums } from "./SupplyChainAlbums";
import { SupplyChainLabels } from "./SupplyChainLabels";
import { useSupplyChainWebsocket } from "../../../hooks/supplyChainHooks/useSupplyChainWebsocket";
import { SupplyChainWebsocketMessages } from "./SupplyChainWebsocketMessages";
import SupplyChainFileAxios from "../../../api/supplyChain/supplyChainFileAxios";
import SupplyChainAxios from "../../../api/supplyChain/supplyChainAxios";

export const SupplyChainLoggedIn = () => {
  useSupplyChainWebsocket();
  const fileAxios = SupplyChainFileAxios();
  const supplyChainAxios = SupplyChainAxios();

  return (
    <Box sx={{ paddingBottom: "200px" }}>
      <SupplyChainLabels />
      <SupplyChainAlbums
        fileAxios={fileAxios}
        supplyChainAxios={supplyChainAxios}
      />
      <SupplyChainWebsocketMessages />
    </Box>
  );
};
