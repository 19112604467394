import { AxiosInstance } from "axios";
import {
  SupplyChainCountry,
  SupplyChainGenre,
  SupplyChainLanguage,
  SupplyChainParentalAdvisory,
  SupplyChainProductType,
} from "./supplyChainModels";

export interface SupplyChainDashboardOptions {
  genres: SupplyChainGenre[];
  languages: SupplyChainLanguage[];
  countries: SupplyChainCountry[];
  product_types: SupplyChainProductType[];
  parental_advisories: SupplyChainParentalAdvisory[];
}

export const getSupplyChainDashboardOptions = async (
  axiosInstance: AxiosInstance,
): Promise<SupplyChainDashboardOptions> => {
  const res = await axiosInstance.get("/complementary/dashboard");
  return res.data.payload;
};
