import { useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { useSetAtom } from "jotai";
import supplyChainSocketMessagesAtom from "../../atoms/supplychain/supplyChainSocketMessagesAtom";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";

const SC_WEBSOCKET_URL = "wss://notify.sb.supplychainmusic.com";

enum SocketEvents {
  Processing = "file.processing",
  Percentage = "file.percentage",
  Validation = "file.validation.ready",
  Transcoding = "file.transcoding.ready",
  Ready = "file.ready",
  ValidationError = "file.validation.error",
  Aborted = "file.aborted",
}

// Creates websocket connection with SupplyChain notifications server.
export const useSupplyChainWebsocket = () => {
  const SCTOKEN = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPPLY_CHAIN_TOKEN);
  const parsedToken = JSON.parse(SCTOKEN || "");
  const socketRef = useRef<Socket | null>(null);
  const setMessages = useSetAtom(supplyChainSocketMessagesAtom);

  const queryClient = useQueryClient();

  useEffect(() => {
    socketRef.current = io(SC_WEBSOCKET_URL, {
      auth: {
        token: parsedToken,
      },
    });

    const refetchAlbums = () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_SUPPLY_CHAIN_ALBUMS],
      });
    };

    // Todo: Update with specific event handlers.
    const handleAllMessages = (eventName: string, payload: any) => {
      setMessages((prev) => [...prev, { ...payload, eventName }]);
    };

    socketRef.current.on(SocketEvents.Processing, (content) => {
      return handleAllMessages(SocketEvents.Processing, content);
    });

    socketRef.current.on(SocketEvents.Percentage, (content) => {
      return handleAllMessages(SocketEvents.Percentage, content);
    });

    socketRef.current.on(SocketEvents.Validation, (content) => {
      return handleAllMessages(SocketEvents.Validation, content);
    });

    socketRef.current.on(SocketEvents.Transcoding, (content) => {
      return handleAllMessages(SocketEvents.Transcoding, content);
    });

    socketRef.current.on(SocketEvents.Ready, (content) => {
      refetchAlbums();
      return handleAllMessages(SocketEvents.Ready, content);
    });

    socketRef.current.on(SocketEvents.ValidationError, (content) => {
      return handleAllMessages(SocketEvents.ValidationError, content);
    });

    socketRef.current.on(SocketEvents.Aborted, (content) => {
      return handleAllMessages(SocketEvents.Aborted, content);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.offAny((event, content) =>
          handleAllMessages(event, content),
        );
      }
    };
  }, []);
};
