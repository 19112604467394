import { Redirect } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { Box } from "@mui/material";
import useSupplyChainCredentialsQuery from "../../../hooks/supplyChainHooks/useSupplyChainCredentialsQuery";
import { useAtomValue } from "jotai";
import { supplyChainTokenAtom } from "../../../atoms/supplychain/supplyChainTokenAtom";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { SupplyChainLoggedIn } from "../../components/SupplyChain/SupplyChainLoggedIn";

export const DistributionScreen = () => {
  const user = useAppSelector((state) => state.accountInfo.user);

  const { isPending: isUserPending } = useSupplyChainCredentialsQuery(user?.id);
  const scToken = useAtomValue(supplyChainTokenAtom);

  if (!user) return <Redirect to="/for-all" />;

  return (
    <Box sx={{ padding: "16px" }}>
      <h1>DISTRIBUTION</h1>
      {(isUserPending || !scToken) && (
        <SoundWaveLoader width={400} height={100} />
      )}
      {!isUserPending && scToken && <SupplyChainLoggedIn />}
    </Box>
  );
};
