import { useHistory } from "react-router-dom";
import {
  useGetMusoAuth,
  useMusoCardText,
} from "../../../hooks/useGetMusoCredits";
import { SCREENS } from "../../../routes/screens";
import { useAppSelector } from "../../../store/hooks";
import {
  MUSO_ASSOCIATION_ACCEPTED_STATUSES,
  MusoAssociationStatus,
  MusoSelectionTab,
  getMusoAuthorizationURL,
} from "../../../store/models/muso";
import { SUBSCRIPTION_STATUS } from "../../../store/models/subscription";
import { isEngineerOnboardedSelector } from "../../../store/selectors/userInfoSelectors";
import { getMusoSelectionScreenRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import MusoLogo from "../../assets/muso-card.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./MusoCard.css";

interface MusoAuthButtonProps {
  showMusoLogo?: boolean;
}

export const MusoAuthButtons = ({
  showMusoLogo = true,
}: MusoAuthButtonProps) => {
  const history = useHistory();
  const engineerIsOnboarded = useAppSelector(isEngineerOnboardedSelector);
  const subscription = useAppSelector((state) => state.subscriptionStore);

  const {
    activeMusoUserId,
    loading: isLoadingMusoAssociation,
    musoAssociation,
  } = useGetMusoAuth(true);

  const onClickHandler = () => {
    if (
      subscription.stripe_subscription_status !== SUBSCRIPTION_STATUS.ACTIVE
    ) {
      emitAnalyticsTrackingEvent("click_muso_subscription_required_button", {});
      history.push(SCREENS.ADD_SUBSCRIPTION);
      return;
    }

    const redirectPath = getMusoSelectionScreenRoute(
      musoAssociation &&
        MUSO_ASSOCIATION_ACCEPTED_STATUSES.includes(musoAssociation.status)
        ? MusoSelectionTab.CREDITS
        : MusoSelectionTab.ROSTER,
    );

    if (!activeMusoUserId) {
      emitAnalyticsTrackingEvent("click_muso_link_profile_button", {
        musoAssociationId: musoAssociation?.id,
      });
      window.location.assign(getMusoAuthorizationURL(redirectPath));
      return;
    }

    emitAnalyticsTrackingEvent("click_muso_credits_button", {
      musoAssociationId: musoAssociation?.id,
      redirectPath: redirectPath,
    });
    history.push(redirectPath);
  };

  const label = useMusoCardText(
    engineerIsOnboarded,
    subscription,
    musoAssociation,
    activeMusoUserId,
  );

  return (
    <div className="muso-card-button-container">
      {showMusoLogo && (
        <img src={MusoLogo} alt="Muso" className="muso-card-icon" />
      )}
      <Button
        className="muso-card-button b1-semi-bold"
        loading={isLoadingMusoAssociation}
        disabled={!engineerIsOnboarded}
        variant={ButtonVariant.OUTLINED}
        onClick={onClickHandler}
      >
        {label}
      </Button>
      {activeMusoUserId &&
        musoAssociation?.status ===
          MusoAssociationStatus.MUSO_ASSOC_STATUS_CONFIRMED_WITH_SSO && (
          <Button
            className="muso-card-button b1-semi-bold mt-2"
            loading={isLoadingMusoAssociation}
            disabled={!engineerIsOnboarded}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              emitAnalyticsTrackingEvent("click_muso_edit_profile_button", {
                musoUserId: activeMusoUserId,
              });
              history.push(
                getMusoSelectionScreenRoute(MusoSelectionTab.ROSTER),
              );
            }}
          >
            Edit Profile
          </Button>
        )}
    </div>
  );
};
