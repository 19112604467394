import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useAtomValue } from "jotai";
import { supplyChainTokenAtom } from "../../atoms/supplychain/supplyChainTokenAtom";
import { supplyChainExpirationAtom } from "../../atoms/supplychain/supplyChainExpirationAtom";
import { getSupplyChainAlbums } from "../../api/supplyChain/getSupplyChainAlbums";
import { reduceArrayToObject } from "../../utils/utils";
import { SupplyChainProductModel } from "../../api/supplyChain/supplyChainModels";

export interface AlbumsData {
  [key: number]: SupplyChainProductModel;
}

const useGetSupplyChainAlbumsQuery = () => {
  const scToken = useAtomValue(supplyChainTokenAtom);
  const scExpiration = useAtomValue(supplyChainExpirationAtom);

  const enabled =
    Boolean(scToken && scExpiration) && new Date(scExpiration) > new Date();

  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.GET_SUPPLY_CHAIN_ALBUMS],
    queryFn: async () => {
      // TODO: add analytics before launch.
      const res = await getSupplyChainAlbums();
      return reduceArrayToObject(res.data) as AlbumsData;
    },
  });
};

export default useGetSupplyChainAlbumsQuery;
