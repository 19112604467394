import { SUPPLY_CHAIN_AUTH } from "../../store/utils/routes";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { EngineEarsError } from "../../store/actions/errorStore";

interface SupplyChainAuthResponse {
  sc_access_token: string;
  sc_expiration: string;
}

export const getSupplyChainCredentials =
  async (): Promise<SupplyChainAuthResponse> => {
    return makeBackendGetCallWithJsonResponse<SupplyChainAuthResponse>(
      SUPPLY_CHAIN_AUTH,
      "",
    ).then((res) => {
      if (!res.success) {
        throw res.resultJson as EngineEarsError;
      }
      return res.resultJson;
    });
  };
