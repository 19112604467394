import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIsMounted } from "usehooks-ts";
import { useFetchProductsByReleaseIdQuery } from "../../../api/products/hooks/usefetchProductsByReleaseIdQuery";
import { useFetchReleaseQuery } from "../../../api/releases/hooks/useFetchReleaseQuery";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { productPriceDetailsAtom } from "../../../atoms/products/productPriceDetails";
import { selectedFeaturedBundleIndexAtom } from "../../../atoms/profileScreen/artistReleaseTabView";
import { useSetPlayList } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { FooterFileTrackType } from "../../../store/actions/abPlayerStore";
import { useAppSelector } from "../../../store/hooks";
import convertArtistReleaseToPlaylistTrack from "../../../store/models/playListTrack/helpers/convertArtistReleaseToPlaylistTrack";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ArtistReleaseTrackTable } from "../ArtistReleaseTrackTable/ArtistReleaseTrackTable";
import { FeaturedArtistRelease } from "../FeaturedArtistRelease/FeaturedArtistRelease";
import { HardCodedReleaseCredits } from "./HardCodedReleaseCredits";

interface ReleaseDetailViewProps {
  releaseId: number;
  navigateBack?: () => void;
  hidePurchaseButton?: boolean;
  backButtonText?: string;
}

export const ReleaseDetailView = ({
  releaseId,
  navigateBack,
  backButtonText = "Back",
  hidePurchaseButton = true,
}: ReleaseDetailViewProps) => {
  const { cart } = useProductCart();
  const [, setPrice] = useAtom(productPriceDetailsAtom);

  const [autoSetPlayList, setAutoSetPlayList] = useState(false);
  const isMounted = useIsMounted();
  const { data: release, isPending } = useFetchReleaseQuery(releaseId);
  const { data: releaseTracks } = useFetchReleaseTracksQuery(releaseId);
  const { playlistId } = useAppSelector((state) => state.abPlayerStore);
  const { data: products } = useFetchProductsByReleaseIdQuery(releaseId);
  const [selectedProductIndex, setSelectedProductIndex] = useAtom(
    selectedFeaturedBundleIndexAtom,
  );
  const hasSetInitialSelectedProduct = useRef(false);
  const isCartEmpty = cart.length === 0;
  const hasSelectedProduct = selectedProductIndex !== "";

  const selectedProduct = useMemo(() => {
    if (selectedProductIndex === "" || !products) return undefined;

    return products.find((product) => product.id === selectedProductIndex);
  }, [products, selectedProductIndex]);

  // After fetching the list of products, if a product is already in the cart,
  // Then it should be the selected product
  useEffect(() => {
    if (
      products &&
      products.length > 0 &&
      !isCartEmpty &&
      !hasSelectedProduct &&
      !hasSetInitialSelectedProduct.current
    ) {
      for (const cartItem of cart) {
        if (products?.find((product) => product.id === cartItem.product_id)) {
          if (releaseTracks.length === cartItem.release_track_ids.length) {
            setSelectedProductIndex(cartItem.product_id);
            break;
          }
        }
      }
      hasSetInitialSelectedProduct.current = true;
    }
  }, [
    cart,
    hasSelectedProduct,
    isCartEmpty,
    products,
    releaseTracks.length,
    selectedProductIndex,
    setSelectedProductIndex,
  ]);

  // Clear the selected product if there is no item in the cart
  useEffect(() => {
    if (
      isCartEmpty &&
      hasSelectedProduct &&
      // This condition is to support selecting a purchased product without adding it to the cart
      !selectedProduct?.product_purchased_by_current_user
    ) {
      setSelectedProductIndex("");
    }
  }, [
    hasSelectedProduct,
    isCartEmpty,
    selectedProduct?.product_purchased_by_current_user,
    setSelectedProductIndex,
  ]);

  useEffect(() => {
    return () => {
      setSelectedProductIndex("");
    };
  }, [setSelectedProductIndex]);

  const lowestPricedProduct = useMemo(() => {
    if (!products || products.length === 0) return undefined;

    return products.reduce((currentLowestPricedProduct, currentProduct) => {
      return parseFloat(currentProduct.unit_price) <
        parseFloat(currentLowestPricedProduct.unit_price)
        ? currentProduct
        : currentLowestPricedProduct;
    }, products[0]);
  }, [products]);

  const productToGetPriceFrom = selectedProduct || lowestPricedProduct;

  // If all individual tracks are selected and no bundle was selected,
  // we'll auto-select the bundle with the lowest unit price
  useEffect(() => {
    if (
      lowestPricedProduct &&
      releaseTracks.length ===
        cart.find((cartItem) => cartItem.product_id === lowestPricedProduct.id)
          ?.release_track_ids.length &&
      !hasSelectedProduct
    ) {
      setSelectedProductIndex(lowestPricedProduct.id);
    }
  }, [
    cart,
    hasSelectedProduct,
    lowestPricedProduct,
    releaseTracks.length,
    setSelectedProductIndex,
  ]);

  const hasUnitPrice = Boolean(productToGetPriceFrom?.unit_price);
  const unitPriceDiffersFromTotalPrice =
    productToGetPriceFrom?.unit_price !== productToGetPriceFrom?.total_price;

  const unitPrice =
    hasUnitPrice && unitPriceDiffersFromTotalPrice
      ? productToGetPriceFrom?.unit_price
      : undefined;

  const setPlayList = useSetPlayList();

  useEffect(() => {
    if (!products) return;
    products.forEach((currentProduct) => {
      setPrice({
        [currentProduct.id]: {
          unitPrice: currentProduct.unit_price
            ? Number(currentProduct.unit_price)
            : 0,
          totalPrice: currentProduct.total_price
            ? Number(currentProduct.total_price)
            : 0,
        },
      });
    });
  }, [products, setPrice]);

  useEffect(() => {
    if (!isMounted) return;
    if (autoSetPlayList) return;
    if (!releaseId) {
      return;
    }
    if (!release) {
      return;
    }
    if (releaseTracks.length === 0) {
      return;
    }
    setAutoSetPlayList(true);
    setPlayList({
      tracks: convertArtistReleaseToPlaylistTrack(releaseTracks).filter(
        (releaseTrack) => !releaseTrack.paymentRequiredForPlayback,
      ),
      index: 0,
      playOnLoad: false,
      playlistId: releaseId,
      footerFileTrackType: FooterFileTrackType.RELEASE,
    });
  }, [
    release,
    releaseTracks,
    releaseId,
    playlistId,
    setPlayList,
    isMounted,
    autoSetPlayList,
  ]);

  return (
    <Box p={2} sx={{ display: "flex", flexDirection: "column", gap: "54px" }}>
      <FeaturedArtistRelease
        release={release}
        isReleaseLoading={isPending}
        products={products}
        selectedProductIndex={
          hidePurchaseButton ? undefined : selectedProductIndex
        }
        setSelectedProductIndex={setSelectedProductIndex}
        releaseTracks={releaseTracks}
        isOnReleaseDetailsView={true}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        {selectedProduct && !hidePurchaseButton && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {/*{Boolean(selectedProduct?.purchase_limit) && (*/}
            {/*  <Text>*/}
            {/*    <strong>Limited quantity: </strong>*/}
            {/*    {`${selectedProduct?.available_quantity} / ${selectedProduct?.purchase_limit} remaining`}*/}
            {/*  </Text>*/}
            {/*)}*/}
            <Text bold variant={TextStyleVariant.S2}>
              Bundle Description
            </Text>
            <Text
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {selectedProduct.description}
            </Text>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text bold variant={TextStyleVariant.S2}>
              Project Songs
            </Text>
            {navigateBack && (
              <Button onClick={navigateBack} variant={ButtonVariant.UNSTYLED}>
                <FontAwesomeIcon
                  style={{
                    marginRight: "4px",
                  }}
                  icon={faAngleLeft}
                />
                <Text>{backButtonText}</Text>
              </Button>
            )}
          </Box>
          <ArtistReleaseTrackTable
            artistUserId={release?.artist_user_id}
            releaseTracks={releaseTracks}
            unitPrice={hidePurchaseButton ? undefined : unitPrice}
            productToGetPriceFromId={
              hidePurchaseButton ? undefined : productToGetPriceFrom?.id
            }
            lowestUnitPriceProductId={lowestPricedProduct?.id}
          />
        </Box>
        {/* TODO: Remove this and replace with actual credits structured data. */}
        <HardCodedReleaseCredits release={release} />
      </Box>
    </Box>
  );
};
