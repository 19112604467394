import { TextColor } from "./TextUtils";
import { styled, Theme, Typography } from "@mui/material";

const convertLegacyColor = (
  color: TextColor | string | undefined,
  theme: Theme,
) => {
  if (!color) {
    return theme.palette.text.primary;
  }
  switch (color) {
    case TextColor.PRIMARY:
      return theme.palette.text.primary;
    case TextColor.SECONDARY:
      return theme.palette.text.secondary;
    case TextColor.TERTIARY:
      return theme.palette.text.disabled;
    case TextColor.INVERT:
      return theme.palette.customColor.textInvertColor;
    case TextColor.WHITE:
      return theme.palette.common.white;
    case TextColor.BLACK:
      return theme.palette.common.black;
    case TextColor.LIGHT_GRAY:
      return theme.palette.standardColor.Gray[200];
    case TextColor.ERROR:
      return theme.palette.customColor.textErrorColor;
    case TextColor.PRIMARY_NO_DARK_THEME:
      return theme.palette.standardColor.Gray[950];
    case TextColor.SUCCESS:
      return theme.palette.standardColor.Green[600];
    case TextColor.FAILED:
      return theme.palette.standardColor.Red[600];
    default:
      return color;
  }
};

export const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => !["$bold", "$color"].includes(prop as string),
})<{
  $bold: boolean;
  $color?: TextColor | string;
}>(({ theme, $bold, $color }) => ({
  fontFamily: "Roobert",
  fontStyle: "normal",
  color: convertLegacyColor($color, theme),
  ...($bold && { fontWeight: 600 }),
}));
