import { useSetAtom } from "jotai";
import { FC } from "react";
import { AuthFlows } from "../../../../constants/authSteps";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { SCREENS } from "../../../../routes/screens";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { authModalOpenAtom, currentFlowAtom } from "../../Auth/atoms";
import "./NavLoginContainer.css";
import { useHistory } from "react-router-dom";

interface NavLoginContainerProps {
  column?: boolean;
  onClick?: () => void;
  showSignUpButton?: boolean;
}

export const NavLoginContainer: FC<NavLoginContainerProps> = ({
  column = false,
  onClick,
  showSignUpButton = true,
}: NavLoginContainerProps) => {
  const setFlow = useSetAtom(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const history = useHistory();
  const { isMobile, isTablet } = useMediaQueryBreakpoint();

  return (
    <div
      className={`nav-login-container ${column ? "nav-login-container--column" : ""}`}
    >
      {showSignUpButton && (
        <Button
          className="nav-login-button"
          fullWidth
          {...(isMobile || (isTablet && { href: SCREENS.AUTH_SCREEN }))}
          onClick={() => {
            emitAnalyticsTrackingEvent("clicked_sign_up", {}, undefined);
            if (isMobile) {
              history.push(SCREENS.AUTH_SCREEN);
            }
            setFlow(AuthFlows.START_SIGNUP);
            setAuthModalOpen(true);
            if (onClick) {
              onClick();
            }
          }}
          variant={ButtonVariant.OUTLINED}
        >
          Sign up
        </Button>
      )}
      <Button
        className="nav-login-button"
        fullWidth
        {...(isMobile || (isTablet && { href: SCREENS.AUTH_SCREEN }))}
        onClick={() => {
          emitAnalyticsTrackingEvent("clicked_login", {}, undefined);
          if (isMobile) {
            history.push(SCREENS.AUTH_SCREEN);
          }
          setFlow(AuthFlows.LOGIN);
          setAuthModalOpen(true);
          if (onClick) {
            onClick();
          }
        }}
      >
        Login
      </Button>
    </div>
  );
};
