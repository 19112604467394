import { Box } from "@mui/material";
import { SupplyChainFile } from "../../../api/supplyChain/supplyChainModels";

interface SupplyChainProductFileProps {
  file: SupplyChainFile;
}

export const SupplyChainProductFile = ({
  file,
}: SupplyChainProductFileProps) => {
  return (
    <Box>
      {file.type_label === "image" && (
        <Box>
          <img src={file.name} />
        </Box>
      )}
      {file.type_label === "audio" && (
        <Box>
          <audio controls>
            <source src={file.name} />
          </audio>
        </Box>
      )}
    </Box>
  );
};
