import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import {
  getSupplyChainDashboardOptions,
  SupplyChainDashboardOptions,
} from "../../api/supplyChain/getSupplyChainDashboardOptions";
import { AxiosInstance } from "axios";

export const initialDashboardOptions: SupplyChainDashboardOptions = {
  genres: [],
  languages: [],
  countries: [],
  product_types: [],
  parental_advisories: [],
};

const useGetSupplyDashboardOptionsQuery = (axiosInstance: AxiosInstance) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SUPPLY_CHAIN_DASHBOARD_OPTIONS],
    queryFn: async () => {
      // TODO: add analytics before launch.
      return await getSupplyChainDashboardOptions(axiosInstance);
    },
  });
};

export default useGetSupplyDashboardOptionsQuery;
