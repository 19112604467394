export interface SupplyChainGenre {
  id: number;
  name: string;
}

export interface SupplyChainLanguage {
  id: number;
  name: string;
  long_code: string;
  code: string;
}

export interface SupplyChainCountry {
  id: number;
  name: string;
  code: string;
}

export interface SupplyChainParentalAdvisory {
  id: number;
  title: string;
}

export interface SupplyChainProductType {
  id: number;
  name: string;
}

export interface SupplyChainFile {
  id: number;
  type: number;
  type_label: string;
  name: string;
  original_name: string;
  status: number;
  status_label: string;
}

export interface SupplyChainPerson {
  // ID is present only if user was previously created.
  id?: number;
  name: string;
  type: number;
  is_featured: number;
}

export interface SupplyChainContributors {
  id: number;
}

export interface SupplyChainProductModel {
  id: number;
  title: string;
  email: string;
  logo: string;
  parent_id: number;
  country: SupplyChainCountry;
  genre_id: number;
  language_id: number;
  persons: SupplyChainPerson[];
  parental_advisory: number;
  release_at?: string;
  label_id: number;
  children: SupplyChainProductModel[];
  files: SupplyChainFile[];
  contributors: SupplyChainContributors[];
  product_type_id: number;
  status: number;
  audio_isrc: string;
}

export const SupplyChainCoverFileTypes = [
  "image/jpeg",
  "image/pjpeg",
  "image/png",
];

export const SupplyChainAudioFileTypes = [
  "audio/mpeg",
  "audio/x-flac",
  "audio/x-aiff",
  "audio/wave",
  "audio/x-wav",
  "audio/wav",
  "audio/mpeg3",
  "audio/mp3",
  "audio/flac",
  "audio/mp4",
];
