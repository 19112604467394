import { Box } from "@mui/material";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import useGetSupplyChainAlbumsQuery from "../../../hooks/supplyChainHooks/useGetSupplyChainAlbumsQuery";
import { SupplyChainProduct } from "./SupplyChainProduct";
import { AxiosInstance } from "axios";

interface SupplyChainAlbumsProps {
  fileAxios: AxiosInstance;
  supplyChainAxios: AxiosInstance;
}

export const SupplyChainAlbums = ({
  fileAxios,
  supplyChainAxios,
}: SupplyChainAlbumsProps) => {
  const { isPending, data: albums } = useGetSupplyChainAlbumsQuery();

  if (isPending) {
    return <SoundWaveLoader width={400} height={100} />;
  }

  const createdAlbums =
    albums && Object.keys(albums).length
      ? Object.values(albums).map((album) => (
          <Box
            key={album.id}
            sx={{
              width: "100%",
              outline: "1px solid blue",
              borderRadius: "5px",
            }}
          >
            <SupplyChainProduct
              product={album}
              fileAxios={fileAxios}
              supplyChainAxios={supplyChainAxios}
            />
          </Box>
        ))
      : null;

  return (
    <Box sx={{ width: "600px" }}>
      <SupplyChainProduct
        fileAxios={fileAxios}
        supplyChainAxios={supplyChainAxios}
      />
      {createdAlbums}
    </Box>
  );
};
