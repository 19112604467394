import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useAtom, useSetAtom } from "jotai";
import { supplyChainTokenAtom } from "../../atoms/supplychain/supplyChainTokenAtom";
import { supplyChainExpirationAtom } from "../../atoms/supplychain/supplyChainExpirationAtom";
import { getSupplyChainCredentials } from "../../api/supplyChain/getSupplyChainCredentials";

const useSupplyChainCredentialsQuery = (userId?: number) => {
  const setSCToken = useSetAtom(supplyChainTokenAtom);
  const [scExpiration, setSCExpiration] = useAtom(supplyChainExpirationAtom);
  const clearSCToken = () => {
    setSCToken("");
    setSCExpiration("");
  };

  const enabled =
    Boolean(userId) && (!scExpiration || new Date(scExpiration) < new Date());

  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.GET_SUPPLY_CHAIN_CREDENTIALS, { userId }],
    queryFn: async () => {
      // TODO: add analytics before launch.
      try {
        const res = await getSupplyChainCredentials();
        setSCToken(res.sc_access_token);
        setSCExpiration(res.sc_expiration);
        return res;
      } catch {
        clearSCToken();
        return null;
      }
    },
  });
};

export default useSupplyChainCredentialsQuery;
