import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { BudgetManager } from "../../../../store/models/project";
import { getTransactionOverviewRoute } from "../../../../store/utils/routeGetters";
import {
  Button,
  ButtonProps,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { ActionToPerform } from "../utils";

interface AdminActionButtonProps extends ButtonProps {
  actionToPerform: ActionToPerform;
  budgetManager: BudgetManager | undefined;
  handleOpenBookingRequestedPanel: () => void;
  toggleAssignProjectModal: Dispatch<SetStateAction<boolean>>;
  transactionId: number;
}

export const AdminActionButton = ({
  actionToPerform,
  budgetManager,
  handleOpenBookingRequestedPanel,
  toggleAssignProjectModal,
  transactionId,
  ...props
}: AdminActionButtonProps) => {
  const history = useHistory();
  const { isDesktop } = useMediaQueryBreakpoint();
  const scheduledProjectAction =
    actionToPerform === ActionToPerform.VIEW_REQUEST ||
    actionToPerform === ActionToPerform.ASSIGN_PROJECT;

  const handleClick = () => {
    // open AssignProject modal (allows admin to assign engineer and studio for a project)
    if (actionToPerform === ActionToPerform.ASSIGN_PROJECT) {
      toggleAssignProjectModal(true);
    }

    // open booking requested panel
    if (actionToPerform === ActionToPerform.VIEW_REQUEST) {
      handleOpenBookingRequestedPanel();
      return;
    }

    if (!budgetManager) return;
    history.push(
      getTransactionOverviewRoute(transactionId, "", budgetManager.code),
    );
  };

  return (
    <Button
      variant={
        scheduledProjectAction ? ButtonVariant.OUTLINED : ButtonVariant.GRADIENT
      }
      onClick={handleClick}
      fullWidth={isDesktop}
      {...props}
    >
      {actionToPerform}
    </Button>
  );
};
