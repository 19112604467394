import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { useGetActiveStudioRoom } from "../../../hooks/studioHooks";
import { useTransactionProjects } from "../../../hooks/transactionHook";
import {
  useMixMasterCheckoutCarts,
  useRecordingCheckoutCarts,
} from "../../../hooks/useCheckoutCarts";
import useModal from "../../../hooks/useModal";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { SCREENS } from "../../../routes/screens";
import { clearEngineerRecordingServices } from "../../../store/actions/engineerRecordingServices";
import {
  resetGenerateBookingState,
  restoreGenerateBookingState,
  setGenerateBookingLoading,
} from "../../../store/actions/generateBookingStore";
import { clearAppliedPromoCode } from "../../../store/actions/marketing";
import {
  resetMixMasterCart,
  updateMixMasterOrderSummary,
} from "../../../store/actions/mixMasterCartsStore";
import {
  resetRecordingCartsSlice,
  updateRecordingOrderSummary,
} from "../../../store/actions/recordingCartsStore";
import {
  clearRecordingServices,
  clearServicesForRefresh,
} from "../../../store/actions/services";
import { clearCartState } from "../../../store/actions/shoppingCart";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { selectIsPredefinedSession } from "../../../store/selectors/shoppingCart";
import { getTransactionOverviewRoute } from "../../../store/utils/routeGetters";
import {
  handleSettingUpMixMasterCart,
  handleSettingUpRecordingCart,
  setInitialBookingParametersCookie,
  setUpInitialBookingParamsFromTransactionCode,
} from "../../../store/utils/transactionBookingUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { TRANSACTION_SCREEN_LAYOUT_CHANGE_BREAKPOINT } from "../../../utils/breakpoints";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { ProjectList } from "../../components/ProjectList/ProjectList";
import { SessionBookingStudioInfo } from "../../components/SessionBookingStudioInfo/SessionBookingStudioInfo";
import { DEFAULT_MAP_STYLE } from "../../elements/GMapElement/GMapElement";
import TransactionBookingHeader from "./TransactionBookingHeader";
import TransactionBookingMixMasterDetails from "./TransactionBookingMixMasterDetails";
import TransactionBookingOrderDetails from "./TransactionBookingOrderDetails";
import TransactionBookingRecordingDetails from "./TransactionBookingRecordingDetails";
import "./TransactionBookingScreen.css";
import { StyledRecordingServiceInfoContainer } from "./TransactionBookingScreen.styles";
import TransactionBookingUpdateWarningModal from "./TransactionBookingUpdateWarningModal";

const TransactionBookingScreen = () => {
  const oneColumnDisplay = useMediaQuery(
    `(max-width: ${TRANSACTION_SCREEN_LAYOUT_CHANGE_BREAKPOINT}px)`,
  );
  const dispatch = useAppDispatch();
  const { transaction_code: transactionCode } = useParams<{
    transaction_code: string;
  }>();
  const [canUpdateCart, setCanUpdateCart] = useState(false);
  const {
    isOpen: isUpdateWarningModalOpen,
    openModal: openUpdateWarningModal,
    closeModal: closeUpdateWarningModal,
  } = useModal();
  const history = useHistory();
  const generateBookingStore = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const {
    isLoading,
    engineerId,
    engineerUserId,
    activeTransactionId,
    activeServiceType,
    activeStudioId,
    activeStudioRoomId,
    activeServiceId,
    linkShareStatus,
    activeServiceTypeProjectIds,
  } = generateBookingStore;

  useSetPageTitle("Booking");

  const { transactionData } = useAppSelector((state) => state.transactionStore);
  const projects = useTransactionProjects(transactionData);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const engineerRecordingService = useAppSelector(
    (state) => state.engineerServices.recordingService,
  );
  const recordingCart = useAppSelector(
    (state) => state.recordingCartsStore.cart,
  );
  const { substituteLocation } = recordingCart;
  const { cart: mixMasterCart } = useAppSelector(
    (state) => state.mixMasterCartsStore,
  );
  const isRecordingServiceTypeActive =
    activeServiceType == ProjectType.RECORDING;

  const studioRoom = useGetActiveStudioRoom(
    activeServiceType,
    activeStudioRoomId,
    activeStudioId,
  );
  const isPredefinedRecordingBooking = useAppSelector(
    selectIsPredefinedSession(studioRoom),
  );

  const activeRecordingService = useMemo(() => {
    if (!isRecordingServiceTypeActive) {
      return null;
    }

    // If we have a studio room selected, the active recording service is the
    // studio room's recording service.
    if (studioRoom) {
      return studioRoom?.recording_service ?? null;
    }

    return engineerRecordingService ?? null;
  }, [studioRoom, engineerRecordingService, isRecordingServiceTypeActive]);

  const isLoggedInUserGeneratingBooking = useMemo(() => {
    if (!transactionData || !loggedInUser) {
      return false;
    }

    // Shouldn't happen, but guard against it anyway.
    if (transactionData.created_by_user?.id !== loggedInUser.id) {
      return false;
    }

    // If we have an engineer ID, the logged in user must be the engineer.
    // This applies to both mixing/mastering and recording.
    if (engineerId) {
      return loggedInUser.engineer?.id === engineerId;
    }

    // If we don't have an engineer ID, this is most likely a studio manager generating
    // a booking for a studio. Return if this is a predefined recording booking.
    return isPredefinedRecordingBooking;
  }, [isPredefinedRecordingBooking, engineerId, transactionData, loggedInUser]);

  useEffect(() => {
    const setupTransactionPage = async () => {
      dispatch(clearServicesForRefresh());
      dispatch(clearRecordingServices());
      // If we do not have a transaction ID on page mount, this means that
      // the page is being loaded from a fresh refresh. Fetch the transaction from the
      // backend and populate the page with transaction information.
      if (!activeTransactionId) {
        await setUpInitialBookingParamsFromTransactionCode(
          transactionCode,
          (transactionId) => {
            history.replace(
              getTransactionOverviewRoute(transactionId, transactionCode),
            );
          },
          () => {
            history.replace(SCREENS.HOME_SCREEN);
          },
          dispatch,
          loggedInUser,
          activeServiceId,
        );
      } else {
        // If we have a transaction ID on page mount, it's either:
        // - The page is being loaded from an entrypoint somewhere else on the site. Then, we need to set the initial cart based on the active service type.
        // - The screen is remounted due to our app being re-wrap in ChatInjector after logging in. Then, we need to restore previously set parameters. This is a TEMPORARY FIX for such case!
        // https://docs.google.com/document/d/1kym-pYqK4ih7qr7ma5-QQaAog5863AmTG2gKnzLaOxs/edit
        const bookingParameters = {
          transactionId: activeTransactionId,
          engineerId,
          engineerUserId,
          activeStudioId,
          activeStudioRoomId,
          activeServiceType,
          activeServiceTypeProjectIds,
        };

        setInitialBookingParametersCookie(transactionCode, bookingParameters);
        dispatch(
          restoreGenerateBookingState({
            ...generateBookingStore,
            isLoading: true,
          }),
        );

        if (isRecordingServiceTypeActive) {
          await handleSettingUpRecordingCart(
            recordingCart,
            dispatch,
            bookingParameters,
            transactionData?.recording_sessions || [],
            activeServiceId,
          );
        } else {
          await handleSettingUpMixMasterCart(
            mixMasterCart,
            dispatch,
            bookingParameters,
          );
        }

        emitAnalyticsTrackingEvent(
          "booking_flow-init",
          {
            transactionId: activeTransactionId,
          },
          loggedInUser?.id,
        );
      }

      setCanUpdateCart(true);
      dispatch(setGenerateBookingLoading(false));
    };
    dispatch(updateRecordingOrderSummary(null));
    dispatch(updateMixMasterOrderSummary(null));
    void setupTransactionPage();
    return () => {
      setCanUpdateCart(false);
      dispatch(resetMixMasterCart());
      dispatch(resetRecordingCartsSlice());
      dispatch(resetGenerateBookingState());
      dispatch(clearCartState());
      dispatch(clearAppliedPromoCode());
      dispatch(clearEngineerRecordingServices());
    };
  }, []);

  useMixMasterCheckoutCarts(
    transactionCode,
    isLoggedInUserGeneratingBooking,
    canUpdateCart && !isRecordingServiceTypeActive && !linkShareStatus,
  );

  useRecordingCheckoutCarts(
    transactionCode,
    isLoggedInUserGeneratingBooking,
    studioRoom,
    canUpdateCart && isRecordingServiceTypeActive && !linkShareStatus,
  );

  const renderRecordingServiceInfo = () => {
    if (!isRecordingServiceTypeActive || !activeRecordingService) {
      return null;
    }

    let recordingLocation = activeRecordingService.recording_location;

    if (
      activeServiceId === engineerRecordingService?.id &&
      substituteLocation?.formatted_address
    ) {
      recordingLocation = {
        city_location: substituteLocation.formatted_address,
        latitude: substituteLocation.geometry?.location?.lat(),
        longitude: substituteLocation.geometry?.location?.lng(),
      };
    }
    return (
      <StyledRecordingServiceInfoContainer>
        <SessionBookingStudioInfo
          recordingLocation={recordingLocation}
          cancellationPolicy={activeRecordingService.cancellation_policy}
          studioRoom={activeRecordingService.studio_room}
          revealLocation={false}
          customMapStyle={{
            ...DEFAULT_MAP_STYLE,
            height: "270px",
          }}
        />
      </StyledRecordingServiceInfoContainer>
    );
  };

  const cssPrefix = "transaction-booking-screen_";
  const disabledClassname = `${cssPrefix}content-container-disabled`;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div
        className={`${cssPrefix}container container-fluid`}
        onClick={() => {
          if (linkShareStatus) {
            emitAnalyticsTrackingEvent(
              "booking_flow-open_update_warning_modal",
              {
                transactionId: activeTransactionId,
              },
              loggedInUser?.id,
            );
            openUpdateWarningModal();
          }
        }}
      >
        {!isLoading && (
          <>
            <TransactionBookingHeader />
            <div
              className={classNames(`${cssPrefix}content-container`, {
                [disabledClassname]: Boolean(linkShareStatus),
              })}
            >
              <div className={`${cssPrefix}content-container-column`}>
                {isRecordingServiceTypeActive && (
                  <TransactionBookingRecordingDetails
                    recordingService={activeRecordingService}
                    studioRoom={studioRoom}
                  />
                )}
                {!isRecordingServiceTypeActive && (
                  <>
                    <TransactionBookingMixMasterDetails
                      isLoggedInUserGeneratingBooking={
                        isLoggedInUserGeneratingBooking
                      }
                    />
                    {transactionData?.code && (
                      <ProjectList
                        projects={projects}
                        transactionCode={transactionData?.code}
                      />
                    )}
                  </>
                )}
                {/* Position for two-column display */}
                {!oneColumnDisplay && renderRecordingServiceInfo()}
              </div>
              <div className={`${cssPrefix}content-container-column`}>
                <TransactionBookingOrderDetails
                  isLoggedInUserGeneratingBooking={
                    isLoggedInUserGeneratingBooking
                  }
                />
                {/* Position for one-column display */}
                {oneColumnDisplay && renderRecordingServiceInfo()}
              </div>
            </div>
          </>
        )}
      </div>
      <TransactionBookingUpdateWarningModal
        transactionId={activeTransactionId}
        isUpdateWarningModalOpen={isUpdateWarningModalOpen}
        closeUpdateWarningModal={closeUpdateWarningModal}
        linkShareStatus={linkShareStatus}
      />
    </>
  );
};

export default TransactionBookingScreen;
