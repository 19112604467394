import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import "./SubscriptionCard.css";
import {
  useEngineerSubscriptionBenefits,
  useStudioSubscriptionBenefits,
  useSubscriptionMonthlyPrice,
  useSubscriptionName,
} from "../../../hooks/useSubscriptionTrophy";
import { CardContent, useTheme } from "@mui/material";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import {
  SubscriptionCardContainer,
  SubscriptionCardContent,
  SubscriptionCardHeader,
  SubscriptionCardKeyFeatures,
  SubscriptionCardPricing,
} from "./SubscriptionCard.styles";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { authModalOpenAtom, currentFlowAtom } from "../Auth/atoms";
import { useAppSelector } from "../../../store/hooks";
import { useEffect, useRef } from "react";
import { useSetAtom } from "jotai";
import { selectSubscriptionStickyButtons } from "../../screens/SelectSubscriptionScreen/atoms";
import { SCREENS } from "../../../routes/screens";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { AuthFlows } from "../../../constants/authSteps";

export interface SubscriptionCardProps {
  plan: SUBSCRIPTION_PLAN;
  onSubscriptionSelected: () => void;
  recommended: boolean;
  refundFees?: boolean;
  showPrice?: boolean;
  buttonDisabled?: boolean;
  buttonDisabledMessage?: string;
  useStudioBenefits?: boolean;
  comparisonTableRef?: React.RefObject<HTMLDivElement>;
}

export const SubscriptionCard = ({
  plan,
  onSubscriptionSelected,
  recommended,
  refundFees,
  showPrice = true,
  buttonDisabled = false,
  buttonDisabledMessage,
  useStudioBenefits = false,
  comparisonTableRef,
}: SubscriptionCardProps) => {
  const history = useHistory();
  const theme = useTheme();
  const benefits = useEngineerSubscriptionBenefits(plan);
  const studioBenefits = useStudioSubscriptionBenefits(plan);
  const subscriptionName = useSubscriptionName(plan);
  const price = useSubscriptionMonthlyPrice(plan);
  const isPlatinum =
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_MONTHLY ||
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_QUARTERLY ||
    plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN_YEARLY;
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const setIsShowingStickyButtons = useSetAtom(selectSubscriptionStickyButtons);
  const subscribeButtonRef = useRef<HTMLButtonElement>(null);
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isMobile } = useMediaQueryBreakpoint();
  const setFlow = useSetAtom(currentFlowAtom);

  const buttonText = () => {
    if (isPlatinum) {
      if (user?.engineer?.stripe_subscription_id || refundFees) {
        return "Select plan";
      }
      return "Start trial";
    }
    return "Get started";
  };

  const handleSeeAllFeatures = () => {
    comparisonTableRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "start",
    });
  };

  // Used to show the sticky buttons once the subscription card button goes out of view
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsShowingStickyButtons(!entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    const currentRef = subscribeButtonRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [subscribeButtonRef]);

  if (benefits.length == 0) return null;

  return (
    <SubscriptionCardContainer>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px",
          height: "100%",
          "&:last-child": { padding: "0px" },
        }}
      >
        <SubscriptionCardHeader $isPlatinum={isPlatinum}>
          <Text
            variant={TextStyleVariant.S2}
            style={{
              color: isPlatinum
                ? theme.palette.standardColor.DeepBlue[800]
                : theme.palette.standardColor.BoomyOrange[800],
            }}
          >
            {subscriptionName}
          </Text>
          <Text
            variant={TextStyleVariant.P2}
            style={{
              color: isPlatinum
                ? theme.palette.standardColor.DeepBlue[800]
                : theme.palette.standardColor.BoomyOrange[800],
            }}
          >
            {isPlatinum ? "14-day free trial" : "No upfront cost"}
          </Text>
        </SubscriptionCardHeader>
        <SubscriptionCardContent>
          <SubscriptionCardPricing>
            <Text variant={TextStyleVariant.H4} style={{ fontWeight: "400" }}>
              ${price}
              <Text variant={TextStyleVariant.P1} style={{ display: "inline" }}>
                /mo
              </Text>
            </Text>
            <Text
              variant={TextStyleVariant.P2}
              style={{
                color: isPlatinum
                  ? theme.palette.customColor.textErrorColor
                  : theme.palette.standardColor.Gray[400],
              }}
            >
              {isPlatinum ? "When you pay annually" : "No credit card required"}
            </Text>
          </SubscriptionCardPricing>
          <Button
            ref={subscribeButtonRef}
            disabled={buttonDisabled}
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              if (!isAuthenticated) {
                if (isMobile) history.push(SCREENS.AUTH_SCREEN);
                setFlow(AuthFlows.START_SIGNUP);
                setAuthModalOpen(true);
                return;
              }
              onSubscriptionSelected();
            }}
            fullWidth
          >
            {buttonText()}
          </Button>
          <SubscriptionCardKeyFeatures>
            <Text variant={TextStyleVariant.P2} weight={TEXT_WEIGHT.SEMI_BOLD}>
              {isPlatinum ? "Everything in Essentials, plus:" : "Key features:"}
            </Text>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px",
                paddingLeft: "15px",
                gap: "12px",
              }}
            >
              {(useStudioBenefits ? studioBenefits : benefits).map(
                (benefit, index) => {
                  return (
                    <li key={`${benefit}-${index}`}>
                      <Text
                        variant={TextStyleVariant.P3}
                        style={{ color: theme.palette.text.secondary }}
                      >
                        {benefit}
                      </Text>
                    </li>
                  );
                },
              )}
            </ul>
          </SubscriptionCardKeyFeatures>
        </SubscriptionCardContent>
        <Button
          variant={ButtonVariant.TEXT}
          style={{ fontSize: "12px", padding: "24px" }}
          onClick={handleSeeAllFeatures}
        >
          <FontAwesomeIcon icon={faArrowRight} style={{ marginRight: "8px" }} />
          See all features
        </Button>
      </CardContent>
    </SubscriptionCardContainer>
  );
};
