import { Box, Grid, Skeleton, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";

import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  FeaturedTrackColumnContainer,
  FeaturedTrackEditContainer,
  FeaturedTrackEmptyContainer,
} from "./FeaturedTrackTable.styles";
import { PortfolioFeatureData } from "../../../store/models/portfolio";
import { FeaturedTrackWrapper } from "../../elements/FeaturedTrack/FeaturedTrackWrapper";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SCREENS } from "../../../routes/screens";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderMusic } from "@fortawesome/pro-solid-svg-icons";
import { useMemo } from "react";
import { PaginationComponent } from "../../screens/ProfileScreen/components/PaginationComponent/PaginationComponent";

const FeaturedTrackSkeleton = () => {
  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      <Skeleton
        animation="wave"
        height={64}
        width={64}
        sx={{ transform: "none", marginBottom: "8px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Skeleton animation="wave" width={64} height={16} />
        <Skeleton animation="wave" width={64} height={16} />
        <Skeleton animation="wave" width={64} height={16} />
      </Box>
    </Box>
  );
};

interface FeaturedTrackTableProps {
  portfolioFeatureDataArr: PortfolioFeatureData[];
  currentPage: number;
  totalPages: number;
  loading: boolean;
  isEditMode: boolean;
  setCurrentPage: (page: number) => void;
}

export const FeaturedTrackTable = ({
  portfolioFeatureDataArr,
  loading,
  isEditMode,
  currentPage,
  totalPages,
  setCurrentPage,
}: FeaturedTrackTableProps) => {
  const history = useHistory();
  const theme = useTheme();

  const featuredTrackBody = useMemo(() => {
    if (loading) {
      return (
        <Grid container>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Grid key={index} item xs={12}>
                <FeaturedTrackSkeleton />
              </Grid>
            ))}
        </Grid>
      );
    }

    if (isEditMode) {
      return (
        <>
          <Text>
            Completed songs will be available to showcase below with your
            client’s approval.
          </Text>
          <FeaturedTrackEditContainer>
            <Button
              onClick={() => history.push(SCREENS.SELECT_FEATURED_TRACK)}
              variant={ButtonVariant.OUTLINED}
            >
              {portfolioFeatureDataArr.length > 0
                ? "Add or edit a song"
                : "Add a song"}
            </Button>
          </FeaturedTrackEditContainer>
        </>
      );
    }
    if (!loading && !isEditMode && portfolioFeatureDataArr.length === 0) {
      return (
        <FeaturedTrackEmptyContainer>
          <FontAwesomeIcon
            style={{
              color: theme.palette.standardColor.Gray[300],
              height: "40px",
              width: "40px",
            }}
            icon={faFolderMusic}
          />
          <Text variant={TextStyleVariant.P1}>No projects yet</Text>
        </FeaturedTrackEmptyContainer>
      );
    }
    return null;
  }, [isEditMode, loading, portfolioFeatureDataArr.length]);

  if (!loading && !isEditMode && portfolioFeatureDataArr.length === 0) {
    return null;
  }

  return (
    <FeaturedTrackColumnContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
        }}
      >
        <Text variant={TextStyleVariant.S2}>Made on EngineEars</Text>
        {featuredTrackBody}
      </Box>
      <Grid container>
        {!loading &&
          portfolioFeatureDataArr.map((portfolioFeatureData) => (
            <Grid key={portfolioFeatureData.id} item xs={12}>
              <FeaturedTrackWrapper
                portfolioFeatureData={portfolioFeatureData}
              />
            </Grid>
          ))}
      </Grid>
      {portfolioFeatureDataArr.length > 0 && (
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          loading={loading}
        />
      )}
    </FeaturedTrackColumnContainer>
  );
};
